import { useAppNavigate } from "@/apollo/cache/auth";
import { StarIcon } from "@heroicons/react/24/solid";
import numeral from "numeral";
import { Tooltip } from "react-tooltip";
import { Maybe } from "yup";

const RateCandidateButton = ({ candidateId, rate }: { candidateId: string; rate?: Maybe<number> }) => {
    const navigate = useAppNavigate();
    const rateValue = new Array(5).fill(0);
    const handleRateCandidate = () => {
        navigate({
            search() {
                return { id: candidateId, modal: "rateCandidate" };
            },
        });
    };
    return (
        <>
            {rate ? (
                <span className="flex  flex-col items-center justify-center whitespace-nowrap">
                    <span className="text-xs font-bold text-gray-500">{numeral(rate).format("#.#")} </span>
                    <span className="flex justify-center items-center">
                        {rateValue?.map((value, index) => {
                            if (index + 1 <= rate) {
                                return <StarIcon className="h-3 w-3 text-orange-300" />;
                            } else {
                                return <StarIcon className="h-3 w-3 text-gray-300" />;
                            }
                        })}
                    </span>
                </span>
            ) : (
                <button onClick={handleRateCandidate} className="px-4 py-2 font-semibold cursor-pointer bg-primary-500 border border-primary-500 text-white text-xs rounded-md" data-tooltip-delay-show={100} data-tooltip-id="global-tooltip" data-tooltip-content="Rate Candidate">
                    <Tooltip
                        style={{
                            fontSize: "10px",
                            fontWeight: "600",
                            backgroundColor: "#101828",
                            color: "#fff",
                            borderRadius: "20px",
                            marginTop: "10px",
                            height: "10px",
                        }}
                        anchorSelect={`#rate-candidate-${candidateId}`}
                        content={`Rate`}
                        className="z-[3]"
                    />
                    Rate
                </button>
            )}
        </>
    );
};

export default RateCandidateButton;

import { GET_MESSAGES, MESSAGE_ADDED } from "@/apollo/messages";
import { GetMessagesQuery, GetMessagesQueryVariables, MessageAddedSubscription, MessageAddedSubscriptionVariables } from "@/apollo/messages/generated/types";
import { useQuery, QueryHookOptions, useSubscription } from "@apollo/client";

export const useMessages = (options?: QueryHookOptions<GetMessagesQuery, GetMessagesQueryVariables>) => {
    const { data, ...rest } = useQuery<GetMessagesQuery, GetMessagesQueryVariables>(GET_MESSAGES, {
        ...options,
        context: { apiName: "message" },
    });

    return {
        messages: data?.getMessages,
        ...rest,
    };
};

// export const useMessagesSubscription = (options?: QueryHookOptions<MessageAddedSubscription, MessageAddedSubscriptionVariables>) => {
//     const { data, ...rest } = useSubscription<MessageAddedSubscription, MessageAddedSubscriptionVariables>(MESSAGE_ADDED, {
//         ...options,
//         context: { apiName: "message" },
//     });

//     return {
//         messages: data?.messageAdded,
//         ...rest,
//     };
// };

import { useCurrentUser } from "@/apollo/cache/auth";
import { CloseJobMutation, CloseJobMutationVariables, DeleteJobMutation, DeleteJobMutationVariables } from "@/apollo/generated/types";
import { CLOSE_JOB } from "@/apollo/graphql/mutations/job";
import { Modal } from "@/components";
import { useUrlState } from "@/utils";
import { useMutation } from "@apollo/client";
import _ from "lodash";
import toast from "react-hot-toast";

type Props = { open: boolean; setOpen: (value: boolean) => void };

const CloseJobModal = ({ open, setOpen }: Props) => {
    const [jobId] = useUrlState("id");
    const employer = useCurrentUser();
    const [closeJob, { loading }] = useMutation<CloseJobMutation, CloseJobMutationVariables>(CLOSE_JOB, { refetchQueries: ["GetJobs", "GetJobStats", "GetActiveJobsLength"] });

    const handleClose = async () => {
        await closeJob({ variables: { companyId: employer?.companyId || "", jobId } })
            .then(() => {
                setOpen(false);
                toast(
                    JSON.stringify({
                        type: "success",
                        title: "Job Closed Successfully",
                    })
                );
            })
            .catch(() => {
                setOpen(false);
                JSON.stringify({
                    type: "error",
                    title: "Something wrong happened",
                });
            });
    };

    return (
        <Modal hideActions open={open} setOpen={setOpen} size="sm" title={""}>
            <div className="w-full h-full space-y-5">
                <h1 className="w-full text-center font-semibold text-xl">Close Job</h1>
                <h6 className="w-full text-center text-sm">
                    Are you sure you want to close <br /> this job
                </h6>
                <div className="w-full flex justify-between  items-center px-5">
                    <button className="px-2 py-2 text-sm text-gray-500 border rounded font-semibold" onClick={() => setOpen(false)}>
                        Cancel
                    </button>
                    <button className="text-sm px-4 py-2 bg-primary-500 text-white rounded font-semibold" onClick={handleClose}>
                        {loading ? "Closing ..." : "Close"}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default CloseJobModal;

import { useAppNavigate } from "@/apollo/cache/auth";
import { Button } from "@/components";
import { FormikProps } from "formik";
import { ChangeEvent } from "react";

type Props = { form: FormikProps<any>; setTab: (title: "Role Info" | "Permissions Info" | "Preview") => void };

const RoleInfo = ({ form, setTab }: Props) => {
    const navigate = useAppNavigate();
    return (
        <div className="w-full mt-2 grid grid-col-1  md:grid-cols-2 gap-2 px-3 pt-3">
            <div className="space-y-0.5">
                <label className="text-xs ">Name</label>
                <input id="name" placeholder="" name="name" type="text" value={form.values.name} onChange={(e: ChangeEvent<HTMLInputElement>) => form.setFieldValue("name", e.target.value)} className=" text-gray-900 text-xs border w-full h-9 px-2 rounded-md  outline-primary-500  ring-primary-500" />
                {form.errors.name && (
                    <p className="text-xs mt-0.5 text-red-400">
                        <>{form.errors.name}</>
                    </p>
                )}
            </div>
            <div className="space-y-0.5">
                <label className="text-xs ">Code</label>
                <input id="code" placeholder="" name="code" type="text" value={form.values.code} onChange={(e: ChangeEvent<HTMLInputElement>) => form.setFieldValue("code", e.target.value)} className=" text-gray-900 text-xs border w-full h-9 px-2 rounded-md  outline-primary-500  ring-primary-500" />
                {form.errors.code && (
                    <p className="text-xs mt-0.5 text-red-400">
                        <>{form.errors.code}</>
                    </p>
                )}
            </div>
            <div className="col-span-full space-y-0.5">
                <label className="text-xs ">Description</label>
                <textarea className=" text-xs  p-2 border h-14 w-full rounded-md focus:ring-1 ring-primary-500 outline-primary-500" value={form.values.description} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => form.setFieldValue("description", e.target.value)} />
                {form.errors.description && (
                    <p className="text-xs mt-0.5 text-red-400">
                        <>{form.errors.description}</>
                    </p>
                )}
            </div>

            <div className="col-span-full mt-5 border-t flex justify-end items-center pt-3 space-x-3">
                <div>
                    <Button
                        variant="minimal"
                        onClick={() => {
                            form.resetForm();
                            navigate({
                                search(prev) {
                                    return { ...prev, modal: undefined };
                                },
                            });
                        }}>
                        <span className="text-xs font-semibold">Cancel</span>
                    </Button>
                </div>
                <div>
                    <Button onClick={() => setTab("Permissions Info")}>
                        <span className="text-xs font-semibold">Next</span>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default RoleInfo;

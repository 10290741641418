import { createTheme, MantineProvider } from "@mantine/core";
import { ReactElement } from "react";
import "@mantine/core/styles.css";

type Props = {
    children: ReactElement;
};

const theme = createTheme({
    /** Put your mantine theme override here */
});

export default function MantimeProviderLayout({ children }: Props) {
    return <MantineProvider theme={theme}>{children}</MantineProvider>;
}

import { useCurrentUser } from "@/apollo/cache/auth";
import { useRoles } from "@/hooks";
import { classNames } from "@/utils";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from "@headlessui/react";
import { FormikProps } from "formik";
import _ from "lodash";
import { Fragment } from "react";
import { IoIosArrowDown } from "react-icons/io";

type Props = { form: FormikProps<any> };

const RoleSelector = ({ form }: Props) => {
    const employer = useCurrentUser();
    const { roles, loading } = useRoles({ variables: { filter: { companyId: employer?.companyId ? { eq: employer?.companyId } : undefined } } });

    return (
        <>
            <Listbox value={form.values.role} onChange={(value) => form.setFieldValue("role", value)}>
                {({ open }) => (
                    <>
                        <div className="relative">
                            <ListboxButton className="relative w-full h-9  cursor-default focus:ring-1 ring-primary-500 rounded-md border border-gray-500 py-1 px-4 shadow bg-white pl-3 pr-10 text-left text-gray-900  focus:outline-none  sm:text-sm sm:leading-6">
                                <span className="font-montserrat font-medium text-xs text-gray-600 block truncate">
                                    <>{roles?.filter((item) => item?.id === form.values.role)?.at(0)?.name}</>
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <IoIosArrowDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                            </ListboxButton>

                            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                                {loading ? (
                                    <div>Loading ....</div>
                                ) : _.isEmpty(roles) && !loading ? (
                                    <div className="w-full text-xs text-center p-2">No Roles Found</div>
                                ) : (
                                    <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 text-base shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {roles?.map((role, index) => (
                                            <ListboxOption key={index} className={({ active }) => classNames(active ? "bg-primary text-white" : "text-[#6B6B6B]", "relative cursor-default select-none py-3 pl-3 ")} value={role?.id}>
                                                {({ selected }) => (
                                                    <>
                                                        <span className={classNames(selected ? "font-semibold" : "font-normal", "block truncate")}>{role?.name}</span>
                                                    </>
                                                )}
                                            </ListboxOption>
                                        ))}
                                    </ListboxOptions>
                                )}
                            </Transition>
                        </div>
                    </>
                )}
            </Listbox>
            {form.errors.role && form.touched.role && (
                <p className="text-xs mt-0.5 text-red-400">
                    <>{form.errors.role}</>
                </p>
            )}
        </>
    );
};

export default RoleSelector;

import { SettingsQuery, SettingsQueryVariables } from "@/apollo/generated/types";
import { GET_SETTINGS } from "@/apollo/graphql/queries/setting";
import { useQuery, QueryHookOptions } from "@apollo/client";

export const useSettings = (options?: QueryHookOptions<SettingsQuery, SettingsQueryVariables>) => {
    const { data, ...rest } = useQuery<SettingsQuery, SettingsQueryVariables>(GET_SETTINGS, {
        ...options,
    });

    return {
        settings: data?.settings,
        ...rest,
    };
};

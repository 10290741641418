import { JobStatus } from "@/apollo/generated/types";
import { Permission } from "@/permissions";
import { ReactLocation, MakeGenerics } from "react-location";

export type LocationGenerics = MakeGenerics<{
    Search: {
        redirect?: string;
        token?: string;
        page?: number;
        pageSize?: number;
        search?: string;
        searchField?: string;
        sort?: string;
        id?: string;
        conversation_id?: string;
        filter?: string;
        modal?: string;
        drawer?: string;
        status?: JobStatus | string;
        draft_id?: string;
    };
    Params: {
        id?: string;
    };
    RouteMeta: {
        layout?: "App" | "Auth";
    };
    LoaderData: { userPermissions: Permission[] };
}>;

const location = new ReactLocation();

export default location;

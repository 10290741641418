import DefaultLoader from "@/components/loaders/default-loader";
import { useNotification } from "@/hooks/useNotification";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FACEBOOK, INSTAGRAM, LINKEDIN, TWITTER } from "@/constants/socials";
import emailFooter from "@/assets/images/email foot.png";
import logo from "@/assets/images/logo.png";
import { useUrlState } from "@/utils";
import moment from "moment";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-location";

const NotificationDetail = () => {
    const navigate = useNavigate();
    const [id] = useUrlState("id");
    const { notification, loading } = useNotification({ variables: { getNotificationId: id } });

    return (
        <main className="w-full flex justify-start items-start space-x-5 h-full lg:pr-10 md:pr-5">
            {loading ? (
                <DefaultLoader />
            ) : (
                <div className=" bg-white border-r border-gray-200 w-full h-full ">
                    <div className="font-montserrat flex flex-col grow overflow-y-auto justify-start ">
                        <div className="sticky top-5 bg-white ">
                            <div className="w-full flex justify-between items-center border-b border-[#D6D6D6] pb-4 px-5">
                                <div className="flex items-center gap-x-4">
                                    <BiArrowBack onClick={() => navigate({ to: "/notifications" })} className="w-5 h-5 text-black cursor-pointer" />
                                    <span className="font-montserrat font-semibold text-base text-black">{notification?.title}</span>
                                </div>

                                <p className="font-montserrat font-medium text-[10px] leading-5 text-gray-400">{moment(notification?.createdAt).fromNow()}</p>
                            </div>
                        </div>
                        <div className="mt-10 px-5">
                            <div className="flex items-start gap-x-4">
                                <div className="flex-1">
                                    <p className=" font-montserrat font-medium text-sm text-gray-500">
                                        {notification?.body.split("\n").map((line, index) => (
                                            <span key={index}>
                                                {line}
                                                <br />
                                            </span>
                                        ))}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mx-5 my-10">
                        <div className=" rounded-xl ring-1 ring-gray-300 p-3">
                            <img src={emailFooter} alt="email-footer" />

                            <div className="flex justify-start items-center w-full gap-3 mt-4 ">
                                <a href={FACEBOOK}>
                                    <BiLogoFacebookCircle className="text-gray-400 w-7 h-7" />
                                </a>
                                <a href={TWITTER}>
                                    <FaXTwitter className="text-gray-400 w-6 h-6" />
                                </a>
                                <a href={INSTAGRAM}>
                                    <FaInstagram className="text-gray-400 w-6 h-6" />
                                </a>
                                <a href={LINKEDIN}>
                                    <FaLinkedin className="text-gray-400 w-6 h-6" />
                                </a>
                            </div>
                        </div>

                        <div className="flex flex-col gap-y-2 items-end  py-5 mt-6">
                            <img src={logo} alt="logo" className="object-contain h-5 w-20 " />

                            <h5 className="font-medium text-sm text-gray-400">Contact Support</h5>

                            <div className="flex justify-end items-center w-full gap-5">
                                <a href={FACEBOOK}>
                                    <BiLogoFacebookCircle className="text-gray-400 w-5 h-5" />
                                </a>
                                <a href={TWITTER}>
                                    <FaXTwitter className="text-gray-400 w-4 h-4" />
                                </a>
                                <a href={INSTAGRAM}>
                                    <FaInstagram className="text-gray-400 w-4 h-4" />
                                </a>
                                <a href={LINKEDIN}>
                                    <FaLinkedin className="text-gray-400 w-4 h-4" />
                                </a>
                            </div>

                            <p className="font-medium text-sm text-gray-400">&copy; Copyright {new Date().getFullYear()}, Adjuma.io</p>
                        </div>
                    </div>
                </div>
            )}
        </main>
    );
};

export default NotificationDetail;

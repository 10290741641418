import { FaSpinner } from "react-icons/fa";
import logo from "@/assets/frame.png";

type Props = {};

const DefaultLoader = (props: Props) => {
    return (
        <div className="w-full h-full flex items-center justify-center bg-white bg-opacity-50 transition-opacity min-h-96">
            <div className="flex flex-col items-center">
                <img src={logo} alt="Logo" className="w-10 h-8 mb-2 " />
                <FaSpinner className="text-2xl text-primary animate-spin" />
            </div>
        </div>
    );
};

export default DefaultLoader;

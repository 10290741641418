import { GetCandidateQuery, GetCandidateQueryVariables } from "@/apollo/generated/types";
import { GET_CANDIDATE } from "@/apollo/graphql/queries/candidate";
import { useQuery, QueryHookOptions } from "@apollo/client";

export const useCandidate = (options?: QueryHookOptions<GetCandidateQuery, GetCandidateQueryVariables>) => {
    const { data, ...rest } = useQuery<GetCandidateQuery, GetCandidateQueryVariables>(GET_CANDIDATE, {
        ...options,
    });

    return {
        candidate: data?.getCandidate,
        ...rest,
    };
};

import { FC } from "react";
import BarLoader from "react-spinners/BeatLoader";

interface LoaderProps {}

const Loader: FC<LoaderProps> = () => (
    <div className="flex-1 flex flex-col space-y-6 items-center justify-center">
        <BarLoader color={"#136140"} loading={true} cssOverride={{ width: "10%" }} aria-label="Loading Spinner" data-testid="loader" />
        <div className="flex items-center justify-center text-gray-600 text-sm mr-12">Loading details...</div>
    </div>
);

export default Loader;

import { Button, Drawer, Show } from "@/components";
import { useJob } from "@/hooks";
import { useUrlState } from "@/utils";
import EditJobDetailForm from "./forms/EditJobDetailForm";
import EditJobDescriptionForm from "./forms/EditJobDescriptionForm";
import EditJobPreviewForm from "./forms/EditJobPreviewForm";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useAppNavigate, useCurrentUser } from "@/apollo/cache/auth";
import { useMutation } from "@apollo/client";
import { act, useEffect, useState } from "react";
import { useAppDispatch } from "@/redux/hook";
import { useFormik } from "formik";
import { clearEditJob, setEditJobDescription, setEditJobDetails } from "@/redux/job/editJobSlice";
import { SalaryFrequency, UpdateJobMutation, UpdateJobMutationVariables } from "@/apollo/generated/types";
import { UPDATE_JOB_MUTATION } from "@/apollo/graphql/mutations/job";
import TabButton from "./TabButton";
import moment from "moment";
import _ from "lodash";
import DefaultLoader from "@/components/loaders/default-loader";
import { CurrencyOption, SalaryDurationOption } from "@/data";
import ButtonLoader from "@/components/loaders/button";

type Props = { open: boolean; setOpen: (value: boolean) => void };

const EditJobDrawer = ({ open, setOpen }: Props) => {
    const [jobId] = useUrlState("id");

    const { job, loading } = useJob({
        variables: {
            jobId: jobId || "",
        },
    });

    const navigate = useAppNavigate();

    const employer = useCurrentUser();
    const [editJob, { loading: postLoading }] = useMutation<UpdateJobMutation, UpdateJobMutationVariables>(UPDATE_JOB_MUTATION, { refetchQueries: ["GetJobs"] });

    const [active, setActive] = useState<"Details" | "Description" | "Preview">("Details");
    const dispatch = useAppDispatch();

    const editJobDetailForm = useFormik({
        initialValues: {
            jobTitle: "",
            jobType: "",
            modeOfWork: "",
            jobLocation: "",
            openingDate: "",
            closingDate: "",
            duration: SalaryDurationOption[0].value,
            currency: CurrencyOption[0].value,
            salaryRange: false,
            minimumSalary: 0,
            maximumSalary: 0,
            vacancies: 0,
        },
        validationSchema: Yup.object().shape({
            jobTitle: Yup.string().required("Job title is required"),
            vacancies: Yup.number().min(1, "Openings must be greater or equal to 1").required("Openings is required"),
            jobType: Yup.string().required("Job type is required"),
            jobLocation: Yup.string().required("Job location is required"),
            modeOfWork: Yup.string().required("Mode of work is required"),
            openingDate: Yup.date().required("Opening date is required"),
            closingDate: Yup.date().required("Closing date is required").min(Yup.ref("openingDate"), "Closing date cannot be before the opening date"),
            minimumSalary: Yup.number()
                .nullable()
                .test("min-price-validation", "Min salary is incorrecct", function (value) {
                    const { maximumSalary } = this.parent;
                    if (maximumSalary !== undefined && maximumSalary !== null) {
                        if (value === undefined || value === null) {
                            return false; // Min price is required when max price is set
                        }
                        return value <= maximumSalary; // Min price cannot be greater than max price
                    }
                    return true;
                }),
            maximumSalary: Yup.number()
                .nullable()
                .test("max-price-validation", "Max salary is incorrect", function (value) {
                    const { minimumSalary } = this.parent;
                    if (minimumSalary !== undefined && minimumSalary !== null) {
                        if (value === undefined || value === null) {
                            return false; // Max price is required when min price is set
                        }
                        return value >= minimumSalary; // Max price cannot be less than min price
                    }
                    return true;
                }),
        }),
        onSubmit: (values) => {
            dispatch(
                setEditJobDetails({
                    ...values,
                    minimumSalary: values?.salaryRange ? values.minimumSalary : 0,
                    maximumSalary: values?.salaryRange ? values.maximumSalary : 0,
                })
            );
            setActive("Description");
        },
    });
    const editJobDescriptionForm = useFormik({
        initialValues: {
            about: "",
            responsibilities: "",
            requirements: "",
            benefits: "",
        },
        validationSchema: Yup.object().shape({
            about: Yup.string().required("About is required"),
            responsibilities: Yup.string().required("Responsibilities is required"),
            requirements: Yup.string().required("Requirements is required"),
            benefits: Yup.string().optional(),
        }),
        onSubmit: (values) => {
            dispatch(
                setEditJobDescription({
                    ...values,
                })
            );
            setActive("Preview");
        },
    });

    const handleSubmit = async () => {
        await editJob({
            variables: {
                input: {
                    id: jobId || "",
                    companyId: employer?.companyId || "",
                    about: editJobDescriptionForm.values.about,
                    requirements: editJobDescriptionForm.values.requirements,
                    responsibilities: editJobDescriptionForm.values.responsibilities,
                    benefits: editJobDescriptionForm.values.benefits,
                    title: editJobDetailForm.values.jobTitle,
                    type: editJobDetailForm.values.jobType,
                    location: editJobDetailForm.values.jobLocation,
                    mode: editJobDetailForm.values.modeOfWork,
                    openDate: moment(editJobDetailForm.values.openingDate).toDate(),
                    closeDate: moment(editJobDetailForm.values.closingDate).toDate(),
                    vacancies: editJobDetailForm.values.vacancies,
                    salary: {
                        currency: editJobDetailForm.values.currency,
                        maximum: editJobDetailForm.values.maximumSalary.toString(),
                        minimum: editJobDetailForm.values.minimumSalary.toString(),
                        frequency: editJobDetailForm.values.duration,
                    },
                },
            },
        })
            .then(() => {
                editJobDescriptionForm.resetForm();
                editJobDetailForm.resetForm();
                dispatch(clearEditJob());
                toast(JSON.stringify({ type: "success", title: "Job Edited Successfully" }));
                navigate({
                    search(prev) {
                        return { ...prev, id: undefined, drawer: undefined };
                    },
                });
            })
            .catch(() => {
                toast(JSON.stringify({ type: "error", title: "Something wrong happened" }));
            });
    };

    useEffect(() => {
        if (!loading && job?.id) {
            editJobDescriptionForm.setValues({ about: job?.about || "", requirements: job?.requirements || "", responsibilities: job?.responsibilities || "", benefits: job?.benefits || "" });
            editJobDetailForm.setValues({
                jobTitle: job?.title || "",
                jobType: job?.type || "",
                jobLocation: job?.location || "",
                modeOfWork: job?.mode || "",
                openingDate: moment(job?.openDate).format("YYYY-MM-DD"),
                closingDate: moment(job?.closeDate).format("YYYY-MM-DD"),
                currency: job?.salary?.currency || CurrencyOption[0].value,
                maximumSalary: Number(job?.salary?.maximum || 0),
                minimumSalary: Number(job?.salary?.minimum || 0),
                duration: job?.salary?.frequency || SalaryDurationOption[0].value,
                salaryRange: job?.salary?.maximum || job?.salary?.minimum ? true : false,
                vacancies: job.vacancies || 0,
            });
            dispatch(
                setEditJobDetails({
                    jobTitle: job?.title || "",
                    jobType: job?.type || "",
                    jobLocation: job?.location || "",
                    modeOfWork: job?.mode || "",
                    openingDate: moment(job?.openDate).format("YYYY-MM-DD"),
                    closingDate: moment(job?.closeDate).format("YYYY-MM-DD"),
                    currency: job?.salary?.currency || CurrencyOption[0].value,
                    maximumSalary: Number(job?.salary?.maximum || 0),
                    minimumSalary: Number(job?.salary?.minimum || 0),
                    duration: job?.salary?.frequency || SalaryDurationOption[0].value,
                    salaryRange: job?.salary?.maximum || job?.salary?.minimum ? true : false,
                    vacancies: job.vacancies || 0,
                })
            );
            dispatch(
                setEditJobDescription({
                    about: job?.about || "",
                    requirements: job?.requirements || "",
                    responsibilities: job?.responsibilities || "",
                    benefits: job?.benefits || "",
                })
            );
        }
    }, [loading, jobId, job?.id]);

    return (
        <Drawer
            open={open}
            loading={loading}
            setOpen={setOpen}
            title="Edit Job"
            renderActions={() => (
                <div className="w-full">
                    {active === "Details" ? (
                        <div className="col-span-full flex items-center justify-end space-x-5">
                            <div>
                                <Button variant="primary" size="md" onClick={() => editJobDetailForm.handleSubmit()} width="auto">
                                    <span className="flex justify-between items-center space-x-2">
                                        <span className="px-5">Next</span>
                                    </span>
                                </Button>
                            </div>
                        </div>
                    ) : active === "Description" ? (
                        <div className="col-span-full flex items-center justify-between space-x-5">
                            <div>
                                <Button variant="minimal" size="md" onClick={() => setActive("Details")}>
                                    Previous
                                </Button>
                            </div>

                            <div>
                                <Button variant="primary" size="md" onClick={() => editJobDescriptionForm.handleSubmit()}>
                                    <span className="flex justify-between items-center space-x-2">
                                        <span>Next</span>
                                    </span>
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className="col-span-full flex items-center justify-between space-x-5">
                            <div>
                                <Button variant="minimal" size="md" onClick={() => setActive("Description")}>
                                    Previous
                                </Button>
                            </div>
                            <div className="flex justify-end items-center space-x-5">
                                <div></div>
                                <div>
                                    <Button variant="primary" size="md" onClick={handleSubmit}>
                                        <span className="flex justify-between items-center space-x-2">
                                            <span>{postLoading ? <ButtonLoader title="Updating ... " /> : "Update Job"}</span>
                                        </span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}>
            <>
                <div className="w-full h-full overflow-scroll overflow-y-scroll no-scrollbar">
                    <div className="flex justify-start items-center border-b w-full h-10 text-sm lg:space-x-10 space-x-5 overflow-scroll overflow-x-scroll no-scrollbar sticky top-0 z-30 bg-white">
                        <TabButton title={"Details"} active={active} setActive={setActive} />
                        <TabButton title={"Description"} active={active} setActive={setActive} />
                        <TabButton title={"Preview"} active={active} setActive={setActive} />
                    </div>
                    <div className="w-full mt-5">
                        {loading ? (
                            <div className="w-full flex justify-center items-center">
                                <DefaultLoader />
                            </div>
                        ) : (
                            <>
                                <Show if={active === "Details"}>
                                    <EditJobDetailForm form={editJobDetailForm} />
                                </Show>
                                <Show if={active === "Description"}>
                                    <EditJobDescriptionForm form={editJobDescriptionForm} />
                                </Show>
                                <Show if={active === "Preview"}>
                                    <EditJobPreviewForm />
                                </Show>
                            </>
                        )}
                    </div>
                </div>
            </>
        </Drawer>
    );
};

export default EditJobDrawer;

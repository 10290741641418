import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: false,
};

export const candidateSlice = createSlice({
  name: "candidateModal",
  initialState,
  reducers: {
    showCandidate: (state) => {
      state.value = true;
    },
    hideCandidate: (state) => {
      state.value = false;
    },
  },
});

export const { showCandidate, hideCandidate } = candidateSlice.actions;

export default candidateSlice.reducer;

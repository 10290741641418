import { Fragment, ReactElement } from "react";
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import DefaultLoader from "../loaders/default-loader";
import { Show } from "../core";

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    children: ReactElement;
    loading?: boolean;
    renderActions?: () => JSX.Element;
    title: string;
    hiddenAction?: boolean;
}

export default function JobDrawer({ open, setOpen, children, loading, renderActions, title, hiddenAction }: Props) {
    return (
        <Transition show={open} as={Fragment}>
            <Dialog className="relative z-40" onClose={setOpen}>
                <TransitionChild as={Fragment} enter="ease-in-out duration-500" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-500" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-[#010024] bg-opacity-50 transition-opacity" />
                </TransitionChild>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <TransitionChild
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full">
                                <DialogPanel className="pointer-events-auto w-screen md:max-w-3xl max-w-full relative h-screen">
                                    <div className="relative flex h-full flex-col bg-white pb-6 shadow-xl">
                                        <div className="sticky top-0 bg-white px-4 sm:px-6 border-b pb-3 pt-5 z-10">
                                            <div className="flex items-start justify-between">
                                                <DialogTitle className="text-xl font-semibold leading-6 text-black select-none">{title}</DialogTitle>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button type="button" className="relative rounded-md bg-white text-gray-400 border p-1 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2" onClick={() => setOpen(false)}>
                                                        <span className="absolute -inset-2.5" />
                                                        <span className="sr-only">Close panel</span>

                                                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`relative mt-6 flex-1 px-4 sm:px-6  w-full h-full overflow-scroll overflow-y-scroll ${hiddenAction ? "pb-5" : "pb-14"}`}>
                                            {/* Content */}
                                            {loading ? (
                                                <div className="h-full w-full">
                                                    <DefaultLoader />
                                                </div>
                                            ) : (
                                                children
                                            )}
                                        </div>
                                    </div>
                                    <Show if={!hiddenAction}>
                                        <>
                                            {renderActions && (
                                                <div className="bg-white w-full absolute bottom-0 right-0 left-0 border-t border-gray-300 py-4 px-6">
                                                    {" "}
                                                    <>{renderActions?.()}</>
                                                </div>
                                            )}
                                        </>
                                    </Show>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}

import { GET_CONVERSATION } from "@/apollo/messages";
import { GetConversationQuery, GetConversationQueryVariables } from "@/apollo/messages/generated/types";
import { useQuery, QueryHookOptions } from "@apollo/client";


export const useConversation = (options?: QueryHookOptions<GetConversationQuery, GetConversationQueryVariables>) => {
    const { data, ...rest } = useQuery<GetConversationQuery, GetConversationQueryVariables>(GET_CONVERSATION, {
        ...options,
        context: { apiName: "message" },
    });

    return {
        conversation: data?.getConversation,
        ...rest,
    };
};


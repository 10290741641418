/* eslint-disable @typescript-eslint/no-explicit-any */
import config from "@/config";
import axios from "axios";

export const UPLOAD_FILE = async (data: FormData) => {
    try {
        const response = await axios.post(config.asset.uri, data, {
            headers: { "Content-Type": "multipart/form-data" },
        });

        return response.data;
    } catch (e: any) {
        console.log(e?.message);
    }
};

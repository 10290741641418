import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";

import storage from "redux-persist/lib/storage";
import companyReducer from "./company/companySlice"; // Import the company reducer
import modalReducer from "./company/modalSlice";
import candidateReducer from "./company/candidateSlice";
import logoutModalReducer from "./company/logoutModalSlice";
import editJobModalReducer from "./company/editJobModalSlice";
import createJobReducer from "./job/createJobSlice";
import editJobReducer from "./job/editJobSlice";
import onboardingReducer from "./auth/onboardingSlice";
import repostJobReducer from "./job/repostJobSlice";

const reducers = combineReducers({
    onboarding: onboardingReducer,
    company: companyReducer,
    modal: modalReducer,
    candidateModal: candidateReducer,
    logoutModal: logoutModalReducer,
    editJobModal: editJobModalReducer,
    createJob: createJobReducer,
    editJob: editJobReducer,
    repostJob: repostJobReducer,
});

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["company", "modal", "logoutModal", "editJobModal", "onboarding", "createJob", "updateJob"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

const persistor = persistStore(store);

setupListeners(store.dispatch);

export { store, persistor };

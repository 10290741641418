import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

interface RepostJobPreviewType {
    jobTitle: string;
    jobType: string;
    jobLocation: string;
    modeOfWork: string;
    minimumSalary: number;
    maximumSalary: number;
    duration: string;
    currency: string;
    openingDate: string;
    closingDate: string;
    about: string;
    responsibilities: string;
    requirements: string;
    benefits: string;
}

interface RepostJobDetailType {
    openingDate: string;
    closingDate: string;
}

const initialState = {
    jobTitle: "",
    department: "",
    experience: "",
    jobLocation: "",
    modeOfWork: "",
    duration: "",
    currency: "",
    jobType: "",
    minimumSalary: 0,
    maximumSalary: 0,
    about: "",
    openingDate: "",
    closingDate: "",
    responsibilities: "",
    requirements: "",
    benefits: "",
};

export const repostJobSlice = createSlice({
    name: "repostJobSlice",
    initialState,
    reducers: {
        setRepostJobPreview: (state, { payload }: PayloadAction<RepostJobPreviewType>) => {
            state.jobTitle = payload.jobTitle;
            state.jobType = payload.jobType;
            state.jobLocation = payload.jobLocation;
            state.modeOfWork = payload.modeOfWork;
            state.minimumSalary = payload.minimumSalary;
            state.maximumSalary = payload.maximumSalary;
            state.duration = payload.duration;
            state.currency = payload.currency;
            state.openingDate = payload.openingDate;
            state.closingDate = payload.closingDate;
            state.about = payload.about;
            state.benefits = payload.benefits;
            state.requirements = payload.requirements;
            state.responsibilities = payload.responsibilities;
        },
        setRepostDetails: (state, { payload }: PayloadAction<RepostJobDetailType>) => {
            state.openingDate = payload.openingDate;
            state.closingDate = payload.closingDate;
        },

        clearRepostJob: (state) => {
            state.jobTitle = "";
            state.jobLocation = "";
            state.openingDate = "";
            state.closingDate = "";
            state.minimumSalary = 0;
            state.maximumSalary = 0;
            state.modeOfWork = "";
            state.duration = "";
            state.currency = "";
            state.jobType = "";
            state.about = "";
            state.responsibilities = "";
            state.requirements = "";
            state.benefits = "";
        },
    },
});

export const { clearRepostJob, setRepostDetails, setRepostJobPreview } = repostJobSlice.actions;

export const selectRepostJob = (state: RootState) => state.repostJob;

export default repostJobSlice.reducer;

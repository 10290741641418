import { Button } from "@/components";
import { FormikProps } from "formik";
import PermissionCheckBox from "./components/PermissionCheckBox";

type Props = { form: FormikProps<any>; setTab: (title: "Role Info" | "Permissions Info" | "Preview") => void };

const PermissionInfo = ({ setTab, form }: Props) => {
    return (
        <div className="w-full mt-2 grid grid-col-1  md:grid-cols-2 gap-4 px-3 pt-3">
            <div className="w-full space-y-4">
                <h1 className="text-sm font-semibold text-left">Jobs</h1>
                <div className="mt- space-y-3">
                    <PermissionCheckBox value="job:create" form={form} />
                    <PermissionCheckBox value="job:update" form={form} />
                    <PermissionCheckBox value="job:delete" form={form} />
                    <PermissionCheckBox value="job:repost" form={form} />
                </div>
            </div>
            <div className="w-full space-y-4">
                <h1 className="text-sm font-semibold text-left">Candidates</h1>
                <div className="mt-2 space-y-3">
                    <PermissionCheckBox value="candidate:shortlist" form={form} />
                    <PermissionCheckBox value="candidate:interview" form={form} />
                </div>
            </div>
            {form.errors.permissions && (
                <p className="text-xs mt-0.5 text-red-400">
                    <>{form.errors.permissions}</>
                </p>
            )}
            <div className="col-span-full mt-5 border-t flex justify-end items-center pt-3 space-x-3">
                <div>
                    <Button variant="minimal" onClick={() => setTab("Role Info")}>
                        <span className="text-xs font-semibold">Previous</span>
                    </Button>
                </div>
                <div>
                    <Button onClick={() => setTab("Preview")}>
                        <span className="text-xs font-semibold">Next</span>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PermissionInfo;

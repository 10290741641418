import { ApplicationStatus } from "@/apollo/generated/types";
import { classNames } from "@/utils";
import { FC } from "react";

type Props = { title: string; value: ApplicationStatus | undefined; quantity: number; active: ApplicationStatus | undefined; setActive: (value: ApplicationStatus | undefined) => void };

const TabButton: FC<Props> = ({ title, active, value, quantity, setActive }) => {
    return (
        <button
            onClick={() => setActive(value)}
            className={classNames(active === value ? "border-b-2 border-primary-500 text-primary-500" : "text-gray-500", "h-full flex items-end justify-start  space-x-2  px-2 flex-shrink-0 py-1")}>
            <span>{title}</span>
            {active === value && (
                <span className={classNames(active === value ? "bg-green-500 text-white " : "bg-gray-200 text-gray-600 font-medium ", "text-[11px]  rounded-full   px-1")}>{quantity}</span>
            )}
        </button>
    );
};

export default TabButton;

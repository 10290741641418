import { GetNotificationsQuery, GetNotificationsQueryVariables } from "@/apollo/generated/types";
import { GET_NOTIFICATIONS } from "@/apollo/graphql/queries/notification";
import { useQuery, QueryHookOptions } from "@apollo/client";

export const useNotifications = (options?: QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) => {
    const { data, ...rest } = useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GET_NOTIFICATIONS, {
        ...options,
    });

    return {
        notifications: data?.getNotifications,
        ...rest,
    };
};

import { useState, FC } from "react";
import ChatList from "./components/list";
import ChatDetails from "./components/details";

import { FiSearch } from "react-icons/fi";
import Wrapper from "@/components/wrapper";
import _ from "lodash";
import emailFooter from "@/assets/images/email foot.png";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FACEBOOK, INSTAGRAM, LINKEDIN, TWITTER } from "@/constants/socials";
import { useUrlState } from "@/utils";

type Props = {};

const MessagePage: FC<Props> = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedChatId, setSelectedChatId] = useUrlState("conversation_id");
    const [visible, setVisible] = useState(false);

    return (
        <Wrapper>
            <div className="relative w-full h-full flex justify-start items-start md:pr-10">
                <div className="md:w-1/2 w-full border-r h-full  border-gray-300 overflow-scroll overflow-y-scroll no-scrollbar">
                    <div className=" flex flex-col grow justify-start  mobile:mt-0 ">
                        <div className="hidden w-full sticky top-0 bg-white  md:block border-b">
                            <div className="px-5 pt-3 pb-2 border-b border-gray-200 ">
                                <h1 className="font-semibold text-lg text-black mb-3">Messages</h1>

                                {/* <div className="w-full h-11 ring-1 ring-gray-300 rounded-full flex justify-between items-center pl-3 pr-2">
                                    <input
                                        type="search"
                                        className="select-no-outline flex-1 border-none outline-none focus:border-none focus:outline-none text-sm tracking-[0.1px] placeholder:text-gray-400 placeholder:font-medium "
                                        placeholder="Search Messages"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                    <button className="w-8 h-8 rounded-full bg-primary-500 flex justify-center items-center">
                                        <FiSearch className="w-[18px] h-[18px] text-white" />
                                    </button>
                                </div> */}
                            </div>
                        </div>
                        {/* {!visible && (
                            <div className="mobile:block hidden w-full sticky md:top-4 top-0 z-10 bg-white ">
                                <div className="px-5 py-5 border-b border-gray-200 ">
                                    <h1 className="font-semibold text-lg text-black mb-3">Messages</h1>

                                    <div className="w-full h-11 ring-1 ring-gray-300 rounded-full flex justify-between items-center pl-3 pr-2">
                                        <input
                                            type="search"
                                            className="select-no-outline flex-1 border-none outline-none focus:border-none focus:outline-none text-sm tracking-[0.1px] placeholder:text-gray-400 placeholder:font-medium "
                                            placeholder="Search Messages"
                                            value={searchTerm}
                                            onChange={(e) => {
                                                setSearchTerm(e.target.value);
                                            }}
                                        />
                                        <button className="w-8 h-8 rounded-full bg-primary-500 flex justify-center items-center">
                                            <FiSearch className="w-[18px] h-[18px] text-white" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )} */}

                        <div className="md:hidden block ">
                            {!_.isEmpty(selectedChatId) ? (
                                <ChatDetails />
                            ) : visible ? (
                                <div className=" mt-5 px-5 absolute inset-0 w-full flex flex-col justify-between">
                                    <div className="flex-1  flex justify-center items-center flex-col">
                                        <h2 className="font-montserrat font-semibold text-base text-center text-black mb-2">Choose a message</h2>
                                        <p className="text-center text-gray-600 text-sm">Select from existing messages to continue chatting.</p>
                                    </div>
                                    <div className="w-full rounded-xl ring-1 ring-gray-300 p-5 mb-14">
                                        <img src={emailFooter} alt="email-footer" />

                                        <div className="flex justify-start items-center w-full gap-3 mt-4 ">
                                            <a href={FACEBOOK}>
                                                <BiLogoFacebookCircle className="text-gray-400 w-7 h-7" />
                                            </a>
                                            <a href={TWITTER}>
                                                <FaXTwitter className="text-gray-400 w-6 h-6" />
                                            </a>
                                            <a href={INSTAGRAM}>
                                                <FaInstagram className="text-gray-400 w-6 h-6" />
                                            </a>
                                            <a href={LINKEDIN}>
                                                <FaLinkedin className="text-gray-400 w-6 h-6" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <ChatList />
                            )}
                        </div>
                        <div className="hidden md:block w-full  ">
                            <ChatList />
                        </div>
                    </div>
                </div>
                {!_.isEmpty(selectedChatId) ? (
                    <div className="hidden md:block md:w-1/2 w-full border-r border-gray-300 h-full overflow-hidden">
                        <ChatDetails />
                    </div>
                ) : (
                    <div className=" h-full p-5 md:flex flex-col justify-between hidden md:w-1/2 w-full border-gray-00 border-r ">
                        <div className="flex-1  flex justify-center items-center flex-col">
                            <h2 className="font-montserrat font-semibold text-base text-center text-black mb-2">Choose a message</h2>
                            <p className="text-center text-gray-600 text-sm">Select from existing messages to continue chatting.</p>
                        </div>
                        <div className="w-full rounded-xl ring-1 ring-gray-300 p-5 mt-14">
                            <img src={emailFooter} alt="email-footer" />

                            <div className="flex justify-start items-center w-full gap-3 mt-4 ">
                                <a href={FACEBOOK}>
                                    <BiLogoFacebookCircle className="text-gray-400 w-7 h-7" />
                                </a>
                                <a href={TWITTER}>
                                    <FaXTwitter className="text-gray-400 w-6 h-6" />
                                </a>
                                <a href={INSTAGRAM}>
                                    <FaInstagram className="text-gray-400 w-6 h-6" />
                                </a>
                                <a href={LINKEDIN}>
                                    <FaLinkedin className="text-gray-400 w-6 h-6" />
                                </a>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Wrapper>
    );
};

export default MessagePage;

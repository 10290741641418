import { useState } from "react";

import { useNavigate } from "react-location";
import { useMutation } from "@apollo/client";
import { clearAuth, useCurrentUser, useCurrentUserPermissions } from "@/apollo/cache/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";

import Wrapper from "@/components/wrapper";
import Button from "@/components/buttons/button";
import ButtonLoader from "@/components/loaders/button";
import { Show } from "@/components";
import { AuthType, ResetEmployerPasswordMutation, ResetEmployerPasswordMutationVariables } from "@/apollo/generated/types";
import { CHANGE_PASSWORD } from "@/apollo/graphql/mutations/auth";

import Users from "./tabs/Users";
import UserRoles from "./tabs/UserRoles";
import Notification from "./tabs/Notification";
import Subscription from "./tabs/Subscription";
import { useUrlState } from "@/utils";
import { hasPermission, PERMISSIONS } from "@/permissions";

const SettingsPage = () => {
    const employer = useCurrentUser();
    const currentUserPermissions = useCurrentUserPermissions();
    const [resetPassword, { loading: loadingReset }] = useMutation<ResetEmployerPasswordMutation, ResetEmployerPasswordMutationVariables>(CHANGE_PASSWORD);
    const navigate = useNavigate();

    const [currentTab, setCurrentTab] = useUrlState<"Password" | "Notification" | "User-Role" | "Users" | "Subscription">("settingsTab");

    interface FormValues {
        oldPassword: string;
        newPassword: string;
        confirmNewPassword: string;
    }

    const formik = useFormik({
        initialValues: {
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string().required("Required"),
            newPassword: Yup.string().min(8, "Must be 8 characters or more").required("Required"),
            confirmNewPassword: Yup.string()
                .oneOf([Yup.ref("newPassword"), undefined], "Passwords must match")
                .required("Required"),
        }),
        onSubmit: async (values: FormValues) => {
            await resetPassword({
                variables: {
                    userId: employer?.id || "",
                    confirmedPassword: values.confirmNewPassword || "",
                    oldPassword: values.oldPassword || "",
                    newPassword: values.newPassword || "",
                },
            }).then(({ data }) => {
                if (data?.resetEmployerPassword) {
                    toast(
                        JSON.stringify({
                            type: "success",
                            title: "Password updated successfully",
                        })
                    );
                    clearAuth();
                    formik.resetForm();
                    navigate({
                        to: "/login",
                    });
                } else {
                    toast(
                        JSON.stringify({
                            type: "error",
                            title: "Password update failed",
                        })
                    );
                }
            });
        },
    });

    return (
        <Wrapper>
            <main className=" w-full h-full ">
                <div className="w-full mx-auto sm:pr-5">
                    <h1 className="font-semibold text-black text-lg leading-5 tracking-[0.1px] py-4 border-b border-gray-300 px-2 lg:px-10">Settings</h1>

                    <div className="mt-5 flex overflow-scroll overflow-x-scroll w-full">
                        <header className="flex md:gap-x-8 gap-x-5 w-full  border-b border-grey-200 text-sm font-normal text-grey-500 px-2 lg:px-10 transition-all duration-150 ease-in-out ">
                            <Show if={hasPermission(currentUserPermissions, PERMISSIONS.SETTING_PACKAGE)}>
                                <button
                                    onClick={() => {
                                        setCurrentTab("Subscription");
                                    }}
                                    className={`text-sm font-medium px-2 border-b ${currentTab === "Subscription" || currentTab === undefined ? " border-primary text-primary" : "border-transparent "}  pb-2 leading-4 transition-all duration-150 ease-in-out`}
                                    type="button">
                                    Subscription
                                </button>
                            </Show>
                            <Show if={hasPermission(currentUserPermissions, PERMISSIONS.SETTING_PASSWORD) && employer?.meta?.authType === AuthType.Email}>
                                <button
                                    onClick={() => {
                                        setCurrentTab("Password");
                                    }}
                                    className={`text-sm font-medium px-2 border-b ${currentTab === "Password" ? " border-primary text-primary" : "border-transparent "}  pb-2 leading-4 transition-all duration-150 ease-in-out`}
                                    type="button">
                                    Password
                                </button>
                            </Show>
                            {/* Notifications is not ready */}
                            <Show if={hasPermission(currentUserPermissions, PERMISSIONS.SETTING_NOTIFICATION)}>
                                <button
                                    onClick={() => {
                                        setCurrentTab("Notification");
                                    }}
                                    className={`text-sm font-medium px-2 border-b  ${currentTab === "Notification" ? " border-primary text-primary" : "border-transparent"}  pb-2 leading-4 transition-all duration-150 ease-in-out`}
                                    type="button">
                                    Notifications
                                </button>
                            </Show>

                            <Show if={hasPermission(currentUserPermissions, PERMISSIONS.SETTING_USER) || hasPermission(currentUserPermissions, PERMISSIONS.SETTING_ROLE)}>
                                <>
                                    <Show if={hasPermission(currentUserPermissions, PERMISSIONS.SETTING_ROLE)}>
                                        <button
                                            onClick={() => {
                                                setCurrentTab("User-Role");
                                            }}
                                            className={`text-sm font-medium px-2 border-b whitespace-nowrap ${currentTab === "User-Role" ? " border-primary text-primary" : "border-transparent"}  pb-2 leading-4 transition-all duration-150 ease-in-out`}
                                            type="button">
                                            User Roles
                                        </button>
                                    </Show>
                                    <Show if={hasPermission(currentUserPermissions, PERMISSIONS.SETTING_USER)}>
                                        <button
                                            onClick={() => {
                                                setCurrentTab("Users");
                                            }}
                                            className={`text-sm font-medium px-2 border-b  ${currentTab === "Users" ? " border-primary text-primary" : "border-transparent"}  pb-2 leading-4 transition-all duration-150 ease-in-out`}
                                            type="button">
                                            Users
                                        </button>
                                    </Show>
                                </>
                            </Show>
                        </header>
                    </div>
                    {currentTab === "Password" && hasPermission(currentUserPermissions, PERMISSIONS.SETTING_PASSWORD) && employer?.meta?.authType === AuthType.Email ? (
                        <>
                            <Show if={employer?.meta?.authType === AuthType.Email && hasPermission(currentUserPermissions, PERMISSIONS.SETTING_PASSWORD)}>
                                <form className="my-6 w-full md:pl-10  mobile:px-5">
                                    <div className="border-b border-gray-200 pb-5 mb-5 px-2">
                                        <h4 className=" font-semibold text-lg text-gray-900 mb-2">Password</h4>
                                        <p className="font-medium text-sm text-gray-500">Please enter your current password to change your password.</p>
                                    </div>

                                    <div className="flex flex-col lg:flex-row gap-y-2 lg:gap-y-0 justify-between items-start border-b border-gray-200 pb-2 mb-2  px-2">
                                        <h4 className="font-medium text-sm text-gray-600">Current password</h4>
                                        <div className="w-full lg:w-[29.438rem]">
                                            <input type="password" className="input-base lg:w-[29.438rem] text-base text-[#667085] placeholder:text-xs " {...formik.getFieldProps("oldPassword")} placeholder="Enter your current password" />
                                            {formik.touched.oldPassword && formik.errors.oldPassword ? <div className="text-red-600 text-xs mt-1">{formik.errors.oldPassword}</div> : null}
                                        </div>
                                    </div>
                                    <div className="flex flex-col lg:flex-row gap-y-2 lg:gap-y-0 justify-between items-start border-b border-gray-200 pb-2 mb-2 px-2">
                                        <h4 className="font-medium text-sm text-gray-600">New password</h4>
                                        <div className="w-full lg:w-[29.438rem]">
                                            <input type="password" className="input-base lg:w-[29.438rem] text-base text-[#667085] placeholder:text-xs " {...formik.getFieldProps("newPassword")} placeholder="Enter your new password" />
                                            {formik.touched.newPassword && formik.errors.newPassword ? <div className="text-red-600 text-xs mt-1">{formik.errors.newPassword}</div> : null}
                                        </div>
                                    </div>
                                    <div className="flex flex-col lg:flex-row gap-y-2 lg:gap-y-0 justify-between items-start border-b border-gray-200 pb-2 mb-2 px-2">
                                        <h4 className="font-medium text-sm text-gray-600">Confirm new password</h4>
                                        <div className="w-full lg:w-[29.438rem]">
                                            <input type="password" className="input-base lg:w-[29.438rem] text-base text-[#667085] placeholder:text-xs " {...formik.getFieldProps("confirmNewPassword")} placeholder="Confirm your new password" />
                                            {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword ? <div className="text-red-600 text-xs mt-1">{formik.errors.confirmNewPassword}</div> : null}
                                        </div>
                                    </div>
                                    <div className="flex justify-end items-center gap-x-5 px-2 mt-2">
                                        <button
                                            className=" py-[10px] px-5 font-montserrat text-sm capitalize leading-5 text-center bg-white rounded-lg text-gray-700 border border-gray-300 shadow-sm "
                                            type="button"
                                            onClick={() => {
                                                formik.resetForm();
                                            }}>
                                            Cancel
                                        </button>
                                        <Button className="!w-fit px-4 h-10 bg-primary" onClick={formik.handleSubmit}>
                                            {loadingReset ? <ButtonLoader title="Updating password..." /> : "Update Password"}
                                        </Button>
                                    </div>
                                </form>
                            </Show>
                            <Show if={employer?.meta?.authType !== AuthType.Email && hasPermission(currentUserPermissions, PERMISSIONS.SETTING_PASSWORD)}>
                                <div className="mt-1 px-5 bg-red-500 ">
                                    <p className="w-full text-center  text-white text-sm font-medium leading-5">You are unable to change your password because you are using an OAuth provider either GoogleOAuth or FacebookOAuth</p>
                                </div>
                            </Show>
                        </>
                    ) : currentTab === "Notification" && hasPermission(currentUserPermissions, PERMISSIONS.SETTING_NOTIFICATION) ? (
                        <div>
                            <Notification />
                        </div>
                    ) : currentTab === "User-Role" && hasPermission(currentUserPermissions, PERMISSIONS.SETTING_ROLE) ? (
                        <div className="w-full px-5">
                            <UserRoles />
                        </div>
                    ) : currentTab === "Users" && hasPermission(currentUserPermissions, PERMISSIONS.SETTING_USER) ? (
                        <div className="w-full px-5">
                            <Users />
                        </div>
                    ) : (
                        <div className="w-full px-5">
                            <Subscription />
                        </div>
                    )}
                </div>
            </main>
        </Wrapper>
    );
};

export default SettingsPage;

// permissions.js
export const PERMISSIONS = {
    JOB_POST_VIEW: "job:post:view",
    JOB_CREATE: "job:create",
    JOB_UPDATE: "job:update",
    JOB_DELETE: "job:delete",
    JOB_ACTIVATE: "job:activate",
    JOB_REPOST: "job:repost",
    JOB_CLOSE: "job:close",
    JOB_GENERATION: "job:generation",
    JOB_DRAFT: "job:draft_view",
    JOB_DRAFT_DELETE: "job:draf:delete",
    JOB_DRAFT_POST: "job:draft:post",
    JOB_URGENT: "job:urgent",
    JOB_DETAILS: "job:details",

    PACKAGE_ENTERPRISE: "package:enterprise",
    DEDICATED_SUPPORT: "dedicated:support",

    CANDIDATE_VIEW: "candidate:view",
    CANDIDATE_DETAILS: "candidate:details",
    CANDIDATE_ACCEPT: "candidate:accept",
    CANDIDATE_REJECT: "candidate:reject",
    CANDIDATE_RATE: "candidate:rate",
    CANDIDATE_HIRE: "candidate:hire",
    CANDIDATE_MESSAGE: "candidate:message",

    MESSAGE_VIEW: "message:view",
    MESSAGE_DELETE: "message:delete",
    MESSAGE_REPORT: "message:report",
    MESSAGE_CLEAR: "message:clear",

    NOTIFICATION_VIEW: "notification:view",

    SETTING_PACKAGE: "setting:package",
    SETTING_PASSWORD: "setting:password",
    SETTING_NOTIFICATION: "setting:notification:view",
    SETTING_NOTIFICATION_UPDATE: "setting:notification:update",
    SETTING_USER: "setting:user",
    SETTING_USER_CREATE: "setting:user:create",
    SETTING_USER_UPDATE: "setting:user:update",
    SETTING_USER_DELETE: "setting:user:delete",
    SETTING_ROLE: "setting:role",
    SETTING_ROLE_CREATE: "setting:role:create",
    SETTING_ROLE_UPDATE: "setting:role:update",

    PROFILE_PERSONAL: "profile:personal",
    PROFILE_COMPANY: "profile:company",
    PROFILE_COMPANY_UPDATE: "profile:company:update",
    PROFILE_COMPANY_CONTACT_UPDATE: "profile:company:contact:update",
    PROFILE_PERSONAL_UPDATE: "profile:personal:update",
};

const Unpaid = ["job:post:view", "candidate:view", "message:view", "notification:view", "setting:password","setting:package", "setting:notification:view", "setting:notification:update", "profile:personal", "profile:personal:update", "profile:company", "profile:company:update", "profile:company:contact:update"];
const Basic = [
    "job:details",
    "job:create",
    "job:update",
    "job:delete",
    "job:close",
    "job:repost",
    "job:draft_view",
    "candidate:hire",
    "job:draf:delete",
    "job:draft:post",
    "candidate:details",
    "candidate:accept",
    "candidate:reject",
    "candidate:message",
    "message:delete",
    "message:report",
    "message:clear",
    "setting:package",
    "package:enterprise",
    "candidate:rate",
];
const Basic_Plus = [
    "job:details",
    "job:create",
    "job:update",
    "job:delete",
    "job:close",
    "job:repost",
    "job:draft_view",
    "candidate:hire",
    "job:draf:delete",
    "job:draft:post",
    "candidate:details",
    "candidate:accept",
    "candidate:reject",
    "candidate:message",
    "message:delete",
    "message:report",
    "message:clear",
    "setting:package",
    "package:enterprise",
    "candidate:rate",
    "dedicated:support",
];
const Pro = [
    "job:details",
    "job:create",
    "job:update",
    "job:delete",
    "job:close",
    "job:repost",
    "job:draft_view",
    "candidate:hire",
    "job:draf:delete",
    "job:draft:post",
    "candidate:details",
    "candidate:accept",
    "candidate:reject",
    "candidate:message",
    "message:delete",
    "message:report",
    "message:clear",
    "setting:package",
    "package:enterprise",
    "dedicated:support",
    "candidate:rate",
    "setting:user",
    "setting:user:create",
    "setting:user:update",
    "setting:role",
    "setting:role:create",
    "setting:role:update",
    "setting:user:delete",
];

const Max = [
    "job:details",
    "job:create",
    "job:update",
    "job:delete",
    "job:close",
    "job:repost",
    "job:draft_view",
    "candidate:hire",
    "job:draf:delete",
    "job:draft:post",
    "candidate:details",
    "candidate:accept",
    "candidate:reject",
    "candidate:message",
    "message:delete",
    "message:report",
    "message:clear",
    "setting:package",
    "package:enterprise",
    "dedicated:support",
    "candidate:rate",
    "setting:user",
    "setting:user:create",
    "setting:user:update",
    "setting:role",
    "setting:role:create",
    "setting:role:update",
    "setting:user:delete",
    "job:urgent",
    "job:generation",
];

export type Permission = (typeof PERMISSIONS)[keyof typeof PERMISSIONS];

export const hasPermission = (userPermissions: Permission[], permission: Permission) => {
    return userPermissions.includes(permission);
};

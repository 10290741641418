import { FC, Fragment, useRef, PropsWithChildren } from "react";
import { Dialog, Transition } from "@headlessui/react";

import wrapClick from "@/utils/wrap-click";
import classNames from "@/utils/classnames";
import { AiOutlineCloseCircle } from "react-icons/ai";
import _ from "lodash";

import { BiArrowBack } from "react-icons/bi";
import { Loader, Show } from "@/components";

interface ModalProps {
  description?: string | JSX.Element;
  descriptionType?: string;
  open: boolean;
  setOpen: (val: boolean) => void;
  renderActions?: () => JSX.Element;
  hideActions?: boolean;
  title?: string;
  size?: "3xl" | "4xl" | "5xl" | "6xl" | "7xl";
  loading?: boolean;
}

const sizeMap = {
  "3xl": "max-w-3xl",
  "4xl": "max-w-4xl",
  "5xl": "max-w-5xl",
  "6xl": "max-w-6xl",
  "7xl": "max-w-7xl",
};

const Modal: FC<PropsWithChildren<ModalProps>> = ({
  open,
  setOpen,
  title,
  children,
  renderActions,
  descriptionType,
  description,
  hideActions,
  size = "3xl",
  loading,
}) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={classNames(
          "fixed mobile:z-20 z-50 bg-black mobile:bg-opacity-0 bg-opacity-60 inset-0 overflow-y-auto"
        )}
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <div className="flex md:items-end justify-center min-h-screen pt-4 px-4 md:pb-20 text-center sm:block mobile:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Title className="fixed inset-0 bg-black-2  bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classNames(
                sizeMap[size],
                "w-full md:w-[39.931%] inline-block align-bottom bg-white mobile:rounded-none rounded-xl text-left overflow-hidden mobile:shadow-none shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full md:max-h-[90%]"
              )}
            >
              <div
                className={classNames(
                  title ? "border-b" : "",
                  "w-full mobile:border-b border-[#D6D6D6] mobile:pb-4 mobile:pt-[5rem] pt-4 mobile:px-4 pl-8 pr-4 flex mobile:gap-4 mobile:justify-start justify-between items-center"
                )}
              >
                <div className="hidden mobile:flex justify-center items-center">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400  hover:text-gray-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 "
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <BiArrowBack
                      className="h-6 w-6 text-black"
                      aria-hidden="true"
                    />
                  </button>
                </div>
                <div className="text-sm">
                  {title}
                  <Show if={!_.isEmpty(description)}>
                    <p className="font-montserrat font-semibold text-black text-base tracking-[0.1px] ">
                      {description}
                    </p>
                  </Show>
                </div>
                <div className="hidden sm:block">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400  hover:text-gray-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 "
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <AiOutlineCloseCircle
                      className="h-6 w-6 z-30"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
              <div className="sm:flex sm:flex-col  ">
                <div
                  className={classNames(
                    "py-5 pt-0 mobile:px-4 px-8 min-h-[50vh] overflow-y-auto",
                    loading ? "min-h-[50vh]" : "",
                    "flex flex-col flex-1 w-full max-h-[75vh]"
                  )}
                >
                  {loading ? <Loader /> : children}
                </div>
              </div>
              {!hideActions && !loading && (
                <div className="flex justify-end items-center gap-x-5  px-8 py-4 border-t border-grey-400 ">
                  <button
                    type="button"
                    className="py-[10px] px-4 font-montserrat font-normal
                    text-sm capitalize leading-5 text-center ring-red-500
                     bg-white rounded-lg text-gray-700
                     border border-grey-300 shadow-sm "
                    onClick={wrapClick(() => setOpen(false))}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                  <>{renderActions?.()}</>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;

import { ReactElement } from "react";

type Props = { title: string; children: ReactElement };

const CandidateDetailView = ({ title, children }: Props) => {
    return (
        <div className="w-full space-y-3">
            <h1 className=" w-full bg-slate-100 text-black font-semibold py-3 px-5 text-sm leading-3 rounded border border-slate-200">
                {title}
            </h1>
            <div className="w-full">{children}</div>
        </div>
    );
};

export default CandidateDetailView;

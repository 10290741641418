import Wrapper from "@/components/wrapper";
import { FC, useState } from "react";
import Representative from "./profiles/representative";
import Company from "./profiles/company";
import {  useCurrentUser, useCurrentUserPermissions } from "@/apollo/cache/auth";
import { Show } from "@/components";
import { hasPermission, PERMISSIONS } from "@/permissions";

type Props = {};

const ProfilePage: FC<Props> = () => {
    const currentUserPermissions = useCurrentUserPermissions()
    const [showRepProfile, setShowRepProfile] = useState(true);
    const [showCompanyProfile, setShowCompanyProfile] = useState(false);

    const employer = useCurrentUser();

    return (
        <Wrapper>
            <div className="relative w-full max-w-4xl">
                <div className="border-b border-gray-300 py-5 px-6">
                    <h1 className="font-montserrat font-semibold text-lg/5 tracking-[0.1px] text-black ">Profile</h1>
                </div>
                <header className="flex items-center gap-5 border-b border-gray-200 px-6 pt-4 transition-all duration-150 ease-in-out">
                    <button
                        onClick={() => {
                            setShowRepProfile(true);
                            setShowCompanyProfile(false);
                        }}
                        className={`font-montserrat text-sm  cursor-pointer border-b-2 py-3 transition-all duration-150 ease-in-out ${showRepProfile ? "border-primary text-primary" : "border-transparent text-[#667085]"}`}>
                        Representative Profile
                    </button>
                    <Show if={hasPermission(currentUserPermissions, PERMISSIONS.PROFILE_COMPANY)}>
                        <button
                            onClick={() => {
                                setShowCompanyProfile(true);
                                setShowRepProfile(false);
                            }}
                            className={`font-montserrat text-sm  cursor-pointer border-b-2 py-3 transition-all duration-150 ease-in-out ${showCompanyProfile ? "border-primary text-primary" : "border-transparent text-[#667085]"}`}>
                            Company Profile
                        </button>
                    </Show>
                </header>
                {showRepProfile ? <Representative user={employer} /> : <Company user={employer} />}
            </div>
        </Wrapper>
    );
};

export default ProfilePage;

import React, { FC, ReactElement } from "react";

interface ListProps<T = any> {
    data: T[];
    renderItem: (data: T) => ReactElement;
    renderEmptyComponent?: () => ReactElement;
}

const List: FC<ListProps> = ({ data, renderItem, renderEmptyComponent }) => {
    if (data?.length < 1) return <div>{renderEmptyComponent?.() || "N/A"}</div>;
    return <>{data?.map((item) => renderItem(item))}</>;
};

export default List;

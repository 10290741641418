import { Button, Show, SwitchProps } from "@/components";
import { UPDATE_SETTINGS } from "@/apollo/graphql/mutations/setting";
import { UpdateSettingsMutation, UpdateSettingsMutationVariables, UserType } from "@/apollo/generated/types";
import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useSettings, useUpdateEmployer } from "@/hooks";
import { useCurrentUser, useCurrentUserPermissions } from "@/apollo/cache/auth";
import toast from "react-hot-toast";
import ButtonLoader from "@/components/loaders/button";
import DefaultLoader from "@/components/loaders/default-loader";
import { hasPermission, PERMISSIONS } from "@/permissions";

interface NotificationSettingsState {
    updates: boolean;
    reminders: boolean;
    activities: boolean;
}

type Category = "updates" | "reminders" | "activities";

const Notification = () => {
    const employer = useCurrentUser();
    const currentUserPermissions = useCurrentUserPermissions();
    const { settings: settingValues, loading: loadingSettings } = useSettings({ variables: { userId: employer?.id || "", userType: UserType.Employer } });
    const [updateSettings, { loading: loadingUpdateSettings }] = useMutation<UpdateSettingsMutation, UpdateSettingsMutationVariables>(UPDATE_SETTINGS, { refetchQueries: ["Settings"] });
    const [settings, setSettings] = useState<NotificationSettingsState>({
        updates: false,
        reminders: false,
        activities: false,
    });

    const handleToggle = ({ category }: { category: Category }) => {
        setSettings((prev) => ({
            ...prev,
            [category]: !prev[category],
        }));
    };

    const handleSettingsUpdate = async () => {
        await updateSettings({
            variables: { input: { userId: employer?.id || "", userType: UserType.Employer, remindersEmail: settings.reminders, activitiesEmail: settings.activities, updatesEmail: settings.updates, updatesSms: false, activitiesSms: false, jobsEmail: false, jobsSms: false, remindersSms: false } },
        }).then(() => {
            toast(
                JSON.stringify({
                    type: "success",
                    title: "Notification settings updated successfully",
                })
            );
        });
    };

    const resetSettings = () => {
        const resetState: NotificationSettingsState = {
            updates: false,
            reminders: false,
            activities: false,
        };
        setSettings(resetState);
    };

    useEffect(() => {
        if (settingValues) {
            setSettings({
                updates: settingValues?.updatesEmail || false,
                reminders: settingValues?.remindersEmail || false,
                activities: settingValues?.activitiesEmail || false,
            });
        }
    }, [loadingSettings, settingValues]);
    return (
        <>
            {loadingSettings ? (
                <div className="h-full w-full">
                    <DefaultLoader />
                </div>
            ) : (
                <div className="my-6 lg:pl-10 px-2 w-full">
                    <div className="border-b border-grey-200 pb-5 mb-5">
                        <h4 className=" font-semibold text-lg text-grey-900 mb-2">Notification settings</h4>
                        <p className="text-xs font-medium leading-5  text-gray-500">We may still send you important notifications about your account outside of your notification settings.</p>
                    </div>

                    <div className="flex gap-x-2  justify-between items-start border-b border-grey-200 pb-5 mb-5">
                        <div className="">
                            <h4 className="font-semibold text-[#344054] text-sm ">Updates</h4>
                            <p className="text-xs font-medium text-gray-500">These are notifications for our future updates of Adjuma and other exciting opportunities</p>
                        </div>

                        <div className="flex flex-col gap-y-2">
                            <div className="flex gap-x-2">
                                <SwitchProps value={settings.updates} onChange={() => handleToggle({ category: "updates" })} />
                                <p className="font-[500] text-[#344054] text-sm">Email</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex gap-x-2 justify-between items-start border-b border-grey-200 pb-5 mb-5">
                        <div className="">
                            <h4 className="font-semibold text-[#344054] text-sm ">Reminders</h4>
                            <p className=" text-xs font-medium text-gray-500">These are notifications to remind you of updates you might have missed.</p>
                        </div>

                        <div className="flex flex-col gap-y-2">
                            <div className="flex gap-x-2">
                                <SwitchProps value={settings.reminders} onChange={() => handleToggle({ category: "reminders" })} />
                                <p className="font-[500] text-[#344054] text-sm">Email</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex gap-x-2 justify-between items-start border-b border-grey-200 pb-5 mb-5">
                        <div className="">
                            <h4 className="font-semibold text-[#344054] text-sm ">More activity about you</h4>
                            <p className="font-medium text-xs text-gray-500">These are notifications for your account’s activities</p>
                        </div>

                        <div className="flex flex-col gap-y-2">
                            <div className="flex gap-x-2">
                                <SwitchProps value={settings.activities} onChange={() => handleToggle({ category: "activities" })} />
                                <p className="font-[500] text-[#344054] text-sm">Email</p>
                            </div>
                        </div>
                    </div>

                    <Show if={hasPermission(currentUserPermissions, PERMISSIONS.SETTING_NOTIFICATION_UPDATE)}>
                        <div className="flex justify-end items-center gap-x-5">
                            <button className=" py-[10px] px-5 font-sans text-sm capitalize leading-5 text-center bg-white rounded-lg text-gray-700 border border-gray-300 shadow-sm " type="button" onClick={resetSettings}>
                                Cancel
                            </button>
                            <div>
                                <Button onClick={() => handleSettingsUpdate()}>{loadingUpdateSettings ? <ButtonLoader title=" Updating ....." /> : " Update Notifications"}</Button>
                            </div>
                        </div>
                    </Show>
                </div>
            )}
        </>
    );
};

export default Notification;

import {  GET_CONVERSATIONS } from "@/apollo/messages";
import {  GetConversationsQuery, GetConversationsQueryVariables } from "@/apollo/messages/generated/types";
import { useQuery, QueryHookOptions } from "@apollo/client";

export const useConversations = (options?: QueryHookOptions<GetConversationsQuery, GetConversationsQueryVariables>) => {
    const { data, ...rest } = useQuery<GetConversationsQuery, GetConversationsQueryVariables>(GET_CONVERSATIONS, {
        ...options,
        context: { apiName: "message" },
    });

    return {
        conversations: data?.getConversations,
        ...rest,
    };
};

import React, { FC } from "react";

type Props = {
    title: string;
    value?: string;
    multiple?: boolean;
    Icon?: React.ForwardRefExoticComponent<
        Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
            title?: string;
            titleId?: string;
        } & React.RefAttributes<SVGSVGElement>
    >;
};

const CandidateDetailItem: FC<Props> = ({ title, value, multiple, Icon }) => {
    return (
        <div className="space-y-1">
            <h1 className="text-xs text-slate-500 leading-3 flex justify-start items-center space-x-1">
                {Icon ? <Icon className="h-3 w-3" /> : null}
                <span>{title}</span>
            </h1>
            {multiple ? (
                <div className="flex justify-start items-center flex-wrap space-x-2">
                    {value?.split(",").map((data) => (
                        <h3 className="text-xs font-medium px-2 py-1 bg-slate-100 rounded">{data}</h3>
                    ))}
                </div>
            ) : (
                <h3 className="text-xs font-medium">{value}</h3>
            )}
        </div>
    );
};

export default CandidateDetailItem;

import { FC, Fragment, useRef, PropsWithChildren } from "react";
import { Dialog, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { classNames, wrapClick } from "@/utils";
import Loader from "./loader";

interface ModalProps {
    title: string;
    description?: string;
    open: boolean;
    setOpen: (val: boolean) => void;
    renderActions?: () => JSX.Element;
    hideActions?: boolean;
    hideCloseButton?: boolean;
    size?: "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "7xl";
    loading?: boolean;
}

const sizeMap = {
    sm: "md:max-w-sm",
    md: "md:max-w-md",
    lg: "md:max-w-lg",
    xl: "md:max-w-xl",
    "2xl": "md:max-w-2xl",
    "3xl": "md:max-w-3xl",
    "4xl": "md:max-w-4xl",
    "5xl": "md:max-w-5xl",
    "6xl": "md:max-w-6xl",
    "7xl": "md:max-w-7xl",
};

const Modal: FC<PropsWithChildren<ModalProps>> = ({ open, setOpen, hideCloseButton, children, renderActions, title, description, hideActions, loading, size = "3xl" }) => {
    const cancelButtonRef = useRef(null);

    return (
        <Transition show={open} as={Fragment}>
            <Dialog as="div" className={classNames("fixed z-50 inset-0 overflow-y-auto")} initialFocus={cancelButtonRef} onClose={setOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 text-center sm:block sm:p-0">
                    <Transition as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div className={classNames(sizeMap[size], "inline-block w-screen align-bottom bg-white  rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full")}>
                            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                {!hideCloseButton && (
                                    <button type="button" className="bg-white  rounded-md text-gray-400  hover:text-gray-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 " onClick={() => setOpen(false)}>
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                )}
                            </div>
                            <div className="sm:flex sm:flex-col">
                                {title && (
                                    <div className="mx-auto p-3 flex-shrink-0 flex flex-col items-start rounded sm:mx-0 sm:px-6 sm:py-4 border-b border-gray-200">
                                        <DialogTitle as="h3" className="flex-1 text-lg leading-6 font-medium text-gray-900  text-center sm:text-left">
                                            {title}
                                        </DialogTitle>
                                        {description && <p className="w-full mt-1  text-base  text-gray-600  sm:w-auto sm:text-sm">{description}</p>}
                                    </div>
                                )}
                                <div className={classNames(hideActions ? "" : "md:p-6 min-h-full md:min-h-[30vh]", loading ? "md:p-6 min-h-[30vh] flex items-center justify-center" : "", "flex-1 p-6 md:w-full md:max-h-[75vh] overflow-y-auto")}>{loading ? <Loader /> : children}</div>
                            </div>
                            {!hideActions && (
                                <div className="bg-gray-50  px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
                                    <>{renderActions?.()}</>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300  shadow-sm px-4 py-2 bg-white  text-base font-medium text-gray-700  hover:bg-gray-50 hover: focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={wrapClick(() => setOpen(false))}
                                        ref={cancelButtonRef}>
                                        Close
                                    </button>
                                </div>
                            )}
                        </div>
                    </TransitionChild>
                </div>
            </Dialog>
        </Transition>
    );
};

export default Modal;

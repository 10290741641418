import { FC, Suspense } from "react";
import { Link, Navigate, Outlet } from "react-location";
import { useCurrentToken, useCurrentUser } from "@/apollo/cache/auth";
import { Disclosure } from "@headlessui/react";
import LoadingComponent from "@/components/loaders/center";
import logo from "@/assets/images/logo.png";

import { DEV_LANDING_URL, PROD_LANDING_URL, TEST_LANDING_URL } from "@/constants/page-paths";

const AuthLayout: FC = () => {
    const currentUser = useCurrentUser();
    const currentToken = useCurrentToken();

    if (currentToken && currentUser?.id) {
        return <Navigate to={"/"} replace />;
    }
    return (
        <div className={`w-full overflow-x-hidden relative  flex bg-white h-screen `}>
            {/* for sidebar */}

            {/* Main column */}
            <div className="w-full m-auto flex flex-col justify-start items-center  h-full">
                {/* Static navbar for desktop */}
                <div className="w-full ">
                    <Disclosure as="nav" className="bg-white ">
                        {({ open }) => (
                            <>
                                <div className="relative flex h-16 justify-between border-b px-2 sm:px-6 lg:px-8">
                                    <Link to="/" className="flex flex-shrink-0 cursor-pointer items-center">
                                        <img src={logo} alt="logo" className="max-w-[133.1px] max-h-[45.14px] object-contain" />
                                    </Link>
                                </div>
                            </>
                        )}
                    </Disclosure>
                </div>
                <main className="max-w-7xl w-full h-full overflow-scroll overflow-y-scroll pb-5">
                    <Suspense fallback={LoadingComponent()}>
                        <Outlet />
                    </Suspense>
                </main>
                <div className="w-full pb-5 flex justify-center items-center">
                    <a className="flex w-fit  flex-col justify-center items-center hover:text-primary-500 cursor-pointer text-slate-700 " href="https://adjuma.io" target="_blank">
                        <span className="text-sm font-semibold md:hidden lg:flex">Adjuma Workforce Solution</span>
                        <span className="text-xs font-semibold md:hidden lg:flex ">adjuma.io</span>
                        <span className="text-xs font-medium text-center">&copy; {new Date().getFullYear()}</span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default AuthLayout;

import { gql } from "@apollo/client";

export const GET_PACKAGES = gql`
    query GetPackages($filter: PackageFilter, $pagination: Pagination) {
        getPackages(filter: $filter, pagination: $pagination) {
            id
            name
            description
            price
            interval
            jobsQuota
            privileges
            permissions
            planCode
            planId
            createdAt
            updatedAt
            deletedAt
        }
    }
`;

export const GET_PACKAGE = gql`
    query GetPackage($filter: PackageFilter!) {
        getPackage(filter: $filter) {
            id
            name
            description
            price
            interval
            jobsQuota
            privileges
            permissions
            planCode
            planId
            createdAt
            updatedAt
            deletedAt
        }
    }
`;


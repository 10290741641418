import { gql } from "@apollo/client";

// export const GET_COMPANY_BY_USER = gql``;

export const UPDATE_COMPANY = gql`
    mutation UpdateCompanyInformation($input: UpdateCompanyInfo!) {
        updateCompanyInformation(input: $input)
    }
`;

export const UPDATE_COMPANY_CONTACT = gql`
    mutation UpdateCompanyContactInformation($input: UpdateCompanyContactInfo!) {
        updateCompanyContactInformation(input: $input)
    }
`;

export const UPDATE_COMPANY_PROFILE = gql`
    mutation UpdateCompanyProfilePicture($updateCompanyProfilePictureId: ID!, $companyLogo: String!) {
        updateCompanyProfilePicture(id: $updateCompanyProfilePictureId, companyLogo: $companyLogo)
    }
`;

import { classNames } from "@/utils";
import React, { FC } from "react";

type Props = {
    color: string;
    bg: string;
    loading?: boolean;
    title: string;
    value: number;
    Icon: React.ForwardRefExoticComponent<
        Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
            title?: string;
            titleId?: string;
        } & React.RefAttributes<SVGSVGElement>
    >;
    active: boolean;
    onClick: () => void;
};

const SummaryItem: FC<Props> = ({ bg, loading, title, color, value, onClick, Icon, active }) => {
    return (
        <button onClick={onClick} className={`${active ? "border-primary-500 border-2" : "border border-gray-300"}  rounded-lg p-4 w-52 flex-shrink-0 cursor-pointer`}>
            <div className="flex items-center justify-between">
                <div className={classNames(bg, "w-10 h-10 rounded-full bg-[#EBFFEB] flex justify-center items-center")}>
                    <Icon className={classNames(color, "w-5 h-6 ")} aria-hidden="true" />
                </div>
                <p className="font-medium text-sm text-gray-500">{loading ? "Loading..." : title.toUpperCase()}</p>
            </div>

            <h2 className="font-bold text-3xl text-gray-700 text-right mt-3">{value}</h2>
        </button>
    );
};

export default SummaryItem;

import "@mantine/core/styles.css";
import NotificationProvider from "./notifications";
import LayoutProvider from "./layouts";
import ApolloProvider from "./apollo";
import RoutesProvider from "./router";
import MantimeProviderLayout from "./mantime";
import { Tooltip } from "react-tooltip";
// import TranslationProvider from 'translation';

function App() {
    return (
        // <TranslationProvider>
        <ApolloProvider>
            <NotificationProvider>
                <RoutesProvider>
                    <MantimeProviderLayout>
                        <>
                            <LayoutProvider />
                            <Tooltip id="global-tooltip" />
                        </>
                    </MantimeProviderLayout>
                </RoutesProvider>
            </NotificationProvider>
        </ApolloProvider>
        // </TranslationProvider>
    );
}

export default App;

import { SalaryFrequency } from "@/apollo/generated/types";
import { Button } from "@/components";
import ButtonLoader from "@/components/loaders/button";
import { useAppSelector } from "@/redux/hook";
import { selectRepostJob } from "@/redux/job/repostJobSlice";
import { formatCurrency, formatPeriod } from "@/utils/formatter";
import { BanknotesIcon, BriefcaseIcon, ClockIcon, GlobeAltIcon, MapPinIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import moment from "moment";

type Props = {
    loading: boolean;
    handleSubmit: () => void;
    setActive: (value: string) => void;
    employerQuota: number;
    activeQuota: number;
};

const RepostJobPreview = ({ loading, handleSubmit, setActive, employerQuota, activeQuota }: Props) => {
    const repostJobDetails = useAppSelector(selectRepostJob);

    return (
        <div className="w-full space-y-5 ">
            <div className="space-y-3 py-5">
                <h1 className="text-2xl font-semibold ">{repostJobDetails?.jobTitle.toUpperCase()}</h1>
                <div className="md:flex md:justify-start md:items-center space-x-5 space-y-2 flex-wrap grid  grid-cols-2">
                    {/* comps */}
                    {repostJobDetails?.jobLocation && (
                        <div className="flex text-gray-500 bg-gray-50 items-center justify-center space-x-1 px-2 py-1 rounded  ">
                            <GlobeAltIcon className="h-4 w-4" />
                            <span className="text-[11px] ">{repostJobDetails?.jobLocation}</span>
                        </div>
                    )}
                    {/* comps */}
                    {repostJobDetails?.minimumSalary && repostJobDetails?.maximumSalary ? (
                        <div className="flex  items-center justify-center space-x-1 bg-green-50 px-2 py-1 rounded ">
                            <BanknotesIcon className="h-4 w-4" />
                            <span className="text-[11px] ">
                                {formatCurrency(repostJobDetails?.currency)}{repostJobDetails?.minimumSalary} -  {formatCurrency(repostJobDetails?.currency)}{repostJobDetails?.maximumSalary} / {formatPeriod(repostJobDetails?.duration as SalaryFrequency)}
                            </span>
                        </div>
                    ) : (
                        <div className="flex  items-center justify-center space-x-1 bg-green-50 px-2 py-1 rounded ">
                            <BanknotesIcon className="h-4 w-4" />
                            <span className="text-[11px] ">Negotiable</span>
                        </div>
                    )}
                    {/* comps */}
                    {repostJobDetails?.jobType && (
                        <div className="flex  items-center justify-center space-x-1 bg-gray-50 px-2 py-1 rounded  ">
                            <BriefcaseIcon className="h-4 w-4" />
                            <span className="text-[11px] ">{repostJobDetails?.jobType}</span>
                        </div>
                    )}
                    {/* comps */}
                    {repostJobDetails?.modeOfWork && (
                        <div className="flex  items-center justify-center space-x-1 bg-gray-50 px-2 py-1 rounded  ">
                            <MapPinIcon className="h-4 w-4" />
                            <span className="text-[11px] ">{repostJobDetails?.modeOfWork}</span>
                        </div>
                    )}
                    {/* comp */}
                    {repostJobDetails.openingDate && (
                        <div className="flex text-gray-500 items-center justify-center space-x-1 px-2 py-1 rounded  bg-blue-50">
                            <ClockIcon className="h-4 w-4" />
                            <span className="text-[11px] ">Opening : {moment(repostJobDetails.openingDate).format("MMMM Do, YYYY")}</span>
                        </div>
                    )}
                    {/* comps */}
                    {repostJobDetails.closingDate && (
                        <div className="flex  items-center justify-center space-x-1 bg-red-50 px-2 py-1 rounded ">
                            <ClockIcon className="h-4 w-4" />
                            <span className="text-[11px] ">Closing : {moment(repostJobDetails.closingDate).format("MMMM Do, YYYY")}</span>
                        </div>
                    )}
                </div>
            </div>
            <div className="space-y-1">
                <h2 className="text-base font-semibold">About This Job</h2>
                <div
                    dangerouslySetInnerHTML={{
                        __html: repostJobDetails?.about,
                    }}
                    className="text-sm font-light text-gray-600 description"
                />
            </div>
            <div className="space-y-1">
                <h2 className="text-base font-semibold">Responsibilities</h2>
                <div
                    dangerouslySetInnerHTML={{
                        __html: repostJobDetails?.responsibilities,
                    }}
                    className="text-sm font-light text-gray-600 description"
                />
            </div>
            <div className="space-y-1">
                <h2 className="text-base font-semibold">Requirements/Qualifications</h2>
                <div
                    dangerouslySetInnerHTML={{
                        __html: repostJobDetails?.requirements,
                    }}
                    className="text-sm font-light text-gray-600 description"
                />
            </div>

            {!_.isEmpty(repostJobDetails?.benefits) && repostJobDetails?.benefits?.length > 11  && (
                <div className="space-y-1">
                    <h2 className="text-base font-semibold">Benefits</h2>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: repostJobDetails?.benefits,
                        }}
                        className="text-sm font-light text-gray-600 description"
                    />
                </div>
            )}

            <div className="col-span-full flex items-center justify-between space-x-5">
                <div>
                    <Button variant="minimal" size="md" onClick={() => setActive("Date Adjustment")}>
                        Previous
                    </Button>
                </div>
                <div className="flex justify-end items-center space-x-5">
                    <div></div>
                    <div>
                        {employerQuota > activeQuota ? (
                            <Button variant="primary" size="md" onClick={handleSubmit}>
                                <span className="flex justify-between items-center space-x-2">
                                    <span>{loading ? <ButtonLoader title="Reposting ... " /> : "Repost Job"}</span>
                                </span>
                            </Button>
                        ) : (
                            <Button variant="minimal" disabled={true}>
                                <span className="text-xs text-red-400 font-medium">Active job quota exceeded</span>
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RepostJobPreview;

import { useAppNavigate, useCurrentUser } from "@/apollo/cache/auth";
import { DeleteEmployerFromCompanyMutation, DeleteEmployerFromCompanyMutationVariables } from "@/apollo/generated/types";
import { DELETE_EMPLOYER_USER } from "@/apollo/graphql/mutations/setting";
import { Button, Modal } from "@/components";
import ButtonLoader from "@/components/loaders/button";
import { useUrlState } from "@/utils";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export default function DeleteUserModal({ open, setOpen }: Props) {
    const [id] = useUrlState("id");
    const navigate = useAppNavigate();
    const employer = useCurrentUser();
    const [deleteEmployer, { loading }] = useMutation<DeleteEmployerFromCompanyMutation, DeleteEmployerFromCompanyMutationVariables>(DELETE_EMPLOYER_USER, { refetchQueries: ["GetEmployers"] });
    const handleDelete = async () => {
        await deleteEmployer({ variables: { companyId: employer?.companyId || "", userId: id || "" } }).then(() => {
            toast(
                JSON.stringify({
                    type: "success",
                    title: "User Deleted Successffully",
                })
            );
            navigate({
                search(prev) {
                    return { ...prev, modal: undefined, id: undefined };
                },
            });
        });
    };
    return (
        <Modal title={""} open={open} setOpen={setOpen} hideActions={true} size="sm">
            <div>
                <div className="text-center mt-5">
                    <h3 className="font-montserrat text-sm font-semibold text-black">Delete Team Member</h3>
                    <div className="mt-5">
                        <p className="font-montserrat text-xs/5 text-black ">
                            Are you sure you want to delete <br /> this team member?
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-8 flex justify-around items-center gap-5">
                <div>
                    <Button
                        type="button"
                        variant="minimal"
                        onClick={() =>
                            navigate({
                                search(prev) {
                                    return { ...prev, modal: undefined, id: undefined };
                                },
                            })
                        }>
                        Cancel
                    </Button>
                </div>
                <div>
                    <Button type="button" onClick={() => handleDelete()} variant="danger">
                        {loading ? <ButtonLoader title="Deleting ..." /> : "Delete"}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

import { FormikProps } from "formik";

type Props = { value: string; form: FormikProps<any> };

const PermissionCheckBox = ({ value, form }: Props) => {
    return (
        <div className="flex text-xs space-x-2">
            <input
                type="checkbox"
                name={value}
                value={value}
                checked={form.values.permissions.includes(value)}
                onChange={() => {
                    const newPermissions = form.values.permissions.includes(value) ? form.values.permissions.filter((info: string) => info !== value) : [...form.values.permissions, value];
                    form.setFieldValue("permissions", newPermissions);
                }}
                className="bg-primary-500 accent-primary-500 ring-primary-500"
            />
            <label htmlFor={value}>{value}</label>
        </div>
    );
};

export default PermissionCheckBox;

import { GetEmployersQuery, GetEmployersQueryVariables } from "@/apollo/generated/types";
import { GET_EMPLOYERS } from "@/apollo/graphql/queries/employer";
import { useQuery, QueryHookOptions } from "@apollo/client";

export const useUsers = (options?: QueryHookOptions<GetEmployersQuery, GetEmployersQueryVariables>) => {
    const { data, ...rest } = useQuery<GetEmployersQuery, GetEmployersQueryVariables>(GET_EMPLOYERS, {
        ...options,
    });

    return {
        users: data?.getEmployers,
        ...rest,
    };
};

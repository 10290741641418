import { GetCandidatesQuery, GetCandidatesQueryVariables } from "@/apollo/generated/types";
import { GET_CANDIDATES } from "@/apollo/graphql/queries/candidate";
import { useQuery, QueryHookOptions } from "@apollo/client";

export const useCandidates = (options?: QueryHookOptions<GetCandidatesQuery, GetCandidatesQueryVariables>) => {
    const { data, ...rest } = useQuery<GetCandidatesQuery, GetCandidatesQueryVariables>(GET_CANDIDATES, {
        ...options,
    });

    return {
        candidates: data?.getCandidates,
        ...rest,
    };
};

import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { UPLOAD_FILE } from "@/helpers/upload";

export const useUploadImage = () => {
    const [image, setImage] = useState<string>("");
    const [error, setError] = useState("");
    const { isLoading, mutate: uploadFile } = useMutation({
        mutationKey: ["uploadFile"],
        mutationFn: UPLOAD_FILE,
    });

    const handleUpload = async (file: any) => {
        const data = new FormData();
        data.append("file", file);
        uploadFile(data, {
            onSuccess: (data) => {
                setImage(data?.url);
            },
            onError: () => {
                setError("Something wrong happened");
            },
        });
    };

    return {
        image,
        setImage,
        loading: isLoading,
        error,
        handleUpload,
    };
};

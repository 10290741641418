import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { classNames } from "@/utils";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

type Option = { label: string; value: string };

type Props = {
    options: Option[];
    onSelected: (value: Option) => void;
    value: Option;
    label?: string;
    required?: boolean;
    labelHidden?: boolean;
    noRing?: boolean;
};

export default function SelectInput({ options, onSelected, label, required, labelHidden, noRing, value }: Props) {
    return (
        <Listbox
            value={value}
            onChange={(option) => {
                onSelected(option);
            }}>
            {({ open }) => (
                <>
                    {!labelHidden && (
                        <Label className="block text-sm font-medium text-gray-700">
                            {label} {required ? <span className="text-primary-500">*</span> : ""}
                        </Label>
                    )}

                    <div className="relative mt-2">
                        <ListboxButton
                            className={classNames(
                                noRing ? "" : "ring-1 shadow-sm focus:ring-2 focus:ring-primary-600 h-10",
                                " relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 ring-inset ring-gray-300 focus:outline-none sm:text-sm sm:leading-6"
                            )}>
                            <span className="block truncate">{value.label}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </ListboxButton>

                        <Transition show={open} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                            <ListboxOptions
                                className={classNames(
                                    "absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg  ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                )}>
                                {options.map((option, index) => (
                                    <ListboxOption
                                        key={index}
                                        className={({ focus }) =>
                                            classNames(focus ? "bg-primary-600 text-white" : "", !focus ? "text-gray-900" : "", "relative cursor-default select-none py-2 pl-8 pr-4")
                                        }
                                        value={option}>
                                        {({ selected, focus }) => (
                                            <>
                                                <span className={classNames(selected ? "font-semibold" : "font-normal", "block whitespace-nowrap")}>{option.label}</span>
                                                {selected ? (
                                                    <span className={classNames(focus ? "text-white" : "text-primary-600", "absolute inset-y-0 left-0 flex items-center pl-1.5")}>
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </ListboxOption>
                                ))}
                            </ListboxOptions>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    );
}

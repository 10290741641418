import { useAppNavigate } from "@/apollo/cache/auth";

type Props = {
    id: string;
    type?: string;
    additionOnClickAction?: () => void;
};

const ViewCandidateButton = ({ id, type, additionOnClickAction }: Props) => {
    const navigate = useAppNavigate();
    return (
        <button
            data-tooltip-delay-show={100}
            data-tooltip-id="global-tooltip"
            data-tooltip-content="View Candidate"
            onClick={() => {
                navigate({
                    search(prev) {
                        return { ...prev, id: id, drawer: "viewCandidate" };
                    },
                });
                additionOnClickAction && additionOnClickAction();
            }}
            className={type ? "text-xs px-4 py-2 text-primary-500 font-semibold cursor-pointer" : "px-4 py-2  cursor-pointer bg-primary-500 text-white text-xs rounded-md"}>
            View Profile
        </button>
    );
};

export default ViewCandidateButton;

import { onError } from "@apollo/client/link/error";
import { SentryLink, excludeGraphQLFetch } from "apollo-link-sentry";
import * as Sentry from "@sentry/react";
import toast from "react-hot-toast";
import config from "../../config";

Sentry.init({
  environment: config.sentry.env,
  dsn: config.sentry.dsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "https://test.gateway.adjuma.io",
    "https://api.adjuma.io",
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, //
});

const sentryMiddleware = new SentryLink({
  uri: config.apollo.uri,
  setTransaction: true,
  setFingerprint: true,
});

const errorMiddleware = onError((errors) => {
  if (errors.graphQLErrors)
    errors.graphQLErrors.forEach(
      ({ message, locations, path, originalError }) => {
        toast(JSON.stringify({ type: "error", title: message }));
      }
    );

  if (errors.networkError) {
    toast(
      JSON.stringify({ type: "error", title: errors.networkError.message })
    );
  }
  Sentry.captureException(errors);
}).split(() => config.env === "production", sentryMiddleware);

export default errorMiddleware;


import RichEditor from "@/components/core/rich-text-editor";
import { FormikProps } from "formik";

type Props = { form: FormikProps<any> };

const EditJobDescriptionForm = ({ form }: Props) => {
    return (
        <div className="w-full ">
            <div className="w-full mb-10">
                <h1 className="font-bold text-xl">Job Description</h1>
                <p className="text-sm text-gray-500 mt-1">Fill in the fields with the necessary information.</p>
            </div>
            <div className="w-full grid grid-cols-1 gap-10">
                <div className="w-full lg:w-[unset] border-b py-2 ">
                    <RichEditor id={"about"} label="About Job" {...form} />
                </div>
                <div className="w-full lg:w-[unset] border-b py-2 ">
                    <RichEditor id={"responsibilities"} label="Responsibilities" {...form} />
                </div>
                <div className="w-full lg:w-[unset] border-b py-2 ">
                    <RichEditor id={"requirements"} label="Requirements/Qualifications" {...form} />
                </div>
                <div className="w-full lg:w-[unset] border-b py-2 ">
                    <RichEditor id={"benefits"} label="Benefits" {...form} />
                </div>
            
            </div>
        </div>
    );
};

export default EditJobDescriptionForm;

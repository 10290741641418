import DefaultLoader from "@/components/loaders/default-loader";
import _ from "lodash";
import NotificationItem from "./NotificationItem";
import notificationPic from "@/assets/images/no-notif.svg";
import moment from "moment";
import { Notification } from "@/apollo/generated/types";

type Props = { loading: Boolean; notifications: (Notification | null)[] | undefined | null };

const NotificationList = ({ loading, notifications }: Props) => {
    return (
        <div className=" h-full bg-white border-r border-gray-200 w-full overflow-scroll overflow-y-scroll no-scrollbar ">
            <div className="flex flex-col justify-start relative">
                <div className="bg-white sticky top-0 z-20">
                    <h1 className="font-semibold text-black text-lg leading-5 tracking-[0.1px] py-5 border-b border-gray-200 px-5">Notifications</h1>
                </div>
                <div className="w-full mobile:mt-0 z-10">
                    {loading ? (
                        <div className="h-full w-full">
                            <DefaultLoader />
                        </div>
                    ) : !_.isEmpty(notifications) && !loading ? (
                        <>
                            {(notifications)?.map((data) => (
                                <NotificationItem id={data?.id || ""} read={data?.read || false} key={data?.id} title={data?.title || ""} createdAt={moment(data?.createdAt).fromNow() || ""} />
                            ))}
                        </>
                    ) : (
                        <div className="flex flex-col items-center w-full pt-10 gap-y-5">
                            <img src={notificationPic} alt="no notification picture" className="w-60 h-36 object-contain" />
                            <h4 className="font-semibold text-base text-black">No notifications yet</h4>
                            <p className="text-sm text-center text-gray-600 max-w-[23.563rem]">You do not have any notifications yet, kindly come back later.</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NotificationList;

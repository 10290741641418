import { gql } from "@apollo/client";

export const ACCEPT_CANDIDATE = gql`
    mutation AcceptApplication($applicationId: ID!, $jobId: ID!) {
        acceptApplication(applicationId: $applicationId, jobId: $jobId)
    }
`;

export const REJECT_CANDIDATE = gql`
    mutation RejectApplication($applicationId: ID!, $jobId: ID!) {
        rejectApplication(applicationId: $applicationId, jobId: $jobId)
    }
`;

export const HIRE_CANDIDATE = gql`
    mutation HireCandidate($applicationId: ID!, $jobId: ID!) {
        hireCandidate(applicationId: $applicationId, jobId: $jobId)
    }
`;
export const RATE_CANDIDATE = gql`
    mutation RateCandidate($candidateId: ID!, $rating: Int!, $comment: String) {
        rateCandidate(candidateId: $candidateId, rating: $rating, comment: $comment)
    }
`;

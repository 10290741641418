import { SalaryFrequency } from "@/apollo/generated/types";
import { useAppSelector } from "@/redux/hook";
import { selectEditJob } from "@/redux/job/editJobSlice";
import { formatCurrency, formatPeriod } from "@/utils/formatter";
import { BanknotesIcon, BriefcaseIcon, ClockIcon, GlobeAltIcon, MapPinIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import moment from "moment";

const EditJobPreviewForm = () => {
    const editJobPost = useAppSelector(selectEditJob);

    return (
        <div className="w-full space-y-5 ">
            <div className="space-y-3 py-5">
                <h1 className="text-2xl font-semibold ">{editJobPost?.jobTitle.toUpperCase()}</h1>
                <div className="md:flex md:justify-start md:items-center space-x-5 space-y-2 flex-wrap grid  grid-cols-2">
                    {/* comps */}
                    {editJobPost?.jobLocation && (
                        <div className="flex text-gray-500 bg-gray-50 items-center justify-center space-x-1 px-2 py-1 rounded  ">
                            <GlobeAltIcon className="h-4 w-4" />
                            <span className="text-[11px] ">{editJobPost?.jobLocation}</span>
                        </div>
                    )}
                    {/* comps */}
                    {(editJobPost?.minimumSalary || 0) > 0 && (editJobPost?.maximumSalary || 0) > 0 ? (
                        <div className="flex  items-center justify-center space-x-1 bg-green-50 px-2 py-1 rounded ">
                            <BanknotesIcon className="h-4 w-4" />
                            <span className="text-[11px] ">
                                {formatCurrency(editJobPost?.currency)}{editJobPost?.minimumSalary} - {formatCurrency(editJobPost?.currency)}{editJobPost?.maximumSalary} / {formatPeriod(editJobPost?.duration as SalaryFrequency)}
                            </span>
                        </div>
                    ) : (
                        <div className="flex  items-center justify-center space-x-1 bg-green-50 px-2 py-1 rounded ">
                            <BanknotesIcon className="h-4 w-4" />
                            <span className="text-[11px] ">Negotiable</span>
                        </div>
                    )}
                    {/* comps */}
                    {editJobPost?.jobType && (
                        <div className="flex  items-center justify-center space-x-1 bg-gray-50 px-2 py-1 rounded  ">
                            <BriefcaseIcon className="h-4 w-4" />
                            <span className="text-[11px] ">{editJobPost?.jobType}</span>
                        </div>
                    )}
                    {/* comps */}
                    {editJobPost?.modeOfWork && (
                        <div className="flex  items-center justify-center space-x-1 bg-gray-50 px-2 py-1 rounded  ">
                            <MapPinIcon className="h-4 w-4" />
                            <span className="text-[11px] ">{editJobPost?.modeOfWork}</span>
                        </div>
                    )}
                    {/* comp */}
                    {editJobPost.openingDate && (
                        <div className="flex text-gray-500 items-center justify-center space-x-1 px-2 py-1 rounded  bg-blue-50">
                            <ClockIcon className="h-4 w-4" />
                            <span className="text-[11px] ">Opening : {moment(editJobPost.openingDate).format("MMMM Do, YYYY")}</span>
                        </div>
                    )}
                    {/* comps */}
                    {editJobPost.closingDate && (
                        <div className="flex  items-center justify-center space-x-1 bg-red-50 px-2 py-1 rounded ">
                            <ClockIcon className="h-4 w-4" />
                            <span className="text-[11px] ">Closing : {moment(editJobPost.closingDate).format("MMMM Do, YYYY")}</span>
                        </div>
                    )}
                </div>
            </div>
            <div className="space-y-1">
                <h2 className="text-base font-semibold">About This Job</h2>
                <div
                    dangerouslySetInnerHTML={{
                        __html: editJobPost?.about,
                    }}
                    className="text-sm font-light text-gray-600 description"
                />
            </div>
            <div className="space-y-1">
                <h2 className="text-base font-semibold">Responsibilities</h2>
                <div
                    dangerouslySetInnerHTML={{
                        __html: editJobPost?.responsibilities,
                    }}
                    className="text-sm font-light text-gray-600 description"
                />
            </div>
            <div className="space-y-1">
                <h2 className="text-base font-semibold">Requirements/Qualifications</h2>
                <div
                    dangerouslySetInnerHTML={{
                        __html: editJobPost?.requirements,
                    }}
                    className="text-sm font-light text-gray-600 description"
                />
            </div>

            {!_.isEmpty(editJobPost?.benefits) && editJobPost?.benefits?.length > 11  && (
                <div className="space-y-1">
                    <h2 className="text-base font-semibold">Benefits</h2>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: editJobPost?.benefits,
                        }}
                        className="text-sm font-light text-gray-600 description"
                    />
                </div>
            )}
        </div>
    );
};

export default EditJobPreviewForm;

import { useCurrentUser } from "@/apollo/cache/auth";
import { DeleteDraftedJobMutation, DeleteDraftedJobMutationVariables } from "@/apollo/generated/types";
import { DELETE_DRAFT_JOB } from "@/apollo/graphql/mutations/job";
import { Modal } from "@/components";
import { useUrlState } from "@/utils";
import { useMutation } from "@apollo/client";
import _ from "lodash";
import toast from "react-hot-toast";

type Props = { open: boolean; setOpen: (value: boolean) => void };

const DeleteDraftedJobModal = ({ open, setOpen }: Props) => {
    const [jobId] = useUrlState("draft_id");
    const employer = useCurrentUser();

    const [deleteDraftJob, { loading }] = useMutation<DeleteDraftedJobMutation, DeleteDraftedJobMutationVariables>(DELETE_DRAFT_JOB, { refetchQueries: ["GetDraftedJobs", "GetJobStats"] });

    const handleDeleteDraft = async () => {
        await deleteDraftJob({
            variables: {
                companyId: employer?.companyId || "",
                jobId: jobId || "",
            },
        })
            .then(() => {
                setOpen(false);
                toast(
                    JSON.stringify({
                        type: "success",
                        title: " Drafted Job Deleted Successful",
                    })
                );
            })
            .catch(() => {
                setOpen(false);
                toast(
                    JSON.stringify({
                        type: "error",
                        title: "Something wrong happened",
                    })
                );
            });
    };
    return (
        <Modal hideActions open={open} setOpen={setOpen} size="sm" title={""}>
            <div className="w-full h-full space-y-5">
                <h1 className="w-full text-center font-semibold text-xl">Delete Post</h1>
                <h6 className="w-full text-center text-sm">
                    Are you sure you want to delete <br /> this job
                </h6>
                <div className="w-full flex justify-between p-x-5 items-center">
                    <button className="px-2 py-2 text-sm text-gray-500 border rounded font-semibold" onClick={() => setOpen(false)}>
                        Cancel
                    </button>
                    <button className="text-sm px-2 py-2 bg-primary-500 text-white rounded font-semibold" onClick={handleDeleteDraft}>
                        {loading ? "Deleting ..." : "Delete"}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default DeleteDraftedJobModal;

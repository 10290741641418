import { SalaryFrequency } from "@/apollo/generated/types";

export function formatPeriod(period: SalaryFrequency) {
    switch (period) {
        case SalaryFrequency.Yearly:
            return "yr";
        case SalaryFrequency.Monthly:
            return "mo";
        case SalaryFrequency.Weekly:
            return "wk";
        case SalaryFrequency.Daily:
            return "day";
        case SalaryFrequency.Hourly:
            return "hr";
        default:
            return "mo";
    }
}

export function formatCurrency(currency: string) {
    switch (currency) {
        case "GHS":
            return '₵';
        case "USD":
            return "$";
        default:
            return '₵';
    }
}

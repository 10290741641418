import { FC } from "react";
import _ from "lodash";
import classNames from "@/utils/classnames";

interface Option {
  label: string;
  value: string | number;
}

export interface SelectInputProps {
  id: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  values: any;
  handleChange: any;
  handleBlur: any;
  errors?: any;
  touched?: any;
  options: (string | Option)[];
}

const SelectInput: FC<SelectInputProps> = ({
  id,
  disabled,
  required,
  options,
  values,
  handleChange,
  handleBlur,
  placeholder,
  label,
  errors,
  touched,
}) => {
  return (
    <>
      <label
        htmlFor={id}
        className="block text-sm font-medium text-gray-700 mb-1">
        {label} {required ? <span className="text-primary-500">*</span> : ""}
      </label>
      <div className="">
        <select
          name={id}
          id={id}
          value={_.get(values, id, "")}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={disabled}
          required={required}
          //   placeholder={placeholder ?? ""}
          className={classNames(
            _.get(values, id, "") === "" ? "font-light text-xs" : "text-sm",
            _.get(errors, id) && _.get(touched, id)
              ? "focus:ring-red-500 focus:border-red-500 border-red-600"
              : "focus:ring-primary focus:border-primary border-gray-300",
            disabled ? "cursor-not-allowed bg-gray-100" : "",
            "shadow-sm block w-full rounded-md bg-white appearance-none px-2 placeholder:font-light placeholder:text-xs h-[2.75rem] outline-none  border"
          )}>
          {options?.map((option, idx) => (
            <option key={idx} value={(option as Option)?.value ?? option}>
              {(option as Option)?.label ?? option}
            </option>
          ))}
        </select>
      </div>
      {_.get(errors, id) && _.get(touched, id) ? (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {_.get(errors, id)}
        </p>
      ) : null}
    </>
  );
};

export default SelectInput;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: false,
};

export const logoutModalSlice = createSlice({
  name: "logoutModal",
  initialState,
  reducers: {
    showLogout: (state) => {
      state.value = true;
    },
    hideLogout: (state) => {
      state.value = false;
    },
  },
});

export const { showLogout, hideLogout } = logoutModalSlice.actions;

export default logoutModalSlice.reducer;

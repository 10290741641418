import { ApplicationStatus, HireCandidateMutation, HireCandidateMutationVariables } from "@/apollo/generated/types";
import { HIRE_CANDIDATE } from "@/apollo/graphql/mutations/candidate";
import { useMutation } from "@apollo/client";

import toast from "react-hot-toast";
import { Tooltip } from "react-tooltip";

type Props = { applicationId: string; jobId: string; status: ApplicationStatus | undefined | null };

const HireCandidateButton = ({ applicationId, jobId, status }: Props) => {
    const [hireCandidate, { loading }] = useMutation<HireCandidateMutation, HireCandidateMutationVariables>(HIRE_CANDIDATE, { refetchQueries: ["GetCandidates","GetCandidate"] });
    const handleAccept = async () => {
        await hireCandidate({ variables: { applicationId: applicationId, jobId: jobId } })
            .then(() => {
                toast(
                    JSON.stringify({
                        type: "success",
                        title: "Candidate hired successfully",
                    })
                );
            })
            .catch(() => {
                toast(
                    JSON.stringify({
                        type: "error",
                        title: "Something wrong happened",
                    })
                );
            });
    };
    return (
        <button
            disabled={status === ApplicationStatus.Hired}
            onClick={handleAccept}
            className={`text-xs px-4 py-2  hover:bg-gray-300 hover:text-black rounded-md font-semibold ${status === ApplicationStatus.Hired ? "bg-primary-500 text-white" : "border border-gray-700 text-gray-800"} `}
            data-tooltip-delay-show={100}
            data-tooltip-id="global-tooltip"
            data-tooltip-content="Hire Candidate">
            <Tooltip
                style={{
                    fontSize: "10px",
                    fontWeight: "600",
                    backgroundColor: "#101828",
                    color: "#fff",
                    borderRadius: "20px",
                    marginTop: "10px",
                    height: "10px",
                }}
                anchorSelect={`#hire-candidate-${applicationId}`}
                content={`Hire Candidate`}
                className="z-[3]"
            />
            {status === ApplicationStatus.Hired ? <span> Hired</span> : <span>Hire</span>}
        </button>
    );
};

export default HireCandidateButton;

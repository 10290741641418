import { classNames } from "@/utils";
import { FC } from "react";

type Props = { title: string; active: "Details" | "Description" | "Preview"; setActive: (value: "Details" | "Description" | "Preview") => void };

const TabButton: FC<Props> = ({ title, active, setActive }) => {
    return (
        <button
            onClick={() => {}}
            className={classNames(active === title ? "border-b-2 border-primary-500 text-primary-500" : " ", "h-full flex items-end justify-start w-32 pl-2 font-bold flex-shrink-0 pb-2")}>
            {title}
        </button>
    );
};

export default TabButton;

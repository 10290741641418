// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDxpDj0-1T7rOKZ_UzhlDNq2xLFuIX4cu4",
  authDomain: "clients-adjuma.firebaseapp.com",
  projectId: "clients-adjuma",
  storageBucket: "clients-adjuma.appspot.com",
  messagingSenderId: "472657981582",
  appId: "1:472657981582:web:b2cca3a7d414b96817d9df",
  measurementId: "G-6V3QFJ5X0V",
  vapidKey: "BIP9qZ2bXj2t1fV2j6J9XV8D7xh7wv5y3LZ3Y1Hr1hKQKzH0f8I9Z9X5V0X2Z0d0f",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export { app, auth, GoogleAuthProvider, signInWithPopup };

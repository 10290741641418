/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useMutation } from "@apollo/client";

import toast from "react-hot-toast";

import { useFormik } from "formik";
import Modal from "../../components/modal";
import { UPDATE_COMPANY, UPDATE_COMPANY_CONTACT } from "@/apollo/graphql/mutations/company";
import { Company, UpdateCompanyContactInformationMutation, UpdateCompanyContactInformationMutationVariables } from "@/apollo/generated/types";
import _ from "lodash";
import { setMe } from "@/apollo/cache/auth";

const CompanyContactModal = ({ visible, onClose, data, refetch }: { visible: boolean; onClose: () => void; data: Company | undefined; refetch: () => void }) => {
    const [updateCompany, { loading }] = useMutation<UpdateCompanyContactInformationMutation, UpdateCompanyContactInformationMutationVariables>(UPDATE_COMPANY_CONTACT, {
        refetchQueries: ["GetEmployer"],
    });

    const form = useFormik({
        initialValues: {
            email: "",
            phoneNumber: "",
            website: "",
        },
        onSubmit: async (values) => {
            await updateCompany({
                variables: {
                    input: {
                        email: values.email,
                        id: data?.id || "",
                        phone: values.phoneNumber,
                        website: values.website,
                    },
                },
            })
                .then(({ data }) => {
                    if (data?.updateCompanyContactInformation) {
                        refetch();
                        form.resetForm();
                        onClose();

                        toast(
                            JSON.stringify({
                                type: "success",
                                title: `Company Contact Information Updated Successfully `,
                            })
                        );
                    }
                })
                .catch((error) => {
                    toast(
                        JSON.stringify({
                            type: "error",
                            title: error?.message || "An error occurred while trying to save job",
                        })
                    );
                });
        },
    });

    useEffect(() => {
        form.setValues({
            email: data?.email || "",
            phoneNumber: data?.phone || "",
            website: data?.website || "",
        });
    }, [data]);

    // const validatePhoneNumber = (phoneNumber: string) => {
    //     const phoneNumberPattern = /^\d{12}$/;
    //     return phoneNumberPattern.test(phoneNumber);
    // };

    if (!visible) return null;

    return (
        <Modal
            open={visible}
            setOpen={() => {
                onClose();
            }}
            hideActions={false}
            description="Company Contact"
            renderActions={() => (
                <>
                    <button
                        disabled={loading}
                        onClick={() => {
                            form.handleSubmit();
                        }}
                        className="w-[8rem] h-[2.5rem] flex justify-center items-center font-montserrat font-normal
                 text-sm capitalize leading-5 text-center
                  bg-primary rounded-lg text-white
                  border border-primary "
                        type="button">
                        {loading ? "Saving..." : " Save changes"}
                    </button>
                </>
            )}>
            {!_.isEmpty(data) ? (
                <>
                    <div className="mt-3">
                        <div>
                            <div className="">
                                <label className="font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.1px]" htmlFor="email">
                                    Company Email
                                </label>

                                <input id="email" value={form.values.email} type="email" placeholder="Enter your company's email" className="input-base mt-2" disabled />
                            </div>

                            <div className="">
                                <label className="font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.1px]" htmlFor="phoneNumber">
                                    phone number
                                </label>

                                <PhoneInput
                                    country={"gh"}
                                    placeholder={"Mobile Number"}
                                    value={form.values.phoneNumber}
                                    onChange={(value: any) => form.setFieldValue("phoneNumber", value)}
                                    countryCodeEditable={false}
                                    enableSearch={true}
                                    inputProps={{
                                        required: true,
                                        title: "Enter your phone number here",
                                        className: "input-base mt-2 mb-0",
                                    }}
                                    inputStyle={{
                                        width: "80%",
                                        marginLeft: "20%",
                                    }}
                                    buttonStyle={{
                                        width: "18%",
                                        borderRadius: "0.5rem",
                                        borderColor: "rgb(156 163 175)",
                                        paddingInline: "0.5rem",
                                        marginTop: "0.5rem",
                                    }}
                                    dropdownStyle={{
                                        borderRadius: "0.5rem",
                                        borderColor: "rgb(156 163 175)",
                                        paddingInline: "1rem",
                                    }}
                                    searchStyle={{
                                        width: "90%",
                                        borderRadius: "0.5rem",
                                        borderColor: "rgb(156 163 175)",
                                        paddingInline: "1rem",
                                    }}
                                />
                            </div>

                            <div className="mt-3">
                                <label className="font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.1px]" htmlFor="website">
                                    Website
                                </label>

                                <input id="website" {...form.getFieldProps("website")} type="text" placeholder="Enter your company's website" className="input-base mt-2" />
                            </div>
                            {/* <div className="">
                                <label className="font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.1px]" htmlFor="igname">
                                    Instagram Username
                                </label>

                                <input id="igname" {...form.getFieldProps("igname")} type="text" placeholder="Enter company's instagram" className="input-base mt-2" />
                            </div> */}
                        </div>
                    </div>
                </>
            ) : (
                <div className="flex-1 w-full max-h-[65vh] overflow-y-auto  sm:p-6">
                    <div>
                        <h1>NO DATA</h1>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default CompanyContactModal;

import { gql } from "@apollo/client";

export const GET_SETTINGS = gql`
    query Settings($userId: ID!, $userType: UserType!) {
        settings(userId: $userId, userType: $userType) {
            id
            userId
            userType
            updatesEmail
            updatesSms
            jobsEmail
            jobsSms
            remindersEmail
            remindersSms
            activitiesEmail
            activitiesSms
            createdAt
            updatedAt
        }
    }
`;

export const GET_ROLES = gql`
    query GetEmployerRoles($filter: RoleFilterForEmployers) {
        getEmployerRoles(filter: $filter) {
            id
            code
            name
            description
            permissions
        }
    }
`;
export const GET_ROLE = gql`
    query GetEmployerRole($filter: RoleFilterForEmployers) {
        getEmployerRole(filter: $filter) {
            id
            code
            name
            description
            permissions
        }
    }
`;

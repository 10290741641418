import { GetEmployerRolesQuery, GetEmployerRolesQueryVariables } from "@/apollo/generated/types";
import { GET_ROLES } from "@/apollo/graphql/queries/setting";
import { useQuery, QueryHookOptions } from "@apollo/client";

export const useRoles = (options?: QueryHookOptions<GetEmployerRolesQuery, GetEmployerRolesQueryVariables>) => {
    const { data, ...rest } = useQuery<GetEmployerRolesQuery, GetEmployerRolesQueryVariables>(GET_ROLES, {
        ...options,
    });

    return {
        roles: data?.getEmployerRoles,
        ...rest,
    };
};

import { CgTrash } from "react-icons/cg";
import { IoCheckboxOutline } from "react-icons/io5";
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import { SlOptionsVertical } from "react-icons/sl";
import { Fragment } from "react";
import { classNames } from "@/utils";
import logo from "@/assets/images/logo.png";
import { useAppNavigate } from "@/apollo/cache/auth";
import { useMutation } from "@apollo/client";
import { DeleteNotificationMutation, DeleteNotificationMutationVariables, ReadNotificationMutation, ReadNotificationMutationVariables } from "@/apollo/generated/types";
import { DELETE_NOTIFICATION, READ_NOTIFICATION } from "@/apollo/graphql/mutations/notification";
import toast from "react-hot-toast";

type Props = { read: boolean; title: string; id: string; createdAt: string };

const NotificationItem = ({ read, title, id, createdAt }: Props) => {
    const navigate = useAppNavigate();
    const [readNotification] = useMutation<ReadNotificationMutation, ReadNotificationMutationVariables>(READ_NOTIFICATION, { refetchQueries: ["GetNotifications"] });
    const [deleteNotification] = useMutation<DeleteNotificationMutation, DeleteNotificationMutationVariables>(DELETE_NOTIFICATION, { refetchQueries: ["GetNotifications"] });

    const handleRead = async () => {
        await readNotification({ variables: { readNotificationId: id } });
    };

    const handleDelete = async () => {
        await deleteNotification({ variables: { deleteNotificationId: id } })
            .then(() => {
                toast(
                    JSON.stringify({
                        type: "success",
                        title: "Notification deleted successfully",
                    })
                );
            })
            .catch(() => {
                toast(
                    JSON.stringify({
                        type: "error",
                        title: "Notification deletion unsuccessfully",
                    })
                );
            });
    };

    return (
        <div className={`flex justify-between items-center gap-x-5 w-full border-b border-gray-200 p-3 ${read ? "bg-white" : "bg-[#EBFFEB]"}`}>
            <button
                onClick={() => {
                    navigate({
                        to: "notification-detail",
                        search(prev) {
                            return { ...prev, id: id };
                        },
                    });
                    handleRead();
                }}
                className="flex mobile:items-start items-center gap-x-4">
                <div className="flex justify-center items-center w-10 h-10 rounded-full bg-gray-50 border border-gray-200 p-1">
                    <img src={logo} alt="notification logo" className="object-contain" />
                </div>
                <div className="flex-1 flex flex-col justify-start items-start">
                    <p className=" font-medium text-xs">{title}</p>
                    <p className=" text-gray-400 text-[10px]">{createdAt}</p>
                </div>
            </button>

            <div className="">
                <Menu as="div" className="relative">
                    <MenuButton className="-m-1.5 flex items-center p-1.5">
                        <span className="sr-only">Open user menu</span>
                        <SlOptionsVertical className="text-gray-700" aria-hidden="true" />
                    </MenuButton>
                    <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                        <MenuItems className="absolute right-0 z-10 mt-2.5 w-52 origin-top-right rounded-md bg-white py-2 shadow-xl ring-1 ring-gray-900/5 focus:outline-none">
                            <MenuItem>
                                {({ active }) => (
                                    <button
                                        onClick={() => {
                                            handleRead();
                                        }}
                                        className={classNames(active ? "bg-gray-50" : "", "block  py-1 text-sm leading-6 text-gray-900 w-full  border-b")}>
                                        <span className="flex items-center gap-x-2 border-[#F0F0F0] py-2 px-3">
                                            <IoCheckboxOutline className="w-5 h-5 text-[#6B6B6B] " />
                                            <span className="text-[#6B6B6B] text-sm">Mark as read</span>
                                        </span>
                                    </button>
                                )}
                            </MenuItem>
                            <MenuItem>
                                {({ active }) => (
                                    <button
                                        onClick={() => {
                                            handleDelete();
                                        }}
                                        className={classNames(active ? "bg-gray-50" : "", "block py-1 text-sm leading-6 text-gray-900 w-full")}>
                                        <span className="flex text-red-500 cursor-pointer items-center space-x-1 py-1 px-3">
                                            <CgTrash className="w-5 h-5" />
                                            <span className="text-sm">Delete</span>
                                        </span>
                                    </button>
                                )}
                            </MenuItem>
                        </MenuItems>
                    </Transition>
                </Menu>
            </div>
        </div>
    );
};

export default NotificationItem;

//auth
export const LOGIN = "/login";
export const SIGNUP = "/signup";

// for main pages
export const HOME = "/";
export const MAIN_LAYOUT = "/";
export const PROFILE = "/profile";
export const JOBS = "/jobs";
export const CANDIDATES = "/candidates";
export const CREATE_JOB = "/jobs/create";
export const NOTIFICATIONS = "/notifications";
export const NOTIFICATION_DETAIL = "/notification-details";
export const SETTINGS = "/settings";

export const DEV_LANDING_URL = "https://test.adjuma.io";
export const TEST_LANDING_URL = "https://test.adjuma.io";
export const PROD_LANDING_URL = "https://adjuma.io";

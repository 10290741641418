import { gql } from "@apollo/client";

export const UPDATE_SETTINGS = gql`
    mutation UpdateSettings($input: UpdateNotificationSettingsInput) {
        updateSettings(input: $input)
    }
`;

export const CREATE_ROLE = gql`
    mutation CreateEmployerRole($name: String!, $code: String!, $companyId: ID!, $description: String!, $permissions: [String!]!) {
        createEmployerRole(name: $name, code: $code, companyId: $companyId, description: $description, permissions: $permissions) {
            id
            code
            name
            description
            permissions
        }
    }
`;

export const UPDATE_ROLE = gql`
    mutation UpdateEmployerRole($updateEmployerRoleId: ID!, $name: String!, $code: String!, $description: String!, $permissions: [String!]!) {
        updateEmployerRole(id: $updateEmployerRoleId, name: $name, code: $code, description: $description, permissions: $permissions)
    }
`;

export const DELETE_ROLE = gql`
    mutation DeleEmployerRole($deleEmployerRoleId: ID!) {
        deleEmployerRole(id: $deleEmployerRoleId)
    }
`;

export const ADD_EMPLOYER_TO_COMPANY = gql`
    mutation AddEmployerToCompany($input: AddEmployerInput) {
        addEmployerToCompany(input: $input)
    }
`;

export const UPDATE_EMPLOYER_ROLE = gql`
    mutation ChangeEmployersRole($userId: ID!, $roleId: ID!) {
        changeEmployersRole(userId: $userId, roleId: $roleId)
    }
`;

export const DELETE_EMPLOYER_USER = gql`
    mutation DeleteEmployerFromCompany($userId: ID!, $companyId: ID!) {
        deleteEmployerFromCompany(userId: $userId, companyId: $companyId)
    }
`;
export const SEND_EMAIL_TO_SUPPORT = gql`
    mutation SendEmailToSupport($name: String!, $email: String!, $phoneNumber: String!, $message: String!) {
        sendEmailToSupport(name: $name, email: $email, phoneNumber: $phoneNumber, message: $message) {
            message
        }
    }
`;

export const REQUEST_ENTERPRISE_PACKAGE = gql`
    mutation MakeEnterPriseRequest($companyEmail: String!, $phone: String!, $numOfActiveJobs: Int!, $message: String) {
        makeEnterPriseRequest(companyEmail: $companyEmail, phone: $phone, numOfActiveJobs: $numOfActiveJobs, message: $message) {
            message
        }
    }
`;

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import { useMutation } from "@apollo/client";

import toast from "react-hot-toast";
import { useFormik } from "formik";
import Modal from "../../components/modal";
import { UPDATE_COMPANY } from "@/apollo/graphql/mutations/company";
import { Company, UpdateCompanyInformationMutation, UpdateCompanyInformationMutationVariables } from "@/apollo/generated/types";
import { SelectInput } from "@/components";

interface Option {
    value: string;
    label: string;
}
const IndustryOptions: Option[] = [
    { value: "", label: "------- Please Select Industry --------" },
    { value: "Agriculture, Food, and Natural Resources", label: "Agriculture, Food, and Natural Resources" },
    { value: "Architecture and Construction", label: "Architecture and Construction" },
    { value: "Arts, Audio/Visual Technology, and Communications", label: "Arts, Audio/Visual Technology, and Communications" },
    { value: "Business Management and Administration", label: "Business Management and Administration" },
    { value: "Education and Training", label: "Education and Training" },
    { value: "Finance", label: "Finance" },
    { value: "Government and Public Administration", label: "Government and Public Administration" },
    { value: "Health Science", label: "Health Science" },
    { value: "Hospitality and Tourism", label: "Hospitality and Tourism" },
    { value: "Human Services", label: "Human Services" },
    { value: "Information Technology", label: "Information Technology" },
    { value: "Law, Public Safety, Corrections, and Security", label: "Law, Public Safety, Corrections, and Security" },
    { value: "Manufacturing", label: "Manufacturing" },
    { value: "Marketing, Sales, and Service", label: "Marketing, Sales, and Service" },
    { value: "Science, Technology, Engineering, and Mathematics (STEM)", label: "Science, Technology, Engineering, and Mathematics (STEM)" },
    { value: "Transportation, Distribution, and Logistics", label: "Transportation, Distribution, and Logistics" },
    { value: "Energy", label: "Energy" },
    { value: "Telecommunications", label: "Telecommunications" },
    { value: "Real Estate", label: "Real Estate" },
    { value: "Nonprofit and Social Services", label: "Nonprofit and Social Services" },
    { value: "Other", label: "Other" },
];

const CompanyTypeOptions: Option[] = [
    { value: "", label: "------- Please Select Company Type --------" },
    { value: "PRIVATE", label: "Private" },
    { value: "NGO", label: "NGO" },
    { value: "GOVERNMENT", label: "Government" },
];

const CompanyInfoModal = ({ visible, onClose, data, refetch }: { visible: boolean; onClose: () => void; data?: Company; refetch: () => void }) => {
    const [updateCompany, { loading }] = useMutation<UpdateCompanyInformationMutation, UpdateCompanyInformationMutationVariables>(UPDATE_COMPANY, { refetchQueries: ["GetEmployer"] });

    const form = useFormik({
        initialValues: {
            companyName: "",
            yearOfEstablishment: "",
            industry: "",
            address: "",
            numberOfEmployees: 0,
            companyType: "",
            about: "",
        },
        onSubmit: async (values) => {
            await updateCompany({
                variables: {
                    input: {
                        address: values.address,
                        companyType: values.companyType || "",
                        id: data?.id || "",
                        industry: values.industry || "",
                        name: values.companyName,
                        numOfEmployees: values.numberOfEmployees,
                        yearOfEstablishment: values.yearOfEstablishment,
                        about: values.about,
                    },
                },
            })
                .then(({ data }) => {
                    if (data?.updateCompanyInformation) {
                        refetch();
                        form.resetForm();
                        onClose();
                        toast(
                            JSON.stringify({
                                type: "success",
                                title: `Company information updated successfully`,
                            })
                        );
                    }
                })
                .catch((error) => {
                    toast(
                        JSON.stringify({
                            type: "error",
                            title: error?.message || "An error occurred while trying to update company",
                        })
                    );
                });
        },
    });

    useEffect(() => {
        form.setValues({
            companyName: data?.name || "",
            address: data?.address || "",
            industry: data?.industry || "",
            numberOfEmployees: Number(data?.numOfEmployees) || 0,
            yearOfEstablishment: data?.yearOfEstablishment || "",
            companyType: data?.companyType || "",
            about: data?.about || "",
        });
    }, [data, data?.id]);

    if (!visible) return null;

    return (
        <Modal
            open={visible}
            setOpen={() => {
                onClose();
            }}
            hideActions={false}
            description="Company Information"
            renderActions={() => (
                <>
                    <button
                        disabled={loading}
                        onClick={() => {
                            form.handleSubmit();
                        }}
                        className="w-[8rem] h-[2.5rem] flex justify-center items-center font-montserrat font-normal
                 text-sm capitalize leading-5 text-center
                  bg-primary rounded-lg text-white
                  border border-primary "
                        type="button">
                        {loading ? "Saving..." : " Save changes"}
                    </button>
                </>
            )}>
            {data?.id ? (
                <>
                    <div className="mt-3">
                        <div>
                            <div className="">
                                <label className="font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.1px]" htmlFor="companyName">
                                    Company Name
                                </label>

                                <input id="companyName" {...form.getFieldProps("companyName")} type="text" placeholder="Enter your company's name" className="input-base " />
                            </div>
                            <div className="">
                                <label className="font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.1px]" htmlFor="companyName">
                                    About Company
                                </label>

                                <textarea id="about" {...form.getFieldProps("about")} placeholder="Tell us about your company" className="input-base " />
                            </div>
                            <div className="">
                                <label className="font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.1px]" htmlFor="yoe">
                                    Year of Establishment
                                </label>
                                <input id="yearOfEstablishment" {...form.getFieldProps("yearOfEstablishment")} type="text" placeholder="Enter Year of Establishment" className="input-base" />
                            </div>
                            <div className="mb-2">
                                <SelectInput label="Company Type" id="companyType" options={CompanyTypeOptions} {...form} />
                            </div>
                            <div className="mb-2">
                                <SelectInput label="Industry" id="industry" options={IndustryOptions} {...form} />
                            </div>
                            <div className="">
                                <label className="font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.1px]" htmlFor="address">
                                    Address
                                </label>

                                <input id="address" {...form.getFieldProps("address")} type="text" placeholder="Enter your position in company" className="input-base" />
                            </div>
                            <div className="">
                                <label className="font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.1px]" htmlFor="noe">
                                    Number of Employees
                                </label>

                                <input id="numberOfEmployees" type="number" {...form.getFieldProps("numberOfEmployees")} placeholder="Enter your position in company" className="input-base" />
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="flex-1 w-full max-h-[65vh] overflow-y-auto  sm:p-6">
                    <div>
                        <h1>NO DATA</h1>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default CompanyInfoModal;

import { gql } from "@apollo/client";

export const POST_JOB_MUTATION = gql`
    mutation CreateJob($input: CreateJob!) {
        createJob(input: $input)
    }
`;

export const UPDATE_JOB_MUTATION = gql`
    mutation UpdateJob($input: UpdateJob!) {
        updateJob(input: $input)
    }
`;

export const DEACTIVATE_JOB = gql`
    mutation DeactivateJob($jobId: ID!, $companyId: ID!) {
        deactivateJob(jobId: $jobId, companyId: $companyId)
    }
`;

export const DELETE_DRAFT_JOB = gql`
    mutation DeleteDraftedJob($jobId: ID!, $companyId: ID!) {
        deleteDraftedJob(jobId: $jobId, companyId: $companyId)
    }
`;

export const REACTIVATE_JOB = gql`
    mutation ReactivateJob($jobId: ID!, $companyId: ID!) {
        reactivateJob(jobId: $jobId, companyId: $companyId)
    }
`;
export const DELETE_JOB = gql`
    mutation DeleteJob($jobId: ID!, $companyId: ID!) {
        deleteJob(jobId: $jobId, companyId: $companyId)
    }
`;

export const CLOSE_JOB = gql`
    mutation CloseJob($jobId: ID!, $companyId: ID!) {
        closeJob(jobId: $jobId, companyId: $companyId)
    }
`;

export const REPOST_JOB = gql`
    mutation RepostJob($jobId: ID!, $closeDate: Date!, $openDate: Date!) {
        repostJob(jobId: $jobId, closeDate: $closeDate, openDate: $openDate)
    }
`;

export const DRAFT_JOB = gql`
    mutation DraftJob($input: CreateDraftJob) {
        draftJob(input: $input)
    }
`;
export const UPDATE_DRAFTED_JOB = gql`
    mutation UpdateDraft($updateDraftId: ID!, $input: CreateDraftJob!) {
        updateDraft(id: $updateDraftId, input: $input)
    }
`;

export const DELETE_COMMENT = gql`
    mutation DeleteComment($commentId: ID!) {
        deleteComment(commentId: $commentId)
    }
`;

export const COMMENT_JOB = gql`
    mutation CommentJob($jobId: ID!, $userId: ID!, $comment: String!) {
        commentJob(jobId: $jobId, userId: $userId, comment: $comment)
    }
`;

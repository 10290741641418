import { PhoneInput } from "react-international-phone";
import { TextInput } from "./core";
import { useFormik } from "formik";
import { Button } from "./buttons";
import { DevicePhoneMobileIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { useMutation } from "@apollo/client";
import { MakeEnterPriseRequestMutation, MakeEnterPriseRequestMutationVariables } from "@/apollo/generated/types";
import { SEND_EMAIL_TO_SUPPORT } from "@/apollo/graphql/mutations/auth";
import { useCurrentUser } from "@/apollo/cache/auth";
import { useEffect } from "react";
import ButtonLoader from "./loaders/button";
import toast from "react-hot-toast";
import { REQUEST_ENTERPRISE_PACKAGE } from "@/apollo/graphql/mutations/setting";

type Props = { setTab: () => void };

const EnterprisePage = ({ setTab,  }: Props) => {
    const [sendMail, { loading }] = useMutation<MakeEnterPriseRequestMutation, MakeEnterPriseRequestMutationVariables>(REQUEST_ENTERPRISE_PACKAGE);
    const employer = useCurrentUser();

    const form = useFormik({
        initialValues: {
            email: "",
            phone: "",
            numberOfOpenings: 0,
            message: "",
        },
        onSubmit: async (values) => {
            await sendMail({
                variables: {
                    companyEmail: values.email,
                    phone: values.phone,
                    message: values.message,
                    numOfActiveJobs: values.numberOfOpenings,
                },
            })
                .then(() => {
                    toast(
                        JSON.stringify({
                            title: "Mail sent succesfully",
                            type: "success",
                        })
                    );
                    form.resetForm();
                    setTab();
                })
                .catch(() => {
                    toast(
                        JSON.stringify({
                            title: "Something wrong happened",
                            type: "error",
                        })
                    );
                });
        },
    });

    useEffect(() => {
        form.setValues({
            email: employer?.company?.email || "",
            phone: "",
            message: "",
            numberOfOpenings: 0,
        });
    }, []);
    return (
        <div className="w-full h-full py-5">
            <div className=" mt-5">
                <h1 className="text-base font-semibold ">Call for Custom Quote (Enterprise Request)</h1>
                <h3 className="text-xs text-gray-500 mt-1">Fill in the fields to submit your custom quote for review</h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 my-10">
                <div>
                    <TextInput id={"email"} label="Company Email" required {...form} />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="phoneNumber">
                        Phone Number <span className="text-primary-500">*</span>
                    </label>
                    <PhoneInput
                        placeholder="Enter your phone number"
                        defaultCountry={"gh"}
                        className="w-full rounded h-12"
                        countrySelectorStyleProps={{}}
                        inputClassName="w-full focus:ring-primary-500 rounded text-xs h-12"
                        value={form.values.phone}
                        onChange={(phone) => form.setFieldValue("phone", phone)}
                    />
                </div>
                <div>
                    <TextInput id={"numberOfOpenings"} label="Number of job openings" type="number" required {...form} />
                </div>
                <div className="col-span-full">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Message <span className="font-light text-gray-500">(Optional)</span>
                    </label>
                    <textarea className=" w-full h-20 border rounded-lg outline-primary border-gray-300 text-sm text-gray-500 p-2" placeholder="Enter message" value={form.values.message} onChange={(e) => form.setFieldValue("message", e.target.value)}></textarea>
                </div>
            </div>
            <div className="w-full flex md:justify-between justify-center items-center flex-col-reverse md:flex-row  gap-5">
                <div className="flex flex-col sm:flex-row justify-start items-center gap-x-5 gap-y-3">
                    <a href="mailto:support@adjuma.io" className="flex items-center justify-start gap-x-2">
                        <EnvelopeIcon className="h-7 w-7 text-primary-500" />
                        <div className="">
                            <h1 className="text-xs text-gray-500 whitespace-nowrap">ADJUMA SALES EMAIL</h1>
                            <h3 className="text-xs text-gray-700 font-medium whitespace-nowrap">support@adjuma.io</h3>
                        </div>
                    </a>
                    <a href="tel:+233545748999" className="flex items-center justify-start gap-x-2">
                        <DevicePhoneMobileIcon className="h-7 w-7 text-primary-500" />
                        <div className="">
                            <h1 className="text-xs text-gray-500 whitespace-nowrap">ADJUMA SALES EMAIL</h1>
                            <h3 className="text-xs text-gray-700 font-medium whitespace-nowrap">+233 54 574 8999</h3>
                        </div>
                    </a>
                </div>
                <div className=" flex justify-between items-center gap-2">
                    <div>
                        <Button onClick={() => setTab()} variant="minimal">Cancel</Button>
                    </div>
                    <div className="">
                        <Button onClick={() => form.handleSubmit()}>{loading ? <ButtonLoader title="Sending ..." /> : <span className="text-sm">Submit</span>}</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EnterprisePage;

import Wrapper from "@/components/wrapper";
import { FC } from "react";
import SummaryItem from "./components/SummaryItem";
import { CheckCircleIcon, FireIcon, MinusCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { useJobStats } from "@/hooks";
import JobTable from "./components/JobTable";
import { useCurrentUser } from "@/apollo/cache/auth";
import { JobStatus } from "@/apollo/generated/types";
import { useUrlState } from "@/utils";
// import UncompleteBanner from "@/components/uncompleted-banner";

type Props = {};

const JobsPage: FC<Props> = () => {
    const employer = useCurrentUser()

    const [activeFilter, setActiveFilter] = useUrlState("status");

    const { stats, loading: loadingStats } = useJobStats({ variables: { companyId: employer?.companyId || "" } });
    return (
        <Wrapper>
            <div className="relative w-full overflow-hidden p-5">
                {/* <div className="mb-5 sticky top-0">
                    <UncompleteBanner />
                </div> */}
                <div className="w-full lg:px-10 px-5 overflow-scroll overflow-x-scroll flex justify-between space-x-5 no-scrollbar">
                    <SummaryItem active={activeFilter === ""} bg="bg-green-50" color="text-green-500" loading={loadingStats} title={"All Jobs"} value={stats?.totalJobs || 0} Icon={CheckCircleIcon} onClick={() => setActiveFilter("")} />
                    <SummaryItem active={activeFilter === JobStatus.Active} color="text-primary-500" bg={"bg-primary-50"} loading={loadingStats} title={"Active Jobs"} value={stats?.activeJobs || 0} Icon={FireIcon} onClick={() => setActiveFilter(JobStatus.Active)} />
                    <SummaryItem active={activeFilter === "Drafted"} color="text-orange-400" bg={"bg-orange-50"} loading={loadingStats} title={"Drafted"} value={stats?.draftedJobs || 0} Icon={MinusCircleIcon} onClick={() => setActiveFilter("Drafted")} />
                    <SummaryItem active={activeFilter === JobStatus.Closed} color="text-red-500" bg={"bg-red-50"} loading={loadingStats} title={"Closed"} value={stats?.closedJobs || 0} Icon={XCircleIcon} onClick={() => setActiveFilter(JobStatus.Closed)} />
                </div>
                <div className="w-full mt-16">
                    <JobTable />
                </div>
            </div>
        </Wrapper>
    );
};

export default JobsPage;

import { GetNotificationQuery, GetNotificationQueryVariables } from "@/apollo/generated/types";
import { GET_NOTIFICATION } from "@/apollo/graphql/queries/notification";
import { useQuery, QueryHookOptions } from "@apollo/client";

export const useNotification = (options?: QueryHookOptions<GetNotificationQuery, GetNotificationQueryVariables>) => {
    const { data, ...rest } = useQuery<GetNotificationQuery, GetNotificationQueryVariables>(GET_NOTIFICATION, {
        ...options,
    });

    return {
        notification: data?.getNotification,
        ...rest,
    };
};

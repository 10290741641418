import "./css/datepicker.css";
import { ChangeEvent, Fragment, useState } from "react";
import DateRangePicker, { DateRange } from "rsuite/DateRangePicker";
import { Tooltip } from "react-tooltip";
import { useDraftJobs, useJobs, usePagination } from "@/hooks";
import moment from "moment";
import { classNames, useUrlState } from "@/utils";
import { AdjustmentsHorizontalIcon, EllipsisVerticalIcon, EyeIcon, MagnifyingGlassIcon, PencilSquareIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Button, Modal, Paginator, Show } from "@/components";
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import JobDrawer from "./JobDrawer";
import _ from "lodash";
import { currentUserPermissionVar, useAppNavigate, useCurrentUser } from "@/apollo/cache/auth";
import illustration from "@/assets/images/illustration.jpg";
import { Link } from "react-location";
import DeleteJobModal from "./DeleteJobModal";
import EditJobDrawer from "./EditJobDrawer";
import CloseJobModal from "./CloseJobModal";
import { JobStatus } from "@/apollo/generated/types";
import RepostDrawer from "./RepostDrawer";
import DeleteDraftedJobModal from "./DeleteDraftedJobModal";
import { hasPermission, PERMISSIONS } from "@/permissions";
import logo from "@/assets/images/company-logo-sec.png";

const filter = [
    { id: "All", value: "" },
    {
        id: "Active",
        value: JobStatus.Active,
    },
    {
        id: "Draft",
        value: "Drafted",
    },
    {
        id: "Closed",
        value: JobStatus.Closed,
    },
];

const JobTable = () => {
    const employer = useCurrentUser();
    const currentUserPermissions = currentUserPermissionVar();
    const [activeFilter, setActiveFilter] = useUrlState("status");
    const [search, setSearch] = useUrlState("search");
    const [drawer] = useUrlState("drawer");
    const [modal] = useUrlState("modal");
    const [currentJob] = useUrlState("id");
    const [draftedJob] = useUrlState("draft_id");
    const [dateFilter, setDateFilter] = useState<DateRange | null>(null);
    const { offset, limit, setPage } = usePagination();

    const navigate = useAppNavigate();

    const {
        jobs,
        loading: loadingJobs,
        refetch,
    } = useJobs({
        variables: {
            filter: {
                title: !_.isEmpty(search) ? { regex: search } : undefined,
                companyId: !_.isEmpty(employer?.companyId) ? { eq: employer?.companyId } : undefined,
                status: activeFilter === JobStatus.Active || activeFilter === JobStatus.Closed ? { eq: activeFilter } : undefined,
                createdAt: !_.isEmpty(dateFilter) ? { between: dateFilter } : undefined,
            },
            pagination: {
                limit,
                offset,
            },
            sort: {},
        },
    });

    const {
        drafted_jobs,
        loading: loadingDrafted,
        refetch: refetchDrafted,
    } = useDraftJobs({
        variables: {
            filter: { title: !_.isEmpty(search) ? { eq: search } : undefined, companyId: !_.isEmpty(employer?.company?.id) ? { eq: employer?.company?.id } : undefined, createdAt: !_.isEmpty(dateFilter) ? { between: dateFilter } : undefined },
            pagination: { limit, offset },
            sort: {},
        },
    });

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    return (
        <>
            <div className="flex justify-between  my-6 items-center space-x-2">
                <div className="ring-1 ring-gray-300  px-3 w-96 h-9 flex items-center gap-2 rounded-md shadow">
                    <MagnifyingGlassIcon className="w-3 h-3 text-gray-500" aria-hidden="true" />
                    <input type="text" placeholder="Search for job title ..." className="w-full h-full outline-none text-sm " value={search} onChange={handleSearchChange} />
                </div>
                <div className="flex items-center gap-3">
                    <div className="">
                        <Menu as="div" className="relative">
                            <MenuButton className="">
                                <span className="sr-only">Open user menu</span>
                                <span className="border border-gray-300 h-9  text-xs px-3 rounded-lg flex justify-center items-center space-x-2">
                                    <AdjustmentsHorizontalIcon className="h-4 w-4" /> <span>Filter</span>
                                </span>
                            </MenuButton>
                            <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                                <MenuItems className="absolute right-0 z-10 w-36 origin-top-right rounded-md bg-white py-2 shadow-xl ring-1 ring-gray-900/5 focus:outline-none">
                                    {filter?.map((item, index) => (
                                        <MenuItem key={index}>
                                            {() => (
                                                <div className={classNames(activeFilter === item.value ? "bg-gray-50" : "", "block  text-xs  text-gray-900")}>
                                                    <div
                                                        onClick={() => {
                                                            setActiveFilter(item.value);
                                                        }}
                                                        className="flex justify-start items-center space-x-2 cursor-pointer gap-x-2 border-b border-[#F0F0F0] py-2 px-3">
                                                        <input type="checkbox" className="accent-primary-500" value={item.value} checked={activeFilter === item.value} />
                                                        <label className="">{item.id}</label>
                                                    </div>
                                                </div>
                                            )}
                                        </MenuItem>
                                    ))}
                                </MenuItems>
                            </Transition>
                        </Menu>
                    </div>
                    <div className="flex md:hidden">
                        <DateRangePicker
                            placement="bottomEnd"
                            showOneCalendar
                            format="MM/dd/yyyy"
                            value={dateFilter}
                            onChange={setDateFilter}
                            onOk={() => {
                                refetchDrafted();
                                refetch();
                            }}
                        />
                    </div>
                    <div className="hidden md:block">
                        <DateRangePicker
                            placement="bottomEnd"
                            format="MM/dd/yyyy"
                            value={dateFilter}
                            onChange={setDateFilter}
                            onOk={() => {
                                refetchDrafted();
                                refetch();
                            }}
                        />
                    </div>
                </div>
            </div>
            {activeFilter === "Drafted" && hasPermission(currentUserPermissions, PERMISSIONS.JOB_DRAFT) ? (
                <div className="w-full h-full rounded-md ">
                    {_.isEmpty(drafted_jobs) && !loadingDrafted ? (
                        <div className="w-full h-96 flex justify-center items-center col-span-full row-span-2 border">
                            <div className="space-y-5">
                                <div className="flex flex-col justify-center items-center space-y-1">
                                    <img src={illustration} alt="empty-illustration" className="h-24 w-24" />
                                    <h6 className="text-lg font-semibold leading-5 mt-2">No Jobs Found</h6>
                                    <p className="text-xs text-gray-500 ">You have no {activeFilter === "Drafted" ? "DRAFTED" : activeFilter || ""} jobs available</p>
                                </div>
                                <Link to="/post-job" className="px-2 py-2 space-x-2 flex items-center justify-center bg-primary-500 text-sm text-white font-semibold rounded-md">
                                    <PlusIcon className="h-4 w-4 " />
                                    <span>Post New Job</span>
                                </Link>
                            </div>
                        </div>
                    ) : (
                        <div className="w-full overflow-scroll overflow-x-scroll flex">
                            <div className="border border-black/5 mt-5 rounded-md  min-w-full flex-shrink-0">
                                <table className="font-sans min-w-full  divide-y divide-gray-200 ">
                                    <thead className=" ">
                                        <tr className="flex justify-between items-center h-12">
                                            <th scope="col" className="w-60 flex-shrink-0 text-start px-5 leading-5 text-sm font-medium text-black">
                                                Job Title
                                            </th>
                                            <th scope="col" className="w-20 flex-shrink-0 text-start px-2 leading-5 text-sm font-medium text-black">
                                                Job Type
                                            </th>
                                            <th scope="col" className="w-40 flex-shrink-0 text-start px-2 leading-5 text-sm font-medium text-black">
                                                Open Date
                                            </th>
                                            <th scope="col" className="w-40 flex-shrink-0 text-start px-2 leading-5 text-sm font-medium text-black">
                                                Close Date
                                            </th>
                                            <th scope="col" className="w-40  flex-shrink-0 text-start px-2 leading-5 text-sm font-medium text-black">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    {loadingDrafted ? (
                                        <tbody>
                                            <tr className="w-full flex items-center justify-between">
                                                <td className=" w-60 whitespace-nowrap px-6 py-4 text-sm text-gray-300 border-b border-gray-200 ">
                                                    <div className="bg-gray-300  h-3 rounded-md animate-pulse" />
                                                </td>
                                                <td className=" w-20 whitespace-nowrap px-6 py-4 text-sm text-gray-300 border-b border-gray-200 ">
                                                    <div className="bg-gray-300  h-3 rounded-md animate-pulse" />
                                                </td>
                                                <td className=" w-40 whitespace-nowrap px-6 py-4 text-sm text-gray-300 border-b border-gray-200 ">
                                                    <div className="bg-gray-300  h-3 rounded-md animate-pulse" />
                                                </td>
                                                <td className=" w-40 whitespace-nowrap px-6 py-4 text-sm text-gray-300 border-b border-gray-200 ">
                                                    <div className="bg-gray-300  h-3 rounded-md animate-pulse" />
                                                </td>
                                                <td className=" w-40 whitespace-nowrap px-6 py-4 text-sm text-gray-300 border-b border-gray-200 ">
                                                    <div className="bg-gray-300  h-3 rounded-md animate-pulse" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : (
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {drafted_jobs?.map((job, index) => (
                                                <tr className={`flex items-center justify-between h-12`} key={job?.title}>
                                                    <td className="w-60 flex-shrink-0 text-start text-sm">
                                                        <p className="w-full truncate px-2 text-gray-800 cursor-pointer hover:text-blue-500">{job?.title}</p>
                                                    </td>
                                                    <td className="w-20 flex-shrink-0 text-start px-2 text-sm">
                                                        <p className="w-full truncate px-2 cursor-pointer">{job?.mode}</p>
                                                    </td>
                                                    <td className="w-40 flex-shrink-0 text-start  text-sm ">
                                                        <p className="w-full truncate px-2 text-gray-800">{moment(job?.openDate).format("L")}</p>
                                                    </td>
                                                    <td className="w-40 flex-shrink-0 text-start  text-sm ">
                                                        <p className="w-full truncate px-2 text-gray-800">
                                                            <span className={classNames("py-1 rounded-full  text-xs px-2 text-center")}>{moment(job?.closeDate).format("L")}</span>
                                                        </p>
                                                    </td>

                                                    <td className="w-40 flex-shrink-0  px-2 text-sm  flex items-center  justify-start space-x-3">
                                                        <Show if={hasPermission(currentUserPermissions, PERMISSIONS.JOB_DRAFT_POST)}>
                                                            <button
                                                                className="w-16 h-7  cursor-pointer bg-gray-800 text-white text-xs leading-4  font-semibold rounded-md"
                                                                onClick={() =>
                                                                    navigate({
                                                                        to: "/post-job",
                                                                        search() {
                                                                            return {
                                                                                draft_id: job?.id || undefined,
                                                                            };
                                                                        },
                                                                    })
                                                                }>
                                                                Continue
                                                            </button>
                                                        </Show>
                                                        <Show if={hasPermission(currentUserPermissions, PERMISSIONS.JOB_DRAFT_DELETE)}>
                                                            <button
                                                                className="w-16 h-7  cursor-pointer bg-red-800 text-white text-xs leading-4  font-semibold rounded-md"
                                                                onClick={() =>
                                                                    navigate({
                                                                        search(prev) {
                                                                            return { ...prev, draft_id: job?.id || "", modal: "deleteDraft" };
                                                                        },
                                                                    })
                                                                }>
                                                                Delete
                                                            </button>
                                                        </Show>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    )}
                                </table>
                                <Paginator offset={offset} limit={limit} totalItems={jobs?.count || 0} currentSize={jobs?.rows?.length || 0} setOffset={setPage} />
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className="w-full h-full rounded-md ">
                    {_.isEmpty(jobs?.rows) && !loadingJobs ? (
                        <div className="w-full h-96 flex justify-center items-center col-span-full row-span-2 border">
                            <div className="space-y-5">
                                <div className="flex flex-col justify-center items-center space-y-1">
                                    <img src={illustration} alt="empty-illustration" className="h-24 w-24" />
                                    <h6 className="text-lg font-semibold leading-5 mt-2">No Jobs Found</h6>
                                    <p className="text-xs text-gray-500 ">You have no {activeFilter === "Drafted" ? "DRAFTED" : activeFilter || ""} jobs available</p>
                                </div>
                                <Link to="/post-job" className="px-2 py-2 space-x-2 flex items-center justify-center bg-primary-500 text-sm text-white font-semibold rounded-md">
                                    <PlusIcon className="h-4 w-4 " />
                                    <span>Post New Job</span>
                                </Link>
                            </div>
                        </div>
                    ) : (
                        <div className="w-full overflow-scroll overflow-x-scroll flex ">
                            <div className="border border-black/5 mt-5 rounded-md  min-w-full flex-shrink-0">
                                <table className="font-sans min-w-full  divide-y divide-gray-200 ">
                                    <thead className=" ">
                                        <tr className="flex justify-between items-center h-12">
                                            <th scope="col" className="w-60 flex-shrink-0 text-start px-5 leading-5 text-sm font-medium text-black">
                                                Job Title
                                            </th>
                                            <th scope="col" className="w-20 flex-shrink-0 text-start px-2 leading-5 text-sm font-medium text-black">
                                                Applicants
                                            </th>
                                            <th scope="col" className="w-40 flex-shrink-0 text-start px-2 leading-5 text-sm font-medium text-black">
                                                Close Date
                                            </th>
                                            <th scope="col" className="w-24 flex-shrink-0 text-start px-2 leading-5 text-sm font-medium text-black">
                                                Status
                                            </th>
                                            <Show
                                                if={
                                                    hasPermission(currentUserPermissions, PERMISSIONS.JOB_REPOST) ||
                                                    hasPermission(currentUserPermissions, PERMISSIONS.JOB_CLOSE) ||
                                                    hasPermission(currentUserPermissions, PERMISSIONS.JOB_DETAILS) ||
                                                    hasPermission(currentUserPermissions, PERMISSIONS.JOB_DELETE) ||
                                                    hasPermission(currentUserPermissions, PERMISSIONS.JOB_UPDATE)
                                                }>
                                                <th scope="col" className="w-48  flex-shrink-0 text-start px-2 leading-5 text-sm font-medium text-black">
                                                    Actions
                                                </th>
                                            </Show>
                                        </tr>
                                    </thead>
                                    {loadingJobs ? (
                                        <tbody>
                                            <tr className="w-full flex items-center justify-between space-x-2">
                                                <td className=" w-60 whitespace-nowrap px-6 py-4 text-sm text-gray-300 border-b border-gray-200 ">
                                                    <div className="bg-gray-300  h-3 rounded-md animate-pulse" />
                                                </td>
                                                <td className=" w-20 whitespace-nowrap px-6 py-4 text-sm text-gray-300 border-b border-gray-200 ">
                                                    <div className="bg-gray-300  h-3 rounded-md animate-pulse" />
                                                </td>
                                                <td className=" w-40 whitespace-nowrap px-6 py-4 text-sm text-gray-300 border-b border-gray-200 ">
                                                    <div className="bg-gray-300  h-3 rounded-md animate-pulse" />
                                                </td>
                                                <td className=" w-20 whitespace-nowrap px-6 py-4 text-sm text-gray-300 border-b border-gray-200 ">
                                                    <div className="bg-gray-300  h-3 rounded-md animate-pulse" />
                                                </td>
                                                <Show
                                                    if={
                                                        hasPermission(currentUserPermissions, PERMISSIONS.JOB_REPOST) ||
                                                        hasPermission(currentUserPermissions, PERMISSIONS.JOB_CLOSE) ||
                                                        hasPermission(currentUserPermissions, PERMISSIONS.JOB_DETAILS) ||
                                                        hasPermission(currentUserPermissions, PERMISSIONS.JOB_DELETE) ||
                                                        hasPermission(currentUserPermissions, PERMISSIONS.JOB_UPDATE)
                                                    }>
                                                    <td className=" w-52 whitespace-nowrap px-6 py-4 text-sm text-gray-300 border-b border-gray-200 ">
                                                        <div className="bg-gray-300  h-3 rounded-md animate-pulse" />
                                                    </td>
                                                </Show>
                                            </tr>
                                        </tbody>
                                    ) : (
                                        <tbody className="divide-y divide-gray-200 bg-white ">
                                            {jobs?.rows?.map((job, index) => (
                                                <tr className={`flex items-center justify-between h-12 space-x-2`} key={job?.id}>
                                                    <td className="w-60 flex-shrink-0 text-start px-5 text-sm">
                                                        <p
                                                            className="w-full truncate max-w-[90%] text-gray-800 cursor-pointer hover:text-blue-500"
                                                            onClick={() => {
                                                                navigate({
                                                                    to: "/candidates",
                                                                    search(prev) {
                                                                        return { ...prev, filter: job?.id || "" };
                                                                    },
                                                                });
                                                            }}>
                                                            {job?.title}
                                                        </p>
                                                    </td>
                                                    <td className="w-20 flex-shrink-0 text-start px-2 text-sm">
                                                        <p
                                                            className="w-full truncate max-w-[90%]  pl-5 text-blue-500 font-semibold cursor-pointer"
                                                            onClick={() => {
                                                                navigate({
                                                                    to: "/candidates",
                                                                    search(prev) {
                                                                        return { ...prev, filter: job?.id || "" };
                                                                    },
                                                                });
                                                            }}>
                                                            {job?.applicationCount}
                                                        </p>
                                                    </td>
                                                    <td className="w-40 flex-shrink-0 text-start px-2 text-sm ">
                                                        <p className="w-full truncate max-w-[90%] text-gray-800">{moment(job?.closeDate).format("L")}</p>
                                                    </td>
                                                    <td className="w-24 flex-shrink-0 text-start px-2 text-sm ">
                                                        <p className="w-full truncate max-w-[90%] text-gray-800">
                                                            <span
                                                                className={classNames(
                                                                    job?.status === JobStatus.Active
                                                                        ? "bg-primary-50 text-primary-500"
                                                                        : job?.status === ("Drafted" as JobStatus)
                                                                        ? "bg-orange-50 text-orange-500"
                                                                        : job?.status === JobStatus.Closed
                                                                        ? "bg-red-50 text-red-500"
                                                                        : "bg-gray-50 text-gray-500",
                                                                    " rounded-full  text-[10px] px-2 py-1 text-center "
                                                                )}>
                                                                {job?.status}
                                                            </span>
                                                        </p>
                                                    </td>

                                                    <Show
                                                        if={
                                                            hasPermission(currentUserPermissions, PERMISSIONS.JOB_REPOST) ||
                                                            hasPermission(currentUserPermissions, PERMISSIONS.JOB_CLOSE) ||
                                                            hasPermission(currentUserPermissions, PERMISSIONS.JOB_DETAILS) ||
                                                            hasPermission(currentUserPermissions, PERMISSIONS.JOB_DELETE) ||
                                                            hasPermission(currentUserPermissions, PERMISSIONS.JOB_UPDATE)
                                                        }>
                                                        <td className="w-48 flex-shrink-0  px-2 text-sm  flex items-center  justify-start space-x-5">
                                                            {job?.status === JobStatus.Closed ? (
                                                                <Show if={hasPermission(currentUserPermissions, PERMISSIONS.JOB_REPOST)}>
                                                                    <button
                                                                        className="w-14 h-7 cursor-pointer bg-slate-500 text-white text-[11px] leading-4 font-semibold rounded"
                                                                        onClick={() => {
                                                                            navigate({
                                                                                search(prev) {
                                                                                    return { ...prev, drawer: "repostJob", id: job?.id || "" };
                                                                                },
                                                                            });
                                                                        }}>
                                                                        Repost
                                                                    </button>
                                                                </Show>
                                                            ) : job?.status === JobStatus.Active ? (
                                                                <Show if={hasPermission(currentUserPermissions, PERMISSIONS.JOB_CLOSE)}>
                                                                    <button
                                                                        className="w-14 h-7 cursor-pointer bg-primary-500 text-white text-[11px] leading-4 font-semibold rounded"
                                                                        onClick={() =>
                                                                            navigate({
                                                                                search(prev) {
                                                                                    return { ...prev, modal: "closeJob", id: job?.id || "" };
                                                                                },
                                                                            })
                                                                        }>
                                                                        Close
                                                                    </button>
                                                                </Show>
                                                            ) : (
                                                                <Show if={hasPermission(currentUserPermissions, PERMISSIONS.JOB_REPOST)}>
                                                                    <button
                                                                        className="w-14 h-7  cursor-pointer bg-slate-500 text-white text-[11px] leading-4  font-semibold rounded"
                                                                        onClick={() => {
                                                                            navigate({
                                                                                search(prev) {
                                                                                    return { ...prev, drawer: "repostJob", id: job?.id || "" };
                                                                                },
                                                                            });
                                                                        }}>
                                                                        Repost
                                                                    </button>
                                                                </Show>
                                                            )}
                                                            <Show if={hasPermission(currentUserPermissions, PERMISSIONS.JOB_DETAILS)}>
                                                                <button
                                                                    onClick={() => {
                                                                        navigate({
                                                                            search(prev) {
                                                                                return { ...prev, id: job?.id || "", drawer: "viewJob" };
                                                                            },
                                                                        });
                                                                    }}
                                                                    className=" "
                                                                    id={`edit-anchor-${index}`}>
                                                                    <EyeIcon className="text-gray-500 w-6 h-6" />
                                                                    <span className="sr-only">View {job?.title}</span>
                                                                    <Tooltip
                                                                        style={{
                                                                            fontSize: "12px",
                                                                            fontWeight: "100",
                                                                            backgroundColor: "#101828",
                                                                            color: "#fff",
                                                                            borderRadius: "8px",
                                                                            marginTop: "10px",
                                                                        }}
                                                                        anchorSelect={`#edit-anchor-${index}`}
                                                                        content={`View`}
                                                                        className="z-[3]"
                                                                    />
                                                                </button>
                                                            </Show>
                                                            <Show if={hasPermission(currentUserPermissions, PERMISSIONS.JOB_DELETE) || hasPermission(currentUserPermissions, PERMISSIONS.JOB_UPDATE)}>
                                                                <span className="" id={`more-anchor-${index}`}>
                                                                    <Menu as="div" className="relative inline-block text-left">
                                                                        <div>
                                                                            <MenuButton className="inline-flex w-full justify-center  rounded-md bg-white  text-sm font-semibold text-gray-900  ">
                                                                                <EllipsisVerticalIcon className="text-[#475467] w-6 h-6" />
                                                                            </MenuButton>
                                                                        </div>

                                                                        <Transition
                                                                            enter="transition ease-out duration-100"
                                                                            enterFrom="transform opacity-0 scale-95"
                                                                            enterTo="transform opacity-100 scale-100"
                                                                            leave="transition ease-in duration-75"
                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                            leaveTo="transform opacity-0 scale-95">
                                                                            <MenuItems className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                <div className="py-1">
                                                                                    {job?.status !== JobStatus.Closed && hasPermission(currentUserPermissions, PERMISSIONS.JOB_UPDATE) && (
                                                                                        <MenuItem>
                                                                                            {({ focus }) => (
                                                                                                <button
                                                                                                    onClick={() =>
                                                                                                        navigate({
                                                                                                            search(prev) {
                                                                                                                return { ...prev, drawer: "editJob", id: job?.id || "" };
                                                                                                            },
                                                                                                        })
                                                                                                    }
                                                                                                    className={classNames(focus ? "bg-gray-100 text-gray-900" : "text-gray-700", " w-full min-w-32 flex justify-start items-center space-x-2 pl-2  py-2 text-xs")}>
                                                                                                    <PencilSquareIcon className="h-4 w-4" /> <span>Edit Post</span>
                                                                                                </button>
                                                                                            )}
                                                                                        </MenuItem>
                                                                                    )}
                                                                                    {hasPermission(currentUserPermissions, PERMISSIONS.JOB_DELETE) && (
                                                                                        <MenuItem>
                                                                                            {({ focus }) => (
                                                                                                <button
                                                                                                    onClick={() =>
                                                                                                        navigate({
                                                                                                            search(prev) {
                                                                                                                return { ...prev, modal: "deleteJob", id: job?.id || "" };
                                                                                                            },
                                                                                                        })
                                                                                                    }
                                                                                                    className={classNames(focus ? "bg-gray-100 text-gray-900" : "text-gray-700", " w-full text-start min-w-32 flex justify-start items-center space-x-2 pl-2 py-2 text-xs")}>
                                                                                                    <TrashIcon className="h-4 w-4" /> <span>Delete</span>
                                                                                                </button>
                                                                                            )}
                                                                                        </MenuItem>
                                                                                    )}
                                                                                </div>
                                                                            </MenuItems>
                                                                        </Transition>
                                                                    </Menu>
                                                                </span>
                                                            </Show>
                                                        </td>
                                                    </Show>
                                                </tr>
                                            ))}
                                        </tbody>
                                    )}
                                </table>
                                <Paginator offset={offset} limit={limit} totalItems={jobs?.count || 0} currentSize={jobs?.rows?.length || 0} setOffset={setPage} />
                            </div>
                        </div>
                    )}
                </div>
            )}

            {!_.isEmpty(currentJob) && drawer === "viewJob" && (
                <JobDrawer
                    open={drawer === "viewJob"}
                    setOpen={() => {
                        navigate({
                            search(prev) {
                                return {
                                    ...prev,
                                    drawer: undefined,
                                    id: undefined,
                                };
                            },
                        });
                    }}
                />
            )}

            {!_.isEmpty(currentJob) && drawer === "repostJob" && hasPermission(currentUserPermissions, PERMISSIONS.JOB_REPOST) && (
                <RepostDrawer
                    open={drawer === "repostJob"}
                    setOpen={() => {
                        navigate({
                            search(prev) {
                                return {
                                    ...prev,
                                    drawer: undefined,
                                    id: undefined,
                                };
                            },
                        });
                    }}
                />
            )}
            {!_.isEmpty(currentJob) && modal === "deleteJob" && hasPermission(currentUserPermissions, PERMISSIONS.JOB_DELETE) && (
                <DeleteJobModal
                    open={modal === "deleteJob"}
                    setOpen={() => {
                        navigate({
                            search(prev) {
                                return { ...prev, modal: undefined, id: undefined };
                            },
                        });
                    }}
                />
            )}
            {!_.isEmpty(currentJob) && modal === "closeJob" && hasPermission(currentUserPermissions, PERMISSIONS.JOB_CLOSE) && (
                <CloseJobModal
                    open={modal === "closeJob"}
                    setOpen={() => {
                        navigate({
                            search(prev) {
                                return { ...prev, modal: undefined, id: undefined };
                            },
                        });
                    }}
                />
            )}
            {!_.isEmpty(draftedJob) && modal === "deleteDraft" && hasPermission(currentUserPermissions, PERMISSIONS.JOB_DRAFT_DELETE) && (
                <DeleteDraftedJobModal
                    open={modal === "deleteDraft"}
                    setOpen={() => {
                        navigate({
                            search(prev) {
                                return { ...prev, modal: undefined, draft_id: undefined };
                            },
                        });
                    }}
                />
            )}
            {!_.isEmpty(currentJob) && drawer === "editJob" && hasPermission(currentUserPermissions, PERMISSIONS.JOB_UPDATE) && (
                <EditJobDrawer
                    open={drawer === "editJob"}
                    setOpen={() => {
                        navigate({
                            search(prev) {
                                return { ...prev, drawer: undefined, id: undefined };
                            },
                        });
                    }}
                />
            )}

            {modal === "repost_success" && (
                <Modal
                    title={""}
                    hideActions={true}
                    size="md"
                    open={modal === "repost_success"}
                    setOpen={() => {
                        navigate({
                            to: "/",
                            search(prev) {
                                return { ...prev, modal: undefined };
                            },
                        });
                    }}>
                    <div className="w-full h-full  flex flex-col justify-center items-center space-y-3 py-10">
                        <img src={logo} className="h-14 w-14 rounded-full bg-cover object-cover" />
                        <h1 className="text-lg font-medium">Congratulations &#127881;</h1>
                        <p className="text-sm text-light text-center">
                            You have successfully reposted <span className="font-semibold">{}</span> job. Now, let's assist you to finding the right talent.
                        </p>

                        <div className="pt-5">
                            <Button
                                size="sm"
                                onClick={() => {
                                    navigate({
                                        to: "/",
                                        search(prev) {
                                            return { ...prev, modal: undefined };
                                        },
                                    });
                                }}>
                                Continue
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default JobTable;

import { gql } from "@apollo/client";

export const GET_EMPLOYER = gql`
    query GetEmployer {
        getEmployer {
            companyId
            company {
                id
                name
                about
                email
                phone
                address
                businessType
                companyType
                about
                industry
                website
                numOfEmployees
                yearOfEstablishment
                coverPhoto
                companyLogo
                companyDocument
                subscription {
                    package {
                        id
                        name
                        description
                        price
                        interval
                        jobsQuota
                        privileges
                        permissions
                        planCode
                        planId
                        createdAt
                        updatedAt
                        deletedAt
                    }
                    status
                    expiryDate
                }
                meta {
                    suspendedAt
                    suspendedBy
                    rejectionReason
                    suspensionReason
                    companyRequestStatus
                    status
                    isVerified
                }
                socials {
                    id
                    name
                    link
                }
                createdAt
                updatedAt
            }
            id
            fullName
            email
            phone
            profilePicture
            position
            role {
                id
                code
                name
                description
                permissions
            }
            loginMeta {
                lastLoginAt
                lastLoginIp
                lastLoginLocation
            }
            meta {
                authType
                onBoardingStatus
                isPhoneNumberVerified
                isEmailVerified
                acceptedTermsAndConditions
            }
            createdAt
            updatedAt
        }
    }
`;

export const GET_EMPLOYERS = gql`
    query GetEmployers($pagination: Pagination, $filter: EmployerFilter) {
        getEmployers(pagination: $pagination, filter: $filter) {
            companyId
            company {
                id
                name
                about
                email
                phone
                address
                businessType
                companyType
                about
                industry
                website
                numOfEmployees
                yearOfEstablishment
                coverPhoto
                companyLogo
                companyDocument
                meta {
                    suspendedAt
                    suspendedBy
                    rejectionReason
                    suspensionReason
                    companyRequestStatus
                    status
                    isVerified
                }
                socials {
                    id
                    name
                    link
                }
                createdAt
                updatedAt
            }
            id
            fullName
            email
            phone
            profilePicture
            position
            role {
                id
                code
                name
                description
                permissions
            }
            loginMeta {
                lastLoginAt
                lastLoginIp
                lastLoginLocation
            }
            meta {
                authType
                onBoardingStatus
                isPhoneNumberVerified
                isEmailVerified
                acceptedTermsAndConditions
            }
            createdAt
            updatedAt
        }
        getEmployersLength
    }
`;

export const GET_EMPLOYER_FILTER = gql`
    query GetEmployerByFilter($filter: EmployerFilter) {
        getEmployerByFilter(filter: $filter) {
            companyId
            company {
                id
                name
                email
                phone
                address
                businessType
                companyType
                about
                industry
                website
                numOfEmployees
                yearOfEstablishment
                coverPhoto
                companyLogo
                companyDocument
                subscription {
                    package {
                        id
                        name
                        description
                        price
                        interval
                        jobsQuota
                        privileges
                        planCode
                        planId
                        createdAt
                        updatedAt
                        deletedAt
                    }
                    status
                    expiryDate
                }
                meta {
                    suspendedAt
                    suspendedBy
                    rejectionReason
                    suspensionReason
                    companyRequestStatus
                    status
                    isVerified
                }
                socials {
                    id
                    name
                    link
                }
                createdAt
                updatedAt
            }
            id
            fullName
            email
            phone
            profilePicture
            position
            role {
                id
                code
                name
                description
                permissions
            }
            loginMeta {
                lastLoginAt
                lastLoginIp
                lastLoginLocation
            }
            meta {
                authType
                onBoardingStatus
                isPhoneNumberVerified
                isEmailVerified
                acceptedTermsAndConditions
            }
            createdAt
            updatedAt
        }
    }
`;

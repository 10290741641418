import { setMe } from "@/apollo/cache/auth";
import { GetEmployerByFilterQuery, GetEmployerByFilterQueryVariables, GetEmployerQuery, GetEmployerQueryVariables } from "@/apollo/generated/types";
import { GET_EMPLOYER, GET_EMPLOYER_FILTER } from "@/apollo/graphql/queries/employer";
import { useQuery, QueryHookOptions } from "@apollo/client";

export const useEmployer = (options?: QueryHookOptions<GetEmployerQuery, GetEmployerQueryVariables>) => {
    const { data, ...rest } = useQuery<GetEmployerQuery, GetEmployerQueryVariables>(GET_EMPLOYER, {
        ...options,
    });

    return {
        employer: data?.getEmployer,
        ...rest,
    };
};

export const useUpdateEmployer = () => {
    const { data, ...rest } = useQuery<GetEmployerQuery, GetEmployerQueryVariables>(GET_EMPLOYER);
    if (data?.getEmployer) {
        setMe(data?.getEmployer);
    }
    return {
        ...rest,
    };
};

export const useEmployerByFilter = (options?: QueryHookOptions<GetEmployerByFilterQuery, GetEmployerByFilterQueryVariables>) => {
    const { data, ...rest } = useQuery<GetEmployerByFilterQuery, GetEmployerByFilterQueryVariables>(GET_EMPLOYER_FILTER, {
        ...options,
    });

    return {
        employer: data?.getEmployerByFilter,
        ...rest,
    };
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

type CompanyType = {
    business_type: string;
    company_name: string;
    company_type: string;
    company_industry: string;
    company_address: string;
    company_phone: string;
    company_email: string;
};

type RepresentativeType = {
    fullname: string;
    email: string;
    position: string;
    phone: string;
    password: string;
    confirm_password: string;
    googleAuth: boolean;
};
const initialState = {
    fullname: "",
    email: "",
    position: "",
    phone: "",
    password: "",
    confirm_password: "",
    googleAuth: false,
    business_type: "",
    company_name: "",
    company_type: "",
    company_industry: "",
    company_address: "",
    company_phone: "",
    company_email: "",
    company_id: "",
};

export const OnboardingSlice = createSlice({
    name: "onboarding",
    initialState,
    reducers: {
        setRepresentative: (state, { payload }: PayloadAction<RepresentativeType>) => {
            state.fullname = payload.fullname;
            state.email = payload.email;
            state.position = payload.position;
            state.phone = payload.phone;
            state.password = payload.password;
            state.googleAuth = payload.googleAuth;
            state.confirm_password = payload.confirm_password;
        },
        clearRepresentative: (state) => {
            state.fullname = "";
            state.email = "";
            state.position = "";
            state.phone = "";
            state.password = "";
            state.googleAuth = false;
            state.confirm_password = "";
        },
        setCompanyId: (state, { payload }: PayloadAction<string>) => {
            state.company_id = payload;
        },
        setCompany: (state, { payload }: PayloadAction<CompanyType>) => {
            state.business_type = payload.business_type;
            state.company_name = payload.company_name;
            state.company_type = payload.company_type;
            state.company_industry = payload.company_industry;
            state.company_address = payload.company_address;
            state.company_phone = payload.company_phone;
            state.company_email = payload.company_email;
        },
        clearCompany: (state) => {
            state.business_type = "";
            state.company_name = "";
            state.company_type = "";
            state.company_industry = "";
            state.company_address = "";
            state.company_phone = "";
            state.company_email = "";
            state.company_id="";
        },
    },
});

export const { setCompany, setRepresentative, clearCompany, clearRepresentative, setCompanyId } =
    OnboardingSlice.actions;

export const selectOnboardingDetails = (state: RootState) => state.onboarding;

export default OnboardingSlice.reducer;

import { GetPackagesQuery, GetPackagesQueryVariables } from "@/apollo/generated/types";
import { GET_PACKAGES } from "@/apollo/graphql/queries/subscription";
import { useQuery, QueryHookOptions } from "@apollo/client";

export const usePackages = (options?: QueryHookOptions<GetPackagesQuery, GetPackagesQueryVariables>) => {
    const { data, ...rest } = useQuery<GetPackagesQuery, GetPackagesQueryVariables>(GET_PACKAGES, {
        ...options,
    });

    return {
        packages: data?.getPackages,
        ...rest,
    };
};

import { Button, Modal } from "@/components";
import { useFormik } from "formik";
import { ChangeEvent } from "react";
import { PhoneInput } from "react-international-phone";
import RoleSelector from "../RoleSelector";
import { useMutation } from "@apollo/client";
import { AddEmployerToCompanyMutation, AddEmployerToCompanyMutationVariables } from "@/apollo/generated/types";
import { ADD_EMPLOYER_TO_COMPANY } from "@/apollo/graphql/mutations/setting";
import { useAppNavigate, useCurrentUser } from "@/apollo/cache/auth";
import * as Yup from "yup";
import toast from "react-hot-toast";
import ButtonLoader from "@/components/loaders/button";

type Props = { open: boolean; setOpen: (value: boolean) => void };

const CreateUserModal = ({ open, setOpen }: Props) => {
    const employer = useCurrentUser();
    const navigate = useAppNavigate();
    const [addUser, { loading }] = useMutation<AddEmployerToCompanyMutation, AddEmployerToCompanyMutationVariables>(ADD_EMPLOYER_TO_COMPANY, { refetchQueries: ["GetEmployers"] });
    const form = useFormik({
        initialValues: {
            phone: "",
            role: "",
            name: "",
            email: "",
            position: "",
        },
        validationSchema: Yup.object().shape({
            phone: Yup.string().min(7, "Invalid phone number").required("Phone number is required"),
            name: Yup.string().required("Fullname is required"),
            role: Yup.string().required("Role is required"),
            position: Yup.string().required("Position is required"),
            email: Yup.string().required("Email is required"),
        }),
        onSubmit: async (values) => {
            await addUser({
                variables: {
                    input: {
                        companyId: employer?.companyId || "",
                        fullName: values.name,
                        email: values.email,
                        phone: values.phone,
                        position: values.position,
                        role: values.role,
                    },
                },
            }).then(() => {
                toast(
                    JSON.stringify({
                        type: "success",
                        title: "User added successfully",
                    })
                );
                form.resetForm();
                navigate({
                    search(prev) {
                        return { ...prev, modal: undefined };
                    },
                });
            });
        },
    });
    return (
        <Modal title={""} open={open} setOpen={setOpen} hideActions={true} size="2xl">
            <div className="w-full h-full relative">
                <h1 className="text-sm font-semibold -mt-2">Add New User</h1>
                <div className="w-full border-t mt-2 grid grid-col-1  md:grid-cols-2 gap-4 px-3 pt-3">
                    <div className="flex flex-col items-start justify-start space-y-1">
                        <label className="text-xs font-[400] text-black-2 capitalize leading-[0.9rem] tracking-[0.006px] mb-1" htmlFor="email">
                            Fullname
                        </label>
                        <input
                            id="name"
                            placeholder="Fullname"
                            name="name"
                            type="name"
                            value={form.values.name}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => form.setFieldValue("name", e.target.value)}
                            autoComplete="name"
                            className=" text-gray-900 text-xs border w-full h-10 px-2 rounded-md border-gray-500 outline-primary-500  ring-primary-500"
                        />
                        {form.errors.name && form.touched.name && (
                            <p className="text-xs mt-0.5 text-red-400">
                                <>{form.errors.name}</>
                            </p>
                        )}
                    </div>
                    <div className="flex flex-col items-start justify-start space-y-1">
                        <label className="text-xs font-[400] text-black-2 capitalize leading-[0.9rem] tracking-[0.006px] mb-1" htmlFor="email">
                            Email
                        </label>
                        <input
                            id="email"
                            placeholder="Email"
                            name="email"
                            type="email"
                            value={form.values.email}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => form.setFieldValue("email", e.target.value)}
                            autoComplete="email"
                            className=" text-gray-900 text-xs border w-full h-10 px-2 rounded-md border-gray-500 outline-primary-500  ring-primary-500"
                        />
                        {form.errors.email && form.touched.email && (
                            <p className="text-xs mt-0.5 text-red-400">
                                <>{form.errors.email}</>
                            </p>
                        )}
                    </div>
                    <div className="flex flex-col items-start justify-start space-y-1">
                        <label className="text-xs font-[400] text-black-2 capitalize leading-[0.9rem] tracking-[0.006px] mb-1" htmlFor="email">
                            Position in Company
                        </label>
                        <input
                            id="position"
                            placeholder="Position in Company"
                            name="position"
                            type="position"
                            value={form.values.position}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => form.setFieldValue("position", e.target.value)}
                            autoComplete="position"
                            className=" text-gray-900 text-xs border w-full h-10 px-2 rounded-md border-gray-500 outline-primary-500  ring-primary-500"
                        />
                        {form.errors.position && form.touched.position && (
                            <p className="text-xs mt-0.5 text-red-400">
                                <>{form.errors.position}</>
                            </p>
                        )}
                    </div>

                    <div className=" relative -mt-1  ">
                        <label className="text-xs font-[400] " htmlFor="phone">
                            Phone number
                        </label>
                        <div className=" ">
                            <PhoneInput
                                placeholder="Enter your phone number"
                                defaultCountry={"gh"}
                                className="w-full rounded mt-1"
                                countrySelectorStyleProps={{}}
                                inputClassName="w-full focus:ring-primary-500 rounded text-xs"
                                value={form.values.phone}
                                onChange={(phone) => form.setFieldValue("phone", phone)}
                            />
                        </div>
                        {form.errors.phone && form.touched.phone && (
                            <p className="text-xs mt-0.5 text-red-400">
                                <>{form.errors.phone}</>
                            </p>
                        )}
                    </div>
                    <div className=" relative -mt-1  ">
                        <label className="text-xs font-[400] " htmlFor="phone">
                            User Roles
                        </label>
                        <RoleSelector form={form} />
                    </div>
                    <div className="col-span-full mt-5 border-t flex justify-end items-center pt-3 space-x-3">
                        <div>
                            <Button
                                variant="minimal"
                                onClick={() => {
                                    form.resetForm();
                                    navigate({
                                        search(prev) {
                                            return { ...prev, modal: undefined };
                                        },
                                    });
                                }}>
                                <span className="text-xs font-semibold">Cancel</span>
                            </Button>
                        </div>
                        <div>
                            <Button onClick={() => form.handleSubmit()}>
                                <span className="text-xs font-semibold">{loading ? <ButtonLoader title="Adding User ..." /> : "Add User"}</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CreateUserModal;

import { classNames } from "@/utils";
import { forwardRef } from "react";

type Props = {
    active: string;
    title: string;
    setActive: () => void;
};

const TabButton = forwardRef(({ active, title, setActive }: Props, ref: any) => {
    return (
        <button onClick={() => setActive()} ref={ref} className={classNames(active === title ? "text-primary-500" : "text-gray-500", "flex flex-col justify-center items-center")}>
            <h6 className="text-xs">{title}</h6>
        </button>
    );
});

export default TabButton;

import { Paginator, Show } from "@/components";
import { useJobs, usePagination } from "@/hooks";
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import { AdjustmentsHorizontalIcon, MagnifyingGlassIcon, HandThumbDownIcon, HandThumbUpIcon, StarIcon, Bars3Icon, Squares2X2Icon } from "@heroicons/react/24/outline";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useAppNavigate, useCurrentUser, useCurrentUserPermissions } from "@/apollo/cache/auth";
import moment from "moment";
import _ from "lodash";
import { classNames, useUrlState, wrapClick } from "@/utils";
import CandidateGridLoader from "./CandidateGridLoader";
import GridComponent from "./GridComponent";
import illustration from "@/assets/images/illustration.jpg";
import { ApplicationStatus, CandidateQuery } from "@/apollo/generated/types";
import ViewCandidateDrawer from "../view";
import ViewCandidateButton from "./action-buttons/ViewCandidateButton";
import AcceptCandidateButton from "./action-buttons/AcceptCandidateButton";
import RejectCandidateButton from "./action-buttons/RejectCandidateButton";
import HireCandidateButton from "./action-buttons/HireCandidateButton";
import RateCandidateButton from "./action-buttons/RateCandidateButton";
import RateCandidateModal from "./RateCandidateModal";
import Avatar from "@/components/core/avatar";
import { hasPermission, PERMISSIONS } from "@/permissions";

type Props = { active: ApplicationStatus | undefined; candidates: CandidateQuery; loading: boolean; refetch: () => void };

const CandidateTable = ({ active, candidates, loading, refetch }: Props) => {
    const currentUserPermissions = useCurrentUserPermissions();
    const [modal] = useUrlState("modal");
    const [search, setSearch] = useUrlState("search");
    const [activeFilter, setActiveFilter] = useUrlState("filter");
    const [currentCandidateIndex, setCurrentCandidateIndex] = useState<number>(0);
    const [drawer, setDrawer] = useUrlState("drawer");
    const [viewMode, setViewMode] = useUrlState("viewMode");
    const [currentCandidate, setCurrentCandidate] = useUrlState("id");

    const { limit, offset, setPage } = usePagination();
    const employer = useCurrentUser();

    const navigate = useAppNavigate();

    const { jobs, loading: loadingJobs } = useJobs({
        variables: {
            filter: { companyId: employer?.companyId ? { eq: employer?.companyId } : undefined },
            pagination: { limit: 100, offset: 0 },
            sort: {},
        },
    });

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    useEffect(() => {
        refetch();
    }, [activeFilter]);

    return (
        <>
            <div className="flex justify-between  my-6 items-center space-x-2">
                <div className="ring-1 ring-gray-300  px-3 w-96 h-9 flex items-center gap-2 rounded-md shadow">
                    <MagnifyingGlassIcon className="w-3 h-3 text-gray-500" aria-hidden="true" />
                    <input type="text" placeholder="Search here ..." className="w-full h-full outline-none text-sm " value={search} onChange={handleSearchChange} />
                </div>
                <div className="flex items-center gap-3">
                    <div className="">
                        <Menu as="div" className="relative">
                            <MenuButton className="">
                                <span className="sr-only">Open user menu</span>
                                <button className="border border-gray-300 h-9  text-xs px-3 rounded-lg flex justify-center items-center space-x-2">
                                    <AdjustmentsHorizontalIcon className="h-4 w-4" />
                                </button>
                            </MenuButton>
                            <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                                <MenuItems className="absolute right-0 z-10 w-56 max-h-96 overflow-scroll overflow-y-scroll origin-top-right rounded-md bg-white py-2 shadow-xl ring-1 ring-gray-900/5 focus:outline-none">
                                    <div className={classNames(activeFilter === "" ? "bg-gray-50" : "", "block  text-xs  text-gray-900")}>
                                        <div
                                            onClick={() => {
                                                setActiveFilter("");
                                            }}
                                            className="flex justify-start items-center space-x-2 cursor-pointer gap-x-2 border-b border-[#F0F0F0] py-2 px-3">
                                            <input type="checkbox" className="accent-primary-500" value={""} checked={activeFilter === ""} />
                                            <label className="">All Jobs</label>
                                        </div>
                                    </div>
                                    {jobs?.rows?.map((item) => (
                                        <MenuItem>
                                            {() => (
                                                <div className={classNames(activeFilter === item?.id ? "bg-gray-50" : "", "block  text-xs  text-gray-900")}>
                                                    <div
                                                        onClick={() => {
                                                            setActiveFilter(item?.id || "");
                                                        }}
                                                        className="flex justify-start items-center space-x-2 cursor-pointer gap-x-2 border-b border-[#F0F0F0] py-2 px-3">
                                                        <input type="checkbox" className="accent-primary-500" value={item?.id || ""} checked={activeFilter === item?.id} />
                                                        <label className="">{item?.title}</label>
                                                    </div>
                                                </div>
                                            )}
                                        </MenuItem>
                                    ))}
                                </MenuItems>
                            </Transition>
                        </Menu>
                    </div>
                    <div className="bg-white flex items-center space-x-2">
                        <button onClick={() => setViewMode("card")} className={classNames(viewMode !== "table" ? "border-primary-500 border-2 text-primary-500" : "border-gray-300 border text-gray-500", " h-9  text-xs px-3 rounded-lg flex justify-center items-center ")}>
                            <Squares2X2Icon className=" h-4 w-4" />
                        </button>
                        <button onClick={() => setViewMode("table")} className={classNames(viewMode === "table" ? "border-primary-500 text-primary-500 border-2" : "border-gray-300 text-gray-500 border", "  h-9  text-xs px-3 rounded-lg flex justify-center items-center")}>
                            <Bars3Icon className="text-primary-500 h-4 w-4" />
                        </button>
                    </div>
                </div>
            </div>
            <Show if={viewMode === "table"}>
                {_.isEmpty(candidates?.rows) && !loading ? (
                    <div className="w-full h-96 flex justify-center items-center col-span-full row-span-2">
                        <div className="space-y-5">
                            <div className="flex flex-col justify-center items-center space-y-1">
                                <img src={illustration} alt="empty-illustration" className="h-24 w-24" />
                                <h6 className="text-lg font-semibold leading-5 mt-2">No Candidate Found</h6>
                                <p className="text-xs text-gray-500 ">
                                    You have no<span className="font-bold"> {active || ""} </span>candidates available
                                </p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="w-full flex overflow-scroll overflow-x-scroll">
                        <div className="border border-black/5 rounded-md  min-w-full flex-shrink-0">
                            <table className="font-sans  w-full divide-y divide-gray-200">
                                <thead className="w-full ">
                                    <tr className="w-full flex justify-between items-center">
                                        <th scope="col" className="w-60  text-start py-4 px-4 leading-5 text-sm font-medium text-black">
                                            Name
                                        </th>
                                        <th scope="col" className="w-44 text-start py-4 px-4 leading-5 text-sm font-medium text-black">
                                            Job Title
                                        </th>
                                        <th scope="col" className="w-44  text-start py-4 px-4 leading-5 text-sm font-medium text-black">
                                            Contact
                                        </th>
                                        <th scope="col" className="w-40  text-start py-4 px-4 leading-5 text-sm font-medium text-black">
                                            Date Applied
                                        </th>
                                        <th scope="col" className="w-60  text-start py-4 px-4 leading-5 text-sm font-medium text-black">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                {loading ? (
                                    <tr className="flex items-center justify-between">
                                        <td className="w-60  whitespace-nowrap px-6 py-4 text-sm text-gray-300 border-b border-gray-200 ">
                                            <div className="bg-gray-300  h-3 rounded-md animate-pulse" />
                                        </td>
                                        <td className="w-44  whitespace-nowrap px-6 py-4 text-sm text-gray-300 border-b border-gray-200 ">
                                            <div className="bg-gray-300  h-3 rounded-md animate-pulse" />
                                        </td>
                                        <td className="w-44  whitespace-nowrap px-6 py-4 text-sm text-gray-300 border-b border-gray-200 ">
                                            <div className="bg-gray-300  h-3 rounded-md animate-pulse" />
                                        </td>
                                        <td className="w-40  whitespace-nowrap px-6 py-4 text-sm text-gray-300 border-b border-gray-200 ">
                                            <div className="bg-gray-300  h-3 rounded-md animate-pulse" />
                                        </td>
                                        <td className="w-60  whitespace-nowrap px-6 py-4 text-sm text-gray-300 border-b border-gray-200 ">
                                            <div className="bg-gray-300  h-3 rounded-md animate-pulse" />
                                        </td>
                                    </tr>
                                ) : (
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {candidates?.rows?.map((candidate, index) => (
                                            <tr className={`flex items-center justify-between `} key={candidate?.id}>
                                                <td className="text-start whitespace h-14 flex items-center px-3 text-sm w-60">
                                                    <div
                                                        className="flex justify-start items-center space-x-1 cursor-pointer hover:underline"
                                                        onClick={() => {
                                                            navigate({
                                                                search(prev) {
                                                                    return { ...prev, id: candidate?.id || undefined, drawer: "viewCandidate" };
                                                                },
                                                            });
                                                            setCurrentCandidateIndex(candidates.rows?.indexOf(candidate) || 0);
                                                        }}>
                                                        <Avatar src={candidate?.talent?.avatar || ""} alt={candidate?.talent?.fullName || ""} size="xs" />
                                                        <div className=" w-full flex-1 flex  flex-col ">
                                                            <p className="w-full truncate  text-gray-800 font-medium text-sm">{_.startCase(candidate?.talent?.fullName || "N/A")}</p>
                                                            <p className="text-[11px] -mt-1">{candidate?.talent?.email}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-start whitespace-nowrap px-4 h-14 flex items-center text-sm w-44">
                                                    <p className="w-full truncate  text-gray-800 ">{candidate?.appliedJob?.title || "N/A"}</p>
                                                </td>
                                                <td className="text-start whitespace-nowrap px-4 h-14 flex items-center text-sm w-44">
                                                    <p className="w-full truncate  text-gray-800">{candidate?.talent?.phoneNumber || "N/A"}</p>
                                                </td>
                                                <td className="text-start whitespace-nowrap px-4 h-14 flex items-center text-sm w-40">
                                                    <p className="w-full truncate  text-gray-800">{moment(candidate?.appliedAt).format("L")}</p>
                                                </td>

                                                <td className="justify-start  space-x-5 text-start whitespace-nowrap px-4 h-14 flex items-center text-sm w-60">
                                                    <>
                                                        {active === ApplicationStatus.Accepted ? (
                                                            <div className="flex justify-start items-center space-x-3">
                                                                <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_DETAILS)}>
                                                                    <a href={candidate?.applicationDetails?.resume || ""} target="__blank" className="px-4 py-2  cursor-pointer bg-primary-500 text-white text-xs rounded-md">
                                                                        View CV
                                                                    </a>
                                                                </Show>
                                                                <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_HIRE)}>
                                                                    <HireCandidateButton applicationId={candidate?.id || ""} jobId={candidate?.appliedJob?.id || ""} status={candidate?.status} />
                                                                </Show>

                                                                <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_REJECT)}>
                                                                    <RejectCandidateButton applicationId={candidate?.id || ""} jobId={candidate?.appliedJob?.id || ""} status={candidate?.status} />
                                                                </Show>
                                                            </div>
                                                        ) : active === ApplicationStatus.Rejected ? (
                                                            <div className="flex justify-start items-center space-x-3">
                                                                <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_DETAILS)}>
                                                                    <a href={candidate?.applicationDetails?.resume || ""} target="__blank" className="px-4 py-2  cursor-pointer bg-primary-500 text-white text-xs rounded-md">
                                                                        View CV
                                                                    </a>
                                                                </Show>
                                                                <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_ACCEPT)}>
                                                                    <AcceptCandidateButton applicationId={candidate?.id || ""} jobId={candidate?.appliedJob?.id || ""} status={candidate?.status} />
                                                                </Show>
                                                            </div>
                                                        ) : active === ApplicationStatus.Pending ? (
                                                            <div className="flex justify-start items-center space-x-3">
                                                                <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_DETAILS)}>
                                                                    <a href={candidate?.applicationDetails?.resume || ""} target="__blank" className="px-4 py-2  cursor-pointer bg-primary-500 text-white text-xs rounded-md">
                                                                        View CV
                                                                    </a>
                                                                </Show>
                                                                <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_ACCEPT)}>
                                                                    <AcceptCandidateButton applicationId={candidate?.id || ""} jobId={candidate?.appliedJob?.id || ""} status={candidate?.status} />
                                                                </Show>
                                                                <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_REJECT)}>
                                                                    <RejectCandidateButton applicationId={candidate?.id || ""} jobId={candidate?.appliedJob?.id || ""} status={candidate?.status} />
                                                                </Show>
                                                            </div>
                                                        ) : active === ApplicationStatus.Hired ? (
                                                            <div className="flex justify-start items-center space-x-3">
                                                                <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_DETAILS)}>
                                                                    <a href={candidate?.applicationDetails?.resume || ""} target="__blank" className="px-4 py-2  cursor-pointer bg-primary-500 text-white text-xs rounded-md">
                                                                        View CV
                                                                    </a>
                                                                </Show>
                                                                <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_RATE)}>
                                                                    <RateCandidateButton candidateId={candidate?.id || ""} rate={candidate?.evaluation?.rating} />
                                                                </Show>
                                                            </div>
                                                        ) : (
                                                            <div className="flex justify-start items-center space-x-3">
                                                                <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_DETAILS)}>
                                                                    <a href={candidate?.applicationDetails?.resume || ""} target="__blank" className="px-4 py-2  cursor-pointer bg-primary-500 text-white text-xs rounded-md">
                                                                        View CV
                                                                    </a>
                                                                </Show>
                                                                <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_ACCEPT)}>
                                                                    <AcceptCandidateButton applicationId={candidate?.id || ""} jobId={candidate?.appliedJob?.id || ""} status={candidate?.status} />
                                                                </Show>
                                                                <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_REJECT)}>
                                                                    <RejectCandidateButton applicationId={candidate?.id || ""} jobId={candidate?.appliedJob?.id || ""} status={candidate?.status} />
                                                                </Show>
                                                            </div>
                                                        )}
                                                    </>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                            </table>
                            <Paginator offset={offset} limit={limit} totalItems={candidates?.count || 0} currentSize={candidates?.rows?.length || 0} setOffset={setPage} />
                        </div>
                    </div>
                )}
            </Show>
            <Show if={viewMode !== "table"}>
                <div className="w-full h-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-5">
                    {loading ? (
                        <>
                            <CandidateGridLoader />
                            <CandidateGridLoader />
                            <CandidateGridLoader />
                        </>
                    ) : _.isEmpty(candidates?.rows) ? (
                        <div className="w-full h-96 flex justify-center items-center col-span-full row-span-2">
                            <div className="space-y-5">
                                <div className="flex flex-col justify-center items-center space-y-1">
                                    <img src={illustration} alt="empty-illustration" className="h-24 w-24" />
                                    <h6 className="text-lg font-semibold leading-5 mt-2">No Candidate Found</h6>
                                    <p className="text-xs text-gray-500 ">
                                        You have no <span className="font-bold"> {active || ""} </span> candidates available
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {candidates?.rows?.map((candidate) => (
                                <GridComponent
                                    onView={() => {
                                        setCurrentCandidateIndex(candidates.rows?.indexOf(candidate) || 0);
                                    }}
                                    profileImg={candidate?.talent?.avatar || ""}
                                    name={candidate?.talent?.fullName || ""}
                                    email={candidate?.talent?.email || ""}
                                    rate={candidate?.evaluation?.rating}
                                    resumeLink={candidate?.applicationDetails?.resume || ""}
                                    id={candidate?.id || ""}
                                    jobId={candidate?.appliedJob?.id || ""}
                                    candidateId={candidate?.candidateId || ""}
                                    status={candidate?.status}
                                    overallStatus={active}
                                />
                            ))}
                        </>
                    )}
                    <div className="w-full  col-span-full mt-10">
                        <Paginator offset={offset} limit={limit} totalItems={candidates?.count || 0} currentSize={candidates?.rows?.length || 0} setOffset={setPage} />
                    </div>
                </div>
            </Show>
            {!_.isEmpty(currentCandidate) && modal === "rateCandidate" && hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_RATE) && (
                <RateCandidateModal
                    open={modal === "rateCandidate"}
                    setOpen={() => {
                        navigate({
                            search(prev) {
                                return { ...prev, modal: undefined, id: undefined };
                            },
                        });
                    }}
                />
            )}
            {!_.isEmpty(currentCandidate) && drawer === "viewCandidate" && hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_DETAILS) && (
                <ViewCandidateDrawer
                    open={drawer === "viewCandidate"}
                    setOpen={() => {
                        navigate({
                            search(prev) {
                                return { ...prev, id: undefined, drawer: undefined };
                            },
                        });
                    }}
                    count={candidates?.rows?.length || 0}
                    currentCandidateIndex={currentCandidateIndex}
                    setNext={() => {
                        setCurrentCandidate(candidates?.rows?.at(currentCandidateIndex + 1)?.id || undefined);
                        setCurrentCandidateIndex(currentCandidateIndex + 1);
                    }}
                    setPrevious={() => {
                        setCurrentCandidate(candidates?.rows?.at(currentCandidateIndex - 1)?.id || undefined);
                        setCurrentCandidateIndex(currentCandidateIndex - 1);
                    }}
                />
            )}
        </>
    );
};

export default CandidateTable;

import { gql } from "@apollo/client";

export const READ_NOTIFICATION = gql`
    mutation ReadNotification($readNotificationId: ID!) {
        readNotification(id: $readNotificationId)
    }
`;

export const DELETE_NOTIFICATION = gql`
    mutation DeleteNotification($deleteNotificationId: ID!) {
        deleteNotification(id: $deleteNotificationId)
    }
`;

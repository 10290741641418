import { Button, Show, TextInput } from "@/components";
import ButtonLoader from "@/components/loaders/button";
import { clearRepresentative } from "@/redux/auth/onboardingSlice";
import { useAppDispatch } from "@/redux/hook";
import { FormikProps } from "formik";
import { FcGoogle } from "react-icons/fc";
import { PhoneInput } from "react-international-phone";

type Props = {
    form: FormikProps<any>;
    handleGoogleAuth: () => void;
    loadingAuth: boolean;
    loading: boolean;
    setActive: (value: string) => void;
};

const RespresentativeForm = ({ handleGoogleAuth, form, loadingAuth, loading, setActive }: Props) => {
    const dispatch = useAppDispatch();
    return (
        <div className="w-full max-w-5xl mt-10 px-5">
            <div className="w-full">
                <h3 className="text-xl font-semibold">Representative Information</h3>
                <p className="text-gray-600 text-sm mt-1">Provide essential details to showcase your company on Adjuma. </p>
            </div>
            <form
                onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        event.preventDefault(); // Prevent default Enter key behavior
                        form.handleSubmit(); // Submit the form
                    }
                }}>
                <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-5 ">
                    <div className="col-span-1">
                        <TextInput id="fullname" label="Full Name" placeholder="Enter your fullname" required {...form} />
                    </div>
                    <Show if={!form.values.googleAuth}>
                        <div className="col-span-1">
                            <TextInput id="email" label="Email" placeholder="Enter your personal email" required {...form} />
                        </div>
                    </Show>
                    <div className="col-span-1">
                        <TextInput id="position" label="Position" placeholder="Enter company type" required {...form} />
                    </div>
                    <div className="col-span-1 relative">
                        <label htmlFor={"phone"} className="block text-sm font-medium text-gray-700 mb-1">
                            Telephone <span className="text-primary-500">*</span>
                        </label>
                        <PhoneInput
                            placeholder="Enter your phone number"
                            defaultCountry={"gh"}
                            className="w-full rounded"
                            countrySelectorStyleProps={{}}
                            inputClassName="w-full focus:ring-primary-500 rounded text-xs"
                            value={form.values.phone}
                            onChange={(phone) => form.setFieldValue("phone", phone)}
                        />
                    </div>
                    <Show if={!form.values.googleAuth}>
                        <div className="col-span-1">
                            <TextInput id="password" type="password" label="Password" placeholder="Choose a password" required {...form} />
                        </div>
                        <div className="col-span-1">
                            <TextInput id="confirm_password" type="password" label="Confirm Password" placeholder="Confirm the password" required {...form} />
                        </div>
                    </Show>
                    <div className="col-span-full flex flex-col-reverse md:flex-row justify-between items-center mt-5 gap-5">
                        <div className="w-full md:w-20">
                            <Button width="full" variant="minimal" onClick={() => setActive("Company")}>
                                Previous
                            </Button>
                        </div>

                        <div className="w-full md:w-fit flex items-center justify-between space-x-4">
                            <Show if={!form.values.googleAuth}>
                                <Button onClick={handleGoogleAuth} variant="minimal">
                                    <span className="flex items-center justify-center space-x-2">
                                        <FcGoogle className="text-center w-4 h-4" /> <span className="whitespace-nowrap">{loadingAuth ? "Authenticating ... " : "Continue with Google"}</span>
                                    </span>
                                </Button>
                            </Show>
                            <Show if={form.values.googleAuth}>
                                <Button
                                    width="full"
                                    variant="minimal"
                                    onClick={() => {
                                        form.resetForm();
                                        dispatch(clearRepresentative());
                                    }}>
                                    Clear
                                </Button>
                            </Show>
                            <Button onClick={form.handleSubmit}>{loading ? <ButtonLoader title="Finishing ..." /> : "Finish"}</Button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default RespresentativeForm;

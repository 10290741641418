type Props = { title: "Role Info" | "Permissions Info" | "Preview"; activeTab: "Role Info" | "Permissions Info" | "Preview"; setTab: (title: "Role Info" | "Permissions Info" | "Preview") => void };

const TabButton = ({ title, activeTab, setTab }: Props) => {
    return (
        <button onClick={() => setTab(title)} className={`pb-1 ${title === activeTab ? "border-b-2 border-primary-500 text-primary-500 font-semibold" : ""} px-2 whitespace-nowrap text-xs`}>
            {title}
        </button>
    );
};

export default TabButton;

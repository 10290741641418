import { gql } from "@apollo/client";

export const GET_ALL_JOBS = gql`
    query GetJobs($filter: JobFilter, $pagination: Pagination, $condition: Condition, $sort: JobSort) {
        getJobs(filter: $filter, pagination: $pagination, condition: $condition, sort: $sort) {
            rows {
                companyId
                urgent
                vacancies
                company {
                    id
                    name
                    email
                    phone
                    address
                    businessType
                    companyType
                    about
                    industry
                    website
                    numOfEmployees
                    yearOfEstablishment
                    coverPhoto
                    companyLogo
                    companyDocument
                    subscription {
                        package {
                            id
                            name
                            description
                            price
                            interval
                            jobsQuota
                            privileges
                            planCode
                            planId
                            createdAt
                            updatedAt
                            deletedAt
                        }
                        status
                        expiryDate
                    }
                    meta {
                        suspendedAt
                        suspendedBy
                        rejectionReason
                        suspensionReason
                        companyRequestStatus
                        status
                        isVerified
                    }
                    socials {
                        id
                        name
                        link
                    }
                    createdAt
                    updatedAt
                }
                id
                title
                type
                about
                mode
                salary {
                    maximum
                    frequency
                    minimum
                    currency
                }
                requirements
                skills
                responsibilities
                benefits
                location
                status
                openDate
                closeDate
                applicationCount
                comments {
                    id
                    comment
                    talent {
                        fullName
                        id
                        avatar
                    }
                }
                likes {
                    id
                }
                createdAt
                updatedAt
            }
            count
        }
    }
`;

export const GET_DRAFTED_JOBS = gql`
    query GetDraftedJobs($filter: JobFilter, $pagination: Pagination, $condition: Condition, $sort: JobSort) {
        getDraftedJobs(filter: $filter, pagination: $pagination, condition: $condition, sort: $sort) {
            id
            title
            type
            about
            mode
            urgent
            vacancies
            salary {
                maximum
                frequency
                minimum
                currency
            }
            companyId
            requirements
            responsibilities
            benefits
            location
            openDate
            closeDate
        }
    }
`;

export const GET_JOB = gql`
    query GetJob($jobId: ID!) {
        getJob(jobId: $jobId) {
            companyId
            urgent
            vacancies
            company {
                id
                name
                email
                phone
                address
                businessType
                companyType
                about
                industry
                website
                numOfEmployees
                yearOfEstablishment
                coverPhoto
                companyLogo
                companyDocument
                subscription {
                    package {
                        id
                        name
                        description
                        price
                        interval
                        jobsQuota
                        privileges
                        planCode
                        planId
                        createdAt
                        updatedAt
                        deletedAt
                    }
                    status
                    expiryDate
                }
                meta {
                    suspendedAt
                    suspendedBy
                    rejectionReason
                    suspensionReason
                    companyRequestStatus
                    status
                    isVerified
                }
                socials {
                    id
                    name
                    link
                }
                createdAt
                updatedAt
            }
            id
            title
            type
            about
            mode
            salary {
                maximum
                frequency
                minimum
                currency
            }
            requirements
            skills
            responsibilities
            benefits
            location
            status
            openDate
            closeDate
            applicationCount
            comments {
                id
                comment
                talent {
                    fullName
                    id
                    avatar
                }
            }
            likes {
                id
            }
            createdAt
            updatedAt
        }
    }
`;

export const GET_JOB_STATS = gql`
    query GetJobStats($companyId: ID!) {
        getJobStats(companyId: $companyId) {
            draftedJobs
            activeJobs
            closedJobs
            totalJobs
        }
    }
`;

export const GET_ACTIVE_JOB_LENGTH = gql`
    query GetActiveJobsLength($companyId: ID!) {
        getActiveJobsLength(companyId: $companyId)
    }
`;

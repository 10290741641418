import { useCurrentUser } from "@/apollo/cache/auth";
import { useActiveJobLength } from "@/hooks/useActiveJobLength";
import { Progress } from "@mantine/core";

const ProgressBar = () => {
    const employer = useCurrentUser();
    const { activeJobs } = useActiveJobLength();

    return (
        <div className="w-44 flex flex-col items-end ">
            <h1 className="text-xs mb-2">
                <span className="font-semibold">
                    {activeJobs || 0} of {employer?.company?.subscription?.package?.jobsQuota}
                </span>{" "}
                job slots used
            </h1>
            <Progress value={((activeJobs || 0) / (employer?.company?.subscription?.package?.jobsQuota || 1)) * 100} color="#358f65" bg={"#f0fdf4 "} className="w-full" size="sm" />
        </div>
    );
};

export default ProgressBar;

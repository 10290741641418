import { GetJobQuery, GetJobQueryVariables } from "@/apollo/generated/types";
import { GET_JOB } from "@/apollo/graphql/queries/job";
import { useQuery, QueryHookOptions, useLazyQuery } from "@apollo/client";

export const useJob = (options?: QueryHookOptions<GetJobQuery, GetJobQueryVariables>) => {
    const { data, ...rest } = useQuery<GetJobQuery, GetJobQueryVariables>(GET_JOB, {
        ...options,
    });

    return {
        job: data?.getJob,
        ...rest,
    };
};

export const useJobLazyQuery = (options?: QueryHookOptions<GetJobQuery, GetJobQueryVariables>) => {
    const [fetchJob, { data, ...rest }] = useLazyQuery<GetJobQuery, GetJobQueryVariables>(GET_JOB, {
        ...options,
    });

    return {
        findJob: fetchJob,
        job: data?.getJob,
        ...rest,
    };
};

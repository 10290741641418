import { useCurrentUser } from "@/apollo/cache/auth";
import { DeleteJobMutation, DeleteJobMutationVariables, RateCandidateMutation, RateCandidateMutationResult, RateCandidateMutationVariables } from "@/apollo/generated/types";
import { RATE_CANDIDATE } from "@/apollo/graphql/mutations/candidate";
import { DELETE_JOB } from "@/apollo/graphql/mutations/job";
import { Modal } from "@/components";
import { useUrlState } from "@/utils";
import { useMutation } from "@apollo/client";
import { StarIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { useState } from "react";
import toast from "react-hot-toast";
import { number } from "yup";
import RateButton from "./RateButton";

type Props = { open: boolean; setOpen: (value: boolean) => void };

const RateCandidateModal = ({ open, setOpen }: Props) => {
    const [candidateId] = useUrlState("id");
    const [rating, setRating] = useState<number>(0);
    const [comment, setComment] = useState<string>("");
    const [rateCandiate, { loading }] = useMutation<RateCandidateMutation, RateCandidateMutationVariables>(RATE_CANDIDATE, { refetchQueries: ["GetCandidates"] });

    const handleRate = async () => {
        await rateCandiate({ variables: { candidateId: candidateId, rating: rating, comment: comment } })
            .then(() => {
                setOpen(false);
                toast(JSON.stringify({ type: "success", title: "Candidate Rated Successfully" }));
            })
            .catch(() => {
                setOpen(false);
                toast(JSON.stringify({ type: "error", title: "Something wrong happened" }));
            });
    };
    return (
        <Modal hideActions open={open} setOpen={setOpen} size="sm" title={""}>
            <div className="w-full h-full space-y-5">
                <h1 className="w-full text-center font-semibold text-xl">Rate Candidate</h1>
                <h6 className="w-full text-center text-sm">Rate this candidate base on abilities and capabilities</h6>
                <div className="flex w-full justify-center items-center space-x-2">
                    <RateButton value={1} rate={rating} setRate={setRating} />
                    <RateButton value={2} rate={rating} setRate={setRating} />
                    <RateButton value={3} rate={rating} setRate={setRating} />
                    <RateButton value={4} rate={rating} setRate={setRating} />
                    <RateButton value={5} rate={rating} setRate={setRating} />
                </div>
                <div className="flex flex-col w-full items-start justify-start ">
                    <textarea value={comment} onChange={(e) => setComment(e.target.value)} className="h-12 border text-xs w-full rounded p-2" placeholder="Add Comment" />
                </div>
                <div className="w-full flex justify-between p-x-5 items-center">
                    <button className="px-2 py-2 text-sm text-gray-500 border rounded font-semibold" onClick={() => setOpen(false)}>
                        Cancel
                    </button>
                    <button className="text-sm px-2 py-2 bg-primary-500 text-white rounded font-semibold" onClick={handleRate}>
                        {loading ? "Rating ..." : "Rate Candidate"}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default RateCandidateModal;

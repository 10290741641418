import { GetEmployerRoleQuery, GetEmployerRoleQueryVariables } from "@/apollo/generated/types";
import { GET_ROLE } from "@/apollo/graphql/queries/setting";
import { useQuery, QueryHookOptions } from "@apollo/client";

export const useRole = (options?: QueryHookOptions<GetEmployerRoleQuery, GetEmployerRoleQueryVariables>) => {
    const { data, ...rest } = useQuery<GetEmployerRoleQuery, GetEmployerRoleQueryVariables>(GET_ROLE, {
        ...options,
    });

    return {
        role: data?.getEmployerRole,
        ...rest,
    };
};

import { ApplicationStatus, RejectApplicationMutation, RejectApplicationMutationVariables } from "@/apollo/generated/types";
import { REJECT_CANDIDATE } from "@/apollo/graphql/mutations/candidate";
import { Modal } from "@/components";
import { useMutation } from "@apollo/client";
import { HandThumbDownIcon } from "@heroicons/react/24/outline";
import { HandThumbDownIcon as HandThumbDownIconSolid } from "@heroicons/react/24/solid";
import { useState } from "react";
import toast from "react-hot-toast";
import { Tooltip } from "react-tooltip";

type Props = { applicationId: string; jobId: string; status: ApplicationStatus | undefined | null };

const RejectCandidateButton = ({ applicationId, jobId, status }: Props) => {
    const [open, setOpen] = useState<boolean>(false);
    const [rejectCandidate, { loading }] = useMutation<RejectApplicationMutation, RejectApplicationMutationVariables>(REJECT_CANDIDATE, { refetchQueries: ["GetCandidates", "GetCandidate"] });
    const handleAccept = async () => {
        await rejectCandidate({ variables: { applicationId: applicationId, jobId: jobId } })
            .then(() => {
                toast(
                    JSON.stringify({
                        type: "success",
                        title: "Candidate rejected successfully",
                    })
                );
                setOpen(false);
            })
            .catch(() => {
                toast(
                    JSON.stringify({
                        type: "error",
                        title: "Something wrong happened",
                    })
                );
                setOpen(false);
            });
    };
    return (
        <>
            <button disabled={status === ApplicationStatus.Rejected} onClick={() => setOpen(true)} className="p-1 rounded-full hover:bg-gray-300 hover:text-black" data-tooltip-delay-show={100} data-tooltip-id="global-tooltip" data-tooltip-content="Reject Candidate">
                {status === ApplicationStatus.Rejected ? <HandThumbDownIconSolid className="h-5 w-5 text-primary-500" /> : <HandThumbDownIcon className="h-5 w-5" />}
                <Tooltip
                    style={{
                        fontSize: "10px",
                        fontWeight: "600",
                        backgroundColor: "#101828",
                        color: "#fff",
                        borderRadius: "20px",
                        marginTop: "10px",
                        height: "10px",
                        zIndex: 50, // Ensure the tooltip is on the outmost layer
                    }}
                    anchorSelect={`#reject-candidate-${applicationId}`}
                    content={`Reject Candidate`}
                    className="z-50"
                />
            </button>
            {open && (
                <Modal title="" open={open} setOpen={setOpen} hideActions size="md" hideCloseButton>
                    <div className="w-full flex flex-col justify-center items-center">
                        <h1 className="text-center font-semibold text-lg">Confirm Candidate Rejection</h1>
                        <p className="text-center my-5 text-sm">
                            Are you sure you want to reject this candidate?
                            <br /> This action is irreversible
                        </p>
                    </div>
                    <div className="flex justify-center gap-5 text-sm">
                        <button onClick={() => setOpen(false)} className="border px-3 py-2 rounded-md">
                            Cancel
                        </button>
                        <button onClick={handleAccept} className="bg-primary-500 text-white px-3 py-1 rounded-md ">
                            {loading ? "Rejecting" : "Yes, Reject"}
                        </button>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default RejectCandidateButton;

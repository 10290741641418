import { Navigate, Route } from "react-location";
import SignIn from "../pages/signin";
import SignUp from "../pages/signup";
import PasswordReset from "../pages/forgot-password";
import CandidatesPage from "../pages/candidates";
import ProfilePage from "../pages/profile";
import JobsPage from "../pages/jobs";
import SettingsPage from "../pages/settings/index";
import Terms from "../pages/terms";
import PostJobPage from "@/pages/post-job";
import NotificationsPage from "@/pages/notification";

import { AcademicCapIcon, BellIcon, BriefcaseIcon, ChatBubbleLeftRightIcon, ClipboardDocumentListIcon, Cog6ToothIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import MessagesPage from "@/pages/messages";
import { Permission, PERMISSIONS } from "@/permissions";

export type RouteProps = Omit<Route, "children"> & {
    withPermissions?: Permission[];
    navigation?: boolean;
    sidebar?: { label: string; icon: any };
    children?: RouteProps[];
};

const routes: RouteProps[] = [
    {
        path: "/",
        element: <JobsPage />,
        meta: {
            layout: "App",
        },
        sidebar: {
            label: "Jobs",
            icon: BriefcaseIcon,
        },
    },
    {
        path: "post-job",
        element: <PostJobPage />,
        meta: {
            layout: "App",
        },
        sidebar: {
            label: "Post Job",
            icon: ClipboardDocumentListIcon,
        },
        withPermissions:[PERMISSIONS.JOB_POST_VIEW]
    },
    {
        path: "candidates",
        element: <CandidatesPage />,
        meta: {
            layout: "App",
        },
        sidebar: {
            label: "Candidates",
            icon: AcademicCapIcon,
        },
        withPermissions:[PERMISSIONS.CANDIDATE_VIEW]
    },
    {
        path: "messages",
        element: <MessagesPage />,
        meta: {
            layout: "App",
        },
        sidebar: {
            label: "Messages",
            icon: ChatBubbleLeftRightIcon,
        },
        withPermissions:[PERMISSIONS.MESSAGE_VIEW]
    },
    {
        path: "notifications",
        element: <NotificationsPage />,
        meta: {
            layout: "App",
        },
        sidebar: {
            label: "Notifications",
            icon: BellIcon,
        },
        withPermissions:[PERMISSIONS.NOTIFICATION_VIEW]
    },

    {
        path: "settings",
        element: <SettingsPage />,
        meta: {
            layout: "App",
        },
        sidebar: {
            label: "Settings",
            icon: Cog6ToothIcon,
        },
        withPermissions:[]
    },
    {
        path: "profile",
        element: <ProfilePage />,
        meta: {
            layout: "App",
        },
        sidebar: {
            label: "Profile",
            icon: UserCircleIcon,
        },
        withPermissions:[]
    },
    // {
    //     path: "notification-detail",
    //     element: <NotificationDetail />,
    //     meta: {
    //         layout: "App",
    //     },
    //     withPermissions:[]
    // },
    {
        path: "terms",
        element: <Terms />,
        meta: {
            layout: "Auth",
        },
    },
    {
        path: "login",
        element: <SignIn />,
        meta: {
            layout: "Auth",
        },
    },
    {
        path: "signup",
        element: <SignUp />,
        meta: {
            layout: "Auth",
        },
    },
    {
        path: "forgot-password",
        element: <PasswordReset />,
        meta: {
            layout: "Auth",
        },
    },
];

export default routes;

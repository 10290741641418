import { Button, Drawer, Show } from "@/components";
import { DocumentTextIcon, GlobeAltIcon } from "@heroicons/react/24/outline";
import CandidateDetailView from "../components/CandidateDetailView";
import man from "@/assets/images/male.jpeg";
import CandidateDetailItem from "../components/CandidateDetailItem";
import { useCandidate } from "@/hooks/useCandidate";
import _ from "lodash";
import moment from "moment";
import { useCurrentUserPermissions, useCurrentUser } from "@/apollo/cache/auth";
import { useUrlState } from "@/utils";
import { useEffect } from "react";
import AcceptCandidateButton from "../components/action-buttons/AcceptCandidateButton";
import RejectCandidateButton from "../components/action-buttons/RejectCandidateButton";
import MessageButton from "./components/MessageButton";
import Avatar from "@/components/core/avatar";
import { ApplicationStatus, JobSeeker } from "@/apollo/generated/types";
// import HireCandidateButton from "../components/action-buttons/HireCandidateButton";
import RateCandidateButton from "../components/action-buttons/RateCandidateButton";
import { hasPermission, PERMISSIONS } from "@/permissions";

type Props = { open: boolean; setOpen: () => void; setNext: () => void; setPrevious: () => void; currentCandidateIndex: number; count: number };

const ViewCandidateDrawer = ({ open, setOpen, currentCandidateIndex, setNext, setPrevious, count }: Props) => {
    const employer = useCurrentUser();
    const currentUserPermissions = useCurrentUserPermissions();
    const [candidateId] = useUrlState("id");
    const { candidate, loading, refetch } = useCandidate({ variables: { applicationId: candidateId || "", companyId: employer?.companyId || "" } });

    useEffect(() => {
        refetch();
    }, [candidateId, currentCandidateIndex]);

    return (
        <Drawer
            title="Candidate Details"
            loading={loading}
            open={open}
            setOpen={setOpen}
            renderActions={() => (
                <div className="w-full flex justify-end items-center space-x-5">
                    <div>
                        <Button variant="minimal" disabled={currentCandidateIndex <= 0} onClick={() => setPrevious()}>
                            Previous
                        </Button>
                    </div>
                    <div>
                        <Button disabled={currentCandidateIndex === count - 1} onClick={() => setNext()}>
                            Next
                        </Button>
                    </div>
                </div>
            )}>
            <div className="w-full h-full overflow-scroll overflow-y-scroll no-scrollbar relative space-y-5 ">
                <div className="sticky top-0 w-full flex justify-between items-center pb-5 px-2 border-b border-black/5 z-10 bg-white">
                    {candidate?.status === ApplicationStatus.Pending && (
                        <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_ACCEPT) || hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_REJECT)}>
                            <div className="flex justify-start items-center space-x-4 border p-2 rounded">
                                <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_ACCEPT)}>
                                    <AcceptCandidateButton applicationId={candidate?.id || ""} jobId={candidate?.appliedJob?.id || ""} status={candidate?.status} />
                                </Show>
                                <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_REJECT)}>
                                    <RejectCandidateButton applicationId={candidate?.id || ""} jobId={candidate?.appliedJob?.id || ""} status={candidate?.status} />
                                </Show>
                            </div>
                        </Show>
                    )}
                    {candidate?.status === ApplicationStatus.Accepted && (
                        <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_HIRE) || hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_REJECT)}>
                            <div className="flex justify-start items-center space-x-4 border p-2 rounded">
                                {/* <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_HIRE)}>
                                    <HireCandidateButton applicationId={candidate?.id || ""} jobId={candidate?.appliedJob?.id || ""} status={candidate?.status} />
                                </Show> */}
                                <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_REJECT)}>
                                    <RejectCandidateButton applicationId={candidate?.id || ""} jobId={candidate?.appliedJob?.id || ""} status={candidate?.status} />
                                </Show>
                            </div>
                        </Show>
                    )}
                    {candidate?.status === ApplicationStatus.Rejected && (
                        <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_ACCEPT)}>
                            <div className="flex justify-start items-center space-x-4 border p-2 rounded">
                                <AcceptCandidateButton applicationId={candidate?.id || ""} jobId={candidate?.appliedJob?.id || ""} status={candidate?.status} />
                            </div>
                        </Show>
                    )}
                    {candidate?.status === ApplicationStatus.Hired && (
                        <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_RATE)}>
                            <div className="flex justify-start items-center space-x-4 p-2 rounded">
                                <RateCandidateButton candidateId={candidate?.id || ""} rate={candidate?.evaluation?.rating} />
                            </div>
                        </Show>
                    )}
                    <span></span>
                    <Show if={hasPermission(currentUserPermissions, PERMISSIONS.CANDIDATE_MESSAGE)}>
                        <div>
                            <MessageButton employer={employer} jobId={candidate?.appliedJob?.id || ""} talent={candidate?.talent as JobSeeker} />
                        </div>
                    </Show>
                </div>
                <div className="w-full space-y-5">
                    <CandidateDetailView title="PROFILE INFORMATION">
                        <div className="space-y-4">
                            <div className="rounded w-full p-5 border border-slate-200 flex justify-between items-start space-x-5">
                                <Avatar src={candidate?.talent?.avatar || man} alt="candidate-image" size="lg" />
                                <div className="flex-1 space-y-3">
                                    <CandidateDetailItem title={"Full Name"} value={candidate?.talent?.fullName || "N/A"} />
                                    <CandidateDetailItem title={"City/Region"} value={candidate?.talent?.address || "N/A"} />
                                    <div className="grid grid-cols-2">
                                        <CandidateDetailItem title={"Email"} value={candidate?.talent?.email || "N/A"} />
                                        <CandidateDetailItem title={"Phone Number"} value={candidate?.talent?.phoneNumber || "N/A"} />
                                    </div>
                                </div>
                            </div>
                            <div className="rounded w-full p-5 border border-slate-200 space-y-2">
                                <h1 className="text-xs font-medium">About Candidate</h1>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: candidate?.talent?.profileSummary || "N/A",
                                    }}
                                    className="text-xs text-slate-500 leading-4 font-light "
                                />
                            </div>
                        </div>
                    </CandidateDetailView>
                    <CandidateDetailView title="PROFESSIONAL BACKGROUND">
                        <>
                            {candidate?.talent?.professionalBg?.map((data: any) => (
                                <div className="rounded w-full p-5 border border-slate-200 space-y-3">
                                    <CandidateDetailItem title={"Profession"} value={data?.jobTitle || "N/A"} />
                                    <CandidateDetailItem title={"Company/Organization"} value={data?.companyName || "N/A"} />
                                    <CandidateDetailItem title={"Period"} value={`${!_.isEmpty(data?.startDate) ? moment(data?.startDate).format("MMMM YYYY") : "N/A"} - ${data?.isCurrentJob ? "Present" : !_.isEmpty(data?.endDate) ? moment(data?.endDate).format("MMMM YYYY") : "N/A"}`} />
                                    <CandidateDetailItem title={"Skills"} multiple value={data?.skills?.join(", ")} />
                                </div>
                            ))}
                        </>
                    </CandidateDetailView>
                    <CandidateDetailView title="PORTOFOLIO">
                        <div className="space-y-3">
                            <div className="rounded w-full p-5 border border-slate-200 space-y-3">
                                <CandidateDetailItem title={"Website"} value={candidate?.applicationDetails?.portfolio || "N/A"} Icon={GlobeAltIcon} />
                            </div>
                            <div className="rounded w-full p-5 border border-slate-200 space-y-3">
                                <CandidateDetailItem title={"CV / Resume"} Icon={DocumentTextIcon} />
                                <a href={candidate?.applicationDetails?.resume || "#"} target="__blank" className="flex flex-col justify-center items-start text-xs p-3 rounded-md bg-slate-100 w-full border border-gray-100">
                                    <h1>{candidate?.applicationDetails?.resume ? `${candidate?.talent?.fullName} CV` : "N/A"}</h1>
                                </a>
                            </div>
                        </div>
                    </CandidateDetailView>
                    <CandidateDetailView title="REFERENCES">
                        <>
                            {candidate?.talent?.references?.map((info: any) => (
                                <div className="space-y-3">
                                    <div className="rounded w-full p-5 border border-slate-200 space-y-3">
                                        <CandidateDetailItem title={"Name"} value={info?.fullName || "N/A"} />
                                        <div className="grid grid-cols-2">
                                            <CandidateDetailItem title={"Company"} value={info?.company || "N/A"} />
                                            <CandidateDetailItem title={"Position"} value={info?.position || "N/A"} />
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <CandidateDetailItem title={"Email"} value={info?.email || "N/A"} />
                                            <CandidateDetailItem title={"Phone"} value={info?.phoneNumber || "N/A"} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    </CandidateDetailView>
                </div>
            </div>
        </Drawer>
    );
};

export default ViewCandidateDrawer;

import { useCurrentUser } from "@/apollo/cache/auth";
import { GetActiveJobsLengthQuery, GetActiveJobsLengthQueryVariables } from "@/apollo/generated/types";
import { GET_ACTIVE_JOB_LENGTH } from "@/apollo/graphql/queries/job";

import { useQuery, QueryHookOptions } from "@apollo/client";

export const useActiveJobLength = () => {
    const employer = useCurrentUser();
    const { data, loading } = useQuery<GetActiveJobsLengthQuery, GetActiveJobsLengthQueryVariables>(GET_ACTIVE_JOB_LENGTH, { variables: { companyId: employer?.companyId || "" }, fetchPolicy: "network-only" });

    return {
        activeJobs: data?.getActiveJobsLength,
        loading,
    };
};

import { Modal, Show } from "@/components";
import { useFormik } from "formik";
import TabButton from "./tab/components/TabButton";
import { useState } from "react";
import RoleInfo from "./tab/RoleInfo";
import PermissionInfo from "./tab/PermissionInfo";
import Preview from "./tab/Preview";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { CREATE_ROLE } from "@/apollo/graphql/mutations/setting";
import { CreateEmployerRoleMutation, CreateEmployerRoleMutationVariables } from "@/apollo/generated/types";
import { useAppNavigate, useCurrentUser } from "@/apollo/cache/auth";
import toast from "react-hot-toast";

type Props = { open: boolean; setOpen: (value: boolean) => void };

const CreateRoleModal = ({ open, setOpen }: Props) => {
    const employer = useCurrentUser();
    const navigate = useAppNavigate();
    const [activeTab, setActiveTab] = useState<"Role Info" | "Permissions Info" | "Preview">("Role Info");
    const [createRole, { loading }] = useMutation<CreateEmployerRoleMutation, CreateEmployerRoleMutationVariables>(CREATE_ROLE, { refetchQueries: ["GetRoles"] });
    const form = useFormik({
        initialValues: {
            name: "",
            code: "",
            description: "",
            permissions: [],
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Role name is required"),
            code: Yup.string().required("Role code is required"),
            description: Yup.string().required("Role description is required"),
            permissions: Yup.array().min(1, "Select at least one permission").required("Permissions is a required field"),
        }),
        onSubmit: async (values) => {
            await createRole({ variables: { code: values.code, description: values.description, permissions: values.permissions, name: values.name, companyId: employer?.companyId || "" } }).then(() => {
                toast(
                    JSON.stringify({
                        type: "success",
                        title: "Role created successfully",
                    })
                );
                form.resetForm();
                navigate({
                    search(prev) {
                        return { ...prev, modal: undefined };
                    },
                });
            });
        },
    });
    return (
        <Modal
            title={""}
            open={open}
            setOpen={(value) => {
                form.resetForm();
                setOpen(value);
            }}
            hideActions={true}
            size="lg">
            <h1 className="text-sm font-semibold -mt-2">Add New Role</h1>
            <div className="w-full flex items-center justify-start space-x-2 border-b mt-4">
                <TabButton title="Role Info" activeTab={activeTab} setTab={setActiveTab} />
                <TabButton title="Permissions Info" activeTab={activeTab} setTab={setActiveTab} />
                <TabButton title="Preview" activeTab={activeTab} setTab={setActiveTab} />
            </div>
            <div className="w-full">
                <Show if={activeTab === "Role Info"}>
                    <RoleInfo form={form} setTab={setActiveTab} />
                </Show>
                <Show if={activeTab === "Permissions Info"}>
                    <PermissionInfo form={form} setTab={setActiveTab} />
                </Show>
                <Show if={activeTab === "Preview"}>
                    <Preview form={form} setTab={setActiveTab} loading={loading} />
                </Show>
            </div>
        </Modal>
    );
};

export default CreateRoleModal;

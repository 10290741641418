/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from "@reduxjs/toolkit";
import { update } from "lodash";

interface initialState {
  companyDetails: any;
}

const initialState: initialState = {
  companyDetails: null, // Add user field to store user data
};

// Create an companyentication slice using Redux Toolkit
const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    updateCompanyDetails: (state, action) => {
      state.companyDetails = action.payload;
    },
  },
});

export const { updateCompanyDetails } = companySlice.actions;

// Create selectors to access the companyentication state and user data
export const selectCompany = (state: { company: any }) =>
  state.company.companyDetails;

// Export the reducer for use in the Redux store configuration
export default companySlice.reducer;

import { Fragment } from "react";
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";

import { SlLocationPin } from "react-icons/sl";
import { CiCreditCard2 } from "react-icons/ci";
import { TbBriefcase, TbMessage, TbMessageDots } from "react-icons/tb";
import { TbHexagon3D } from "react-icons/tb";
import { TbClockPlay } from "react-icons/tb";
import { TbClockPause } from "react-icons/tb";
import { Button, Drawer, Show } from "@/components";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useJob } from "@/hooks";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import { useUrlState } from "@/utils";
import { useAppNavigate, useCurrentUserPermissions } from "@/apollo/cache/auth";
import { FiThumbsUp } from "react-icons/fi";
import { JobStatus, SalaryFrequency } from "@/apollo/generated/types";
import { hasPermission, PERMISSIONS } from "@/permissions";
import { formatCurrency, formatPeriod } from "@/utils/formatter";

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export default function JobDrawer({ open, setOpen }: Props) {
    const currentUserPermissions = useCurrentUserPermissions();
    const navigate = useAppNavigate();
    const [jobId] = useUrlState("id");
    const { job, loading } = useJob({
        variables: {
            jobId: jobId || "",
        },
    });
    return (
        <Drawer
            title="Job Details"
            open={open}
            loading={loading}
            setOpen={setOpen}
            renderActions={() => (
                <div className="w-full flex justify-between items-center">
                    <button />
                    <div className="flex items-center gap-5">
                        <Button
                            variant="minimal"
                            size="md"
                            onClick={() => {
                                setOpen(false);
                            }}>
                            Close
                        </Button>
                        <Show if={job?.status !== JobStatus.Closed && hasPermission(currentUserPermissions, PERMISSIONS.JOB_UPDATE)}>
                            <Button
                                size="md"
                                onClick={() =>
                                    navigate({
                                        search(prev) {
                                            return { ...prev, drawer: "editJob" };
                                        },
                                    })
                                }>
                                Edit
                            </Button>
                        </Show>
                    </div>
                </div>
            )}>
            <>
                {/* Content */}
                <h1 className="font-bold text-2xl text-gray-700">{job?.title}</h1>

                <div className="flex items-center  justify-start flex-wrap gap-5 mt-5 select-none">
                    <div className="flex items-center text-[#6B6B6B] gap-1.5 py-1 rounded">
                        <SlLocationPin className="w-4 h-4 text-[#6B6B6B]" aria-hidden="true" />
                        <p className="font-medium text-xs ">{job?.location}</p>
                    </div>
                    {parseInt(job?.salary?.minimum || "") > 0 && parseInt(job?.salary?.maximum || "") > 0 ? (
                        <div className="flex items-center text-[#02733E] gap-1 bg-[#EBFFEB] px-2 py-1 rounded">
                            <CiCreditCard2 className="w-5 h-5 text-[#02733E]" aria-hidden="true" />
                            <p className="font-medium text-xs ">
                                {formatCurrency(job?.salary?.currency as string)}{numeral(job?.salary?.minimum).format("#,#")} - {formatCurrency(job?.salary?.currency as string)}{numeral(job?.salary?.maximum).format("#,#")} / {formatPeriod(job?.salary?.frequency as SalaryFrequency) }
                            </p>
                        </div>
                    ) : (
                        <div className="flex items-center text-[#02733E] gap-1 bg-[#EBFFEB] px-2 py-1 rounded">
                            <CiCreditCard2 className="w-5 h-5 text-[#02733E]" aria-hidden="true" />
                            <p className="font-medium text-xs ">Negotiable</p>
                        </div>
                    )}

                    <div className="flex items-center text-black gap-1 bg-slate-100 px-2 py-1 rounded">
                        <TbBriefcase className="w-5 h-5 text-black" aria-hidden="true" />
                        <p className="font-medium text-xs ">{job?.type}</p>
                    </div>
                    <div className="flex items-center text-black gap-1 bg-slate-100 px-2 py-1 rounded">
                        <TbHexagon3D className="w-5 h-5 text-black" aria-hidden="true" />
                        <p className="font-medium text-xs ">{job?.mode}</p>
                    </div>
                    <div className="flex items-center text-[#02733E] gap-1 bg-[#EBFFEB] px-2 py-1 rounded">
                        <TbClockPlay className="w-5 h-5 text-[#02733E]" aria-hidden="true" />
                        <p className="font-medium text-xs ">Opening: {moment(job?.openDate).format("MMMM Do, YYYY")}</p>
                    </div>
                    <div className="flex items-center text-[#DB0909] gap-1 bg-[#FEE4E2] px-2 py-1 rounded">
                        <TbClockPause className="w-5 h-5 text-[#DB0909]" aria-hidden="true" />
                        <p className="font-medium text-xs ">Closing: {moment(job?.closeDate).format("MMMM Do, YYYY")}</p>
                    </div>
                    <div className="flex items-center gap-2  px-3 py-1 rounded bg-slate-100">
                        <FiThumbsUp className="w-5 h-4" aria-hidden="true" />
                        <p className="font-medium text-xs "> {job?.likes?.length || 0}</p>
                    </div>
                </div>

                <div className="mt-8">
                    <h1 className="text-bold text-md text-gray-900 mb-2">About this job</h1>
                    <ul className="my-1">
                        <div className="text-xs text-gray-600 description" dangerouslySetInnerHTML={{ __html: job?.about || "" }} />
                    </ul>
                </div>

                <div className="mt-8">
                    <h1 className="text-bold text-md text-gray-900 mb-2">Responsibilities</h1>
                    <div className="text-xs text-gray-600 description" dangerouslySetInnerHTML={{ __html: job?.responsibilities || "" }} />
                </div>

                <div className="mt-8">
                    <h1 className="text-bold text-md text-gray-900 mb-2">Requirements/ Qualifications</h1>
                    <div className="text-xs text-gray-600 description" dangerouslySetInnerHTML={{ __html: job?.requirements || "" }} />
                </div>
                {!_.isEmpty(job?.benefits) && job?.benefits?.length as number > 11 && (
                    <div className="mt-8">
                        <h1 className="text-bold text-md text-gray-900 mb-2">Benefits</h1>
                        <div className="text-xs text-gray-600 description" dangerouslySetInnerHTML={{ __html: job?.benefits || "" }} />
                    </div>
                )}
            </>
        </Drawer>
    );
}

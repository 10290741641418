import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: false,
};

export const editJobModalSlice = createSlice({
  name: "editJobModal",
  initialState,
  reducers: {
    showEditJob: (state) => {
      state.value = true;
    },
    hideEditJob: (state) => {
      state.value = false;
    },
  },
});

export const { showEditJob, hideEditJob } = editJobModalSlice.actions;

export default editJobModalSlice.reducer;

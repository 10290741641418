import { StarIcon } from "@heroicons/react/24/outline";
import { StarIcon as StarIconSolid } from "@heroicons/react/24/solid";

type Props = {
    value: number;
    rate: number;
    setRate: (value: number) => void;
};

const RateButton = ({ value, rate, setRate }: Props) => {
    return <button onClick={() => setRate(value)}>{value <= rate ? <StarIconSolid className="h-8 w-8 text-yellow-500" /> : <StarIcon className="h-8 w-8 text-gray-500" />}</button>;
};

export default RateButton;

import { SalaryFrequency } from "@/apollo/generated/types";

export const SalaryDurationOption = [
    { value: SalaryFrequency.Hourly, label: "Salary Per Hour" },
    { value: SalaryFrequency.Daily, label: "Salary Per Day" },
    { value: SalaryFrequency.Monthly, label: "Salary Per Month" },
    { value: SalaryFrequency.Yearly, label: "Salary Per Year" },
];

export const CurrencyOption = [
    { value: "GHS", label: "GHS" },
    { value: "USD", label: "USD" },
];

export const ModeOfWorkOption = [
    { value: "Remote", label: "Remote" },
    { value: "Hybrid", label: "Hybrid" },
    { value: "In-Person", label: "In-Person" },
];


export const JobTypeOption = [
  { value: "Full Time", label: "Full Time" },
  { value: "Part Time", label: "Part Time" },
  { value: "Contract", label: "Contract" },
  { value: "Internship", label: "Internship" },
]

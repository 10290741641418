import { useAppNavigate } from "@/apollo/cache/auth";
import { Button, Modal } from "@/components";
import { useFetchChatGPTResponse } from "@/hooks";
import { FormikProps } from "formik";
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import toast from "react-hot-toast";
import OpenAI from "openai";
import ButtonLoader from "@/components/loaders/button";

enum Section {
    About = "about",
    Responsibilities = "responsibilities",
    Requirements = "requirements",
    Benefits = "benefits",
}
type Props = { open: boolean; setOpen: (value: boolean) => void; form: FormikProps<any> };

const AiModal = ({ open, setOpen, form }: Props) => {
    const navigate = useAppNavigate();
    const apiKey = import.meta.env.VITE_APP_OPENAI_API_KEY;
    const openai = new OpenAI({ apiKey, dangerouslyAllowBrowser: true, maxRetries: 1 });
    const [text, setText] = useState<string>("");
    const [textError, setTextError] = useState<string>("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        if (text.length > 10) {
            setLoading(true);
            const completion = await openai.chat.completions.create({
                messages: [{ role: "user", content: `Help me generate job details for job post on a platform without the company information and job title but only about the job, requirements of the job, responsibilities of the job and the benefit seperately with the following details: ${text}` }],
                model: "gpt-4o",
                max_tokens: 512,
                temperature: 0,
            });
            const response = completion.choices.at(0)?.message?.content;

            if (response) {
                console.log(response);
                const sections: Record<Section, string> = {
                    [Section.About]: "",
                    [Section.Responsibilities]: "",
                    [Section.Requirements]: "",
                    [Section.Benefits]: "",
                };

                const lines = response.split("\n");
                let currentSection: Section | "" = "";

                lines.forEach((line) => {
                    if (line.includes("Job Description") || line.includes("About")) {
                        currentSection = Section.About;
                    } else if (line.includes("Key Responsibilities") || line.includes("Responsibilities")) {
                        currentSection = Section.Responsibilities;
                    } else if (line.includes("Requirements")) {
                        currentSection = Section.Requirements;
                    } else if (line.includes("Benefits")) {
                        currentSection = Section.Benefits;
                    }

                    if (currentSection && line.trim() && !line.includes("**")) {
                        sections[currentSection] += `${line.trim()}\n`;
                    }
                });

                form.setFieldValue("about", sections.about.trim());
                form.setFieldValue("requirements", sections.requirements.trim().replaceAll("\n", "<br/>"));
                form.setFieldValue("responsibilities", sections.responsibilities.trim().replaceAll("\n", "<br/>"));
                form.setFieldValue("benefits", sections.benefits.trim().replaceAll("\n", "<br/>"));
                toast(JSON.stringify({ type: "success", title: "Job details generated successfully" }));
                navigate({
                    search(prev) {
                        return { ...prev, modal: undefined };
                    },
                });
            } else {
                toast(JSON.stringify({ type: "error", title: "Something wrong happened. Try again." }));
            }
            setLoading(false);
        } else {
            setTextError("Inadequate information");
        }
    };

    useEffect(() => {
        if (text?.length > 10) {
            setTextError("");
        }
    }, [text]);

    return (
        <Modal hideActions open={open} setOpen={setOpen} size="md" title={""}>
            <div className="w-full h-full space-y-5">
                <div className="w-full">
                    <h1 className="w-full text-center font-semibold text-lg  ">Generate With AI</h1>
                    <h6 className="w-full text-center text-xs text-gray-500">Enter the key information related to the various various fields</h6>
                </div>
                <>
                    <div className=" px-5 pb-2">
                        <div className="w-full">
                            <textarea value={text} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setText(e.target.value)} placeholder="Tell us about the job?" className="border w-full rounded text-xs p-2" />
                            {textError?.length > 0 && <p className="text-xs mt-0.5 text-red-500">{textError}</p>}
                        </div>
                    </div>
                    <div className="w-full border-t flex justify-between items-center pt-2 ">
                        <div>
                            <Button
                                size="sm"
                                variant="minimal"
                                onClick={() =>
                                    navigate({
                                        search(prev) {
                                            return { ...prev, modal: undefined };
                                        },
                                    })
                                }>
                                <span className="text-gray-500">Cancel</span>
                            </Button>
                        </div>

                        <div className="space-x-2 flex items-center justify-end">
                            <div>
                                <Button size="sm" onClick={() => handleSubmit()}>
                                    <span>{loading ? <ButtonLoader title="Generating ..." /> : "Next"}</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            </div>
        </Modal>
    );
};

export default AiModal;

import { gql } from "@apollo/client";

export const UPDATE_EMPLOYER = gql`
    mutation UpdateEmployer($fullName: String!, $email: String!, $phone: String!, $profilePicture: String!, $position: String!) {
        updateEmployer(fullName: $fullName, email: $email, phone: $phone, profilePicture: $profilePicture, position: $position)
    }
`;

export const DELETE_EMPLOYER = gql`
    mutation DeleteEmployer($input: DeleteEmployer!) {
        deleteEmployer(input: $input)
    }
`;

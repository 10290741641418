import { GetDraftedJobsQuery, GetDraftedJobsQueryVariables, GetJobsQuery, GetJobsQueryVariables } from "@/apollo/generated/types";
import { GET_ALL_JOBS, GET_DRAFTED_JOBS } from "@/apollo/graphql/queries/job";
import { useQuery, QueryHookOptions } from "@apollo/client";

export const useJobs = (options?: QueryHookOptions<GetJobsQuery, GetJobsQueryVariables>) => {
    const { data, ...rest } = useQuery<GetJobsQuery, GetJobsQueryVariables>(GET_ALL_JOBS, {
        ...options,
        fetchPolicy: "network-only",
    });

    return {
        jobs: data?.getJobs,
        ...rest,
    };
};
export const useDraftJobs = (options?: QueryHookOptions<GetDraftedJobsQuery, GetDraftedJobsQueryVariables>) => {
    const { data, ...rest } = useQuery<GetDraftedJobsQuery, GetDraftedJobsQueryVariables>(GET_DRAFTED_JOBS, {
        ...options,
        fetchPolicy: "network-only",
    });

    return {
        drafted_jobs: data?.getDraftedJobs,
        ...rest,
    };
};

import { GetJobStatsQuery, GetJobStatsQueryVariables } from "@/apollo/generated/types";
import { GET_JOB_STATS } from "@/apollo/graphql/queries/job";
import { QueryHookOptions, useQuery } from "@apollo/client";

export const useJobStats = (options?: QueryHookOptions<GetJobStatsQuery, GetJobStatsQueryVariables>) => {
    const { data, ...rest } = useQuery<GetJobStatsQuery, GetJobStatsQueryVariables>(GET_JOB_STATS, {
        ...options,
        fetchPolicy: "network-only",
    });

    return {
        stats: data?.getJobStats,
        ...rest,
    };
};

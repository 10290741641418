/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation as useMutationTanstack } from "@tanstack/react-query";

import { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useMutation } from "@apollo/client";

import toast from "react-hot-toast";
import { useFormik } from "formik";
import Modal from "../../components/modal";
import { UPDATE_EMPLOYER } from "@/apollo/graphql/mutations/employer";
import { Employer, UpdateEmployerMutation, UpdateEmployerMutationVariables } from "@/apollo/generated/types";
import _ from "lodash";

const RepresentativeModal = ({ visible, onClose, refetch, data }: { visible: boolean; onClose: () => void; refetch: () => void; data: Employer }) => {
    const [updateEmployer, { loading }] = useMutation<UpdateEmployerMutation, UpdateEmployerMutationVariables>(UPDATE_EMPLOYER, { refetchQueries: ["GetEmployer"] });

    const form = useFormik({
        initialValues: {
            fullName: "",
            email: "",
            position: "",
            phoneNumber: "",
            profilePicture: "",
        },
        onSubmit: async (values) => {
            await updateEmployer({
                variables: {
                    fullName: values.fullName,
                    email: values.email,
                    phone: values.phoneNumber,
                    position: values.position,
                    profilePicture: values.profilePicture,
                },
            })
                .then(({ data }) => {
                    if (data?.updateEmployer) {
                        refetch();
                        onClose();
                        toast(
                            JSON.stringify({
                                type: "success",
                                title: ` Representative Information Updated Successfully`,
                            })
                        );
                    } else {
                        toast(
                            JSON.stringify({
                                type: "error",
                                title: `Something wrong happened`,
                            })
                        );
                    }
                })
                .catch((error) => {
                    toast(
                        JSON.stringify({
                            type: "error",
                            title: error?.message || "An error occurred while trying to save job",
                        })
                    );
                });
        },
    });

    useEffect(() => {
        form.setValues({
            fullName: data?.fullName || "",
            email: data?.email || "",
            phoneNumber: data?.phone || "",
            position: data?.position || "",
            profilePicture: data?.profilePicture || "",
        });
    }, [data]);

    return (
        <Modal
            open={visible}
            setOpen={() => {
                onClose();
            }}
            hideActions={false}
            description="Representative Information"
            renderActions={() => (
                <>
                    <button
                        disabled={loading}
                        onClick={() => {
                            form.handleSubmit();
                        }}
                        className="w-[8rem] h-[2.5rem] flex justify-center items-center font-montserrat font-normal text-sm capitalize leading-5 text-center bg-primary rounded-lg text-white border border-primary "
                        type="button">
                        {loading ? "Saving..." : " Save changes"}
                    </button>
                </>
            )}>
            {!_.isEmpty(data) ? (
                <>
                    <div className="mt-3">
                        <div>
                            <div className="">
                                <label className="font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.1px]" htmlFor="fullName">
                                    Full name
                                </label>

                                <input id="fullName" {...form.getFieldProps("fullName")} type="text" placeholder="Enter your full name" className="input-base mt-2" />
                            </div>
                            <div className="">
                                <label className="font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.1px]" htmlFor="email" aria-disabled>
                                    Email
                                </label>

                                <input id="email" value={form.values.email} type="email" placeholder="Enter your email" className="input-base mt-2 bg-gray-100" disabled />
                            </div>
                            <div className="">
                                <label className="font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.1px]" htmlFor="position">
                                    Position in company
                                </label>

                                <input id="position" {...form.getFieldProps("position")} type="text" placeholder="Enter your position in company" className="input-base mt-2" />
                            </div>

                            <div className="">
                                <label className="font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.1px]" htmlFor="phoneNumber">
                                    phone number
                                </label>

                                <PhoneInput
                                    country={"gh"}
                                    placeholder={"Mobile Number"}
                                    value={form.values.phoneNumber}
                                    onChange={(value: any) => form.setFieldValue("phoneNumber", value)}
                                    countryCodeEditable={false}
                                    enableSearch={true}
                                    inputProps={{
                                        required: true,
                                        title: "Enter your phone number here",
                                        className: "input-base mt-2 mb-0",
                                    }}
                                    inputStyle={{
                                        width: "80%",
                                        marginLeft: "20%",
                                    }}
                                    buttonStyle={{
                                        width: "18%",
                                        borderRadius: "0.5rem",
                                        borderColor: "rgb(156 163 175)",
                                        paddingInline: "0.5rem",
                                        marginTop: "0.5rem",
                                    }}
                                    dropdownStyle={{
                                        borderRadius: "0.5rem",
                                        borderColor: "rgb(156 163 175)",
                                        paddingInline: "1rem",
                                    }}
                                    searchStyle={{
                                        width: "90%",
                                        borderRadius: "0.5rem",
                                        borderColor: "rgb(156 163 175)",
                                        paddingInline: "1rem",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="flex-1 w-full max-h-[65vh] overflow-y-auto  sm:p-6">
                    <div>
                        <h1>NO DATA</h1>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default RepresentativeModal;

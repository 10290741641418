import { Button, SelectInput, TextInput } from "@/components";
import ButtonLoader from "@/components/loaders/button";
import { clearCompany, clearRepresentative } from "@/redux/auth/onboardingSlice";
import { useAppDispatch } from "@/redux/hook";
import { classNames } from "@/utils";
import { FormikProps } from "formik";
import { defaultCountries, parseCountry, PhoneInput } from "react-international-phone";

type Props = {
    form: FormikProps<any>;
    otherForm: FormikProps<any>;
    loading: boolean;
};

interface Option {
    value: string;
    label: string;
}
// for industry type
const IndustryOptions: Option[] = [
    { value: "", label: "------- Please Select Industry --------" },
    { value: "Agriculture, Food, and Natural Resources", label: "Agriculture, Food, and Natural Resources" },
    { value: "Architecture and Construction", label: "Architecture and Construction" },
    { value: "Arts, Audio/Visual Technology, and Communications", label: "Arts, Audio/Visual Technology, and Communications" },
    { value: "Business Management and Administration", label: "Business Management and Administration" },
    { value: "Education and Training", label: "Education and Training" },
    { value: "Finance", label: "Finance" },
    { value: "Government and Public Administration", label: "Government and Public Administration" },
    { value: "Health Science", label: "Health Science" },
    { value: "Hospitality and Tourism", label: "Hospitality and Tourism" },
    { value: "Human Services", label: "Human Services" },
    { value: "Information Technology", label: "Information Technology" },
    { value: "Law, Public Safety, Corrections, and Security", label: "Law, Public Safety, Corrections, and Security" },
    { value: "Manufacturing", label: "Manufacturing" },
    { value: "Marketing, Sales, and Service", label: "Marketing, Sales, and Service" },
    { value: "Science, Technology, Engineering, and Mathematics (STEM)", label: "Science, Technology, Engineering, and Mathematics (STEM)" },
    { value: "Transportation, Distribution, and Logistics", label: "Transportation, Distribution, and Logistics" },
    { value: "Energy", label: "Energy" },
    { value: "Telecommunications", label: "Telecommunications" },
    { value: "Real Estate", label: "Real Estate" },
    { value: "Nonprofit and Social Services", label: "Nonprofit and Social Services" },
    { value: "Other", label: "Other" },
];

const CompanyTypeOptions: Option[] = [
    { value: "", label: "------- Please Select Company Type --------" },
    { value: "PRIVATE", label: "Private" },
    { value: "NGO", label: "NGO" },
    { value: "GOVERNMENT", label: "Government" },
];

const CompanyForm = ({ form, loading, otherForm }: Props) => {
    const dispatch = useAppDispatch();
    return (
        <div className="w-full max-w-5xl mt-10 px-5">
            <div className="w-full">
                <h3 className="text-xl font-semibold">Company Information</h3>
                <p className="text-gray-600 text-sm mt-1">Provide essential details on onboarding as the company's representative. </p>
            </div>
            <form
                onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        event.preventDefault(); // Prevent default Enter key behavior
                        form.handleSubmit(); // Submit the form
                    }
                }}>
                <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-5 ">
                    <div className="grid grid-cols-1 md:grid-cols-2 col-span-full md:space-x-5 space-y-5 md:space-y-0">
                        <div className="col-span-1">
                            <div
                                className={classNames(
                                    form.values.business_type === "Corporate" ? "bg-green-100" : "",
                                    "flex justify-start items-center border px-3  space-x-3 h-[38px] shadow-sm w-full sm:text-sm rounded-md placeholder:font-light placeholder:text-xs  border-gray-300 outline-primary"
                                )}>
                                <input type="radio" id="business_type" name="business_type" checked={form.values.business_type === "Corporate"} value="Corporate" onChange={form.getFieldProps("company").onChange} className="h-4 w-4 accent-primary-500" />
                                <label className={classNames(form.values.business_type === "Corporate" ? " text-primary-700" : "text-gray-700", "block text-sm font-medium")}>Corporate Entity</label>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div
                                className={classNames(
                                    form.values.business_type === "Individual" ? "bg-green-100" : "",
                                    "flex justify-start items-center border px-3  space-x-3 h-[38px] shadow-sm w-full sm:text-sm rounded-md placeholder:font-light placeholder:text-xs  border-gray-300 outline-primary"
                                )}>
                                <input type="radio" className="h-4 w-4 accent-primary-500" checked={form.values.business_type === "Individual"} value="Individual" id="business_type" name="business_type" onChange={form.getFieldProps("company").onChange} />
                                <label className={classNames(form.values.business_type === "Individual" ? " text-primary-700" : "text-gray-700", "block text-sm font-medium")}>Individual</label>
                            </div>
                        </div>
                        {form.errors.business_type && (
                            <p className="col-span-full text-sm text-red-600 text-center mt-2">
                                <>{form.errors.business_type}</>
                            </p>
                        )}
                    </div>
                    <div className="col-span-1">
                        <TextInput id="company_name" label="Company Name" placeholder="Enter company name" required {...form} />
                    </div>
                    <div className="col-span-1">
                        <TextInput id="company_email" label="Company Email" placeholder="Enter company email" required {...form} />
                    </div>
                    <div className="col-span-1">
                        <SelectInput label="Company Type" id="company_type" options={CompanyTypeOptions} {...form} />
                    </div>
                    <div className="col-span-1">
                        <SelectInput label="Industry" id="company_industry" options={IndustryOptions} {...form} />
                    </div>
                    <div className="col-span-1">
                        <TextInput id="company_address" label="Company Address" placeholder="Enter company address" required {...form} />
                    </div>
                    <div className="col-span-1 relative">
                        <label htmlFor={"company_phone"} className="block text-sm font-medium text-gray-700 mb-1">
                            Telephone <span className="text-primary-500">*</span>
                        </label>
                        <PhoneInput
                            placeholder="Enter your phone number"
                            defaultCountry={"gh"}
                            className="w-full rounded"
                            countrySelectorStyleProps={{}}
                            inputClassName="w-full focus:ring-primary-500 rounded text-xs"
                            value={form.values.company_phone}
                            onChange={(phone) => form.setFieldValue("company_phone", phone)}
                        />
                    </div>
                    <div className="col-span-full flex flex-col-reverse md:flex-row justify-between items-center mt-5 gap-5">
                        <div className="w-full md:w-20">
                            <Button
                                width="full"
                                variant="minimal"
                                onClick={() => {
                                    form.resetForm();
                                    otherForm.resetForm();
                                    dispatch(clearRepresentative());
                                    dispatch(clearCompany());
                                }}>
                                Cancel
                            </Button>
                        </div>

                        <div className="w-full md:w-fit flex items-center justify-between space-x-4">
                            <Button
                                width="full"
                                variant="minimal"
                                onClick={() => {
                                    form.resetForm();
                                    dispatch(clearCompany());
                                }}>
                                Clear
                            </Button>
                            <Button onClick={form.handleSubmit}>{loading ? <ButtonLoader title="Continuing ..." /> : "Continue"}</Button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CompanyForm;

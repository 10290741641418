import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { CurrencyOption, SalaryDurationOption } from "@/data";
import { SalaryFrequency } from "@/apollo/generated/types";

type Option = { label: string; value: string };

interface EditJobDetailType {
    jobTitle: string;
    jobType: string;
    jobLocation: string;
    modeOfWork: string;
    minimumSalary: number;
    maximumSalary: number;
    duration: SalaryFrequency;
    currency: string;
    openingDate: string;
    closingDate: string;
    salaryRange: boolean;
    vacancies: number;
}

interface EditJobDescriptionType {
    about: string;
    responsibilities: string;
    requirements: string;
    benefits: string;
}

const initialState = {
    jobTitle: "",
    department: "",
    experience: "",
    jobLocation: "",
    modeOfWork: "",
    duration: SalaryDurationOption[0].value,
    currency: CurrencyOption[0].value,
    jobType: "",
    minimumSalary: 0,
    maximumSalary: 0,
    about: "",
    openingDate: "",
    closingDate: "",
    responsibilities: "",
    requirements: "",
    benefits: "",
    salaryRange: false,
    vacancies: 0,
};

export const editJobSlice = createSlice({
    name: "editJobSlice",
    initialState,
    reducers: {
        setEditJobDetails: (state, { payload }: PayloadAction<EditJobDetailType>) => {
            state.jobTitle = payload.jobTitle;
            state.jobType = payload.jobType;
            state.jobLocation = payload.jobLocation;
            state.modeOfWork = payload.modeOfWork;
            state.minimumSalary = payload.minimumSalary;
            state.maximumSalary = payload.maximumSalary;
            state.duration = payload.duration;
            state.currency = payload.currency;
            state.openingDate = payload.openingDate;
            state.closingDate = payload.closingDate;
            state.salaryRange = payload.salaryRange;
            state.vacancies = payload.vacancies;
        },
        setEditJobDescription: (state, { payload }: PayloadAction<EditJobDescriptionType>) => {
            state.about = payload.about;
            state.responsibilities = payload.responsibilities;
            state.requirements = payload.requirements;
            state.benefits = payload.benefits;
        },

        clearEditJob: (state) => {
            state.jobTitle = "";
            state.jobLocation = "";
            state.openingDate = "";
            state.closingDate = "";
            state.minimumSalary = 0;
            state.maximumSalary = 0;
            state.modeOfWork = "";
            state.duration = SalaryDurationOption[0].value;
            state.currency = CurrencyOption[0].value;
            state.jobType = "";
            state.about = "";
            state.responsibilities = "";
            state.requirements = "";
            state.benefits = "";
            state.vacancies = 0;
        },
        clearEditJobDetails: (state) => {
            state.jobTitle = "";
            state.jobLocation = "";
            state.openingDate = "";
            state.closingDate = "";
            state.minimumSalary = 0;
            state.maximumSalary = 0;
            state.vacancies = 0;
            state.modeOfWork = "";
            state.duration = SalaryDurationOption[0].value;
            state.currency = CurrencyOption[0].value;
            state.jobType = "";
        },
        clearEditJobDescription: (state) => {
            state.about = "";
            state.responsibilities = "";
            state.requirements = "";
            state.benefits = "";
        },
    },
});

export const { setEditJobDetails, setEditJobDescription, clearEditJobDetails, clearEditJobDescription, clearEditJob } = editJobSlice.actions;

export const selectEditJob = (state: RootState) => state.editJob;

export default editJobSlice.reducer;

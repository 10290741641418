import { Button } from "@/components";
import { FormikProps } from "formik";
import PermissionCheckBox from "./components/PermissionCheckBox";
import ButtonLoader from "@/components/loaders/button";
import { useUrlState } from "@/utils";

type Props = { form: FormikProps<any>; setTab: (title: "Role Info" | "Permissions Info" | "Preview") => void; loading: boolean };

const Preview = ({ form, setTab, loading }: Props) => {
    const [modal] = useUrlState("modal");
    return (
        <div className="w-full mt-2 px-3 pt-3 space-y-5">
            <div className="w-full">
                <div>
                    <h1 className="text-sm font-semibold leading-4">Role Information</h1>
                    <p className="text-[11px] text-gray-500">Information about the role</p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 border border-gray-200   border-dashed rounded-md  px-5 py-2 mt-5">
                    <div>
                        <label className="text-xs text-gray-500 ">Name</label>
                        <p className="text-xs font-medium">{form.values.name}</p>
                    </div>
                    <div>
                        <label className="text-xs text-gray-500">Code</label>
                        <p className="text-xs font-medium">{form.values.code}</p>
                    </div>
                    <div className="col-span-full">
                        <label className="text-xs text-gray-500">Description</label>
                        <p className="text-xs font-medium">{form.values.description}</p>
                    </div>
                </div>
            </div>
            <div className="w-full">
                <div>
                    <h1 className="text-sm font-semibold leading-4">Permission Information</h1>
                    <p className="text-[11px] text-gray-500">Information about the permissions assigned to the role</p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2  mt-5 px-5 py-2 border border-dashed rounded-md">
                    <div className="w-full space-y-4">
                        <h1 className="text-sm font-medium text-left text-gray-700">Jobs</h1>
                        <div className="mt- space-y-3">
                            <PermissionCheckBox value="job:create" form={form} />
                            <PermissionCheckBox value="job:update" form={form} />
                            <PermissionCheckBox value="job:delete" form={form} />
                            <PermissionCheckBox value="job:repost" form={form} />
                        </div>
                    </div>
                    <div className="w-full space-y-4">
                        <h1 className="text-sm font-medium text-left text-gray-700">Candidates</h1>
                        <div className="mt-2 space-y-3">
                            <PermissionCheckBox value="candidate:shortlist" form={form} />
                            <PermissionCheckBox value="candidate:interview" form={form} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-span-full mt-5 border-t flex justify-end items-center pt-3 space-x-3">
                <div>
                    <Button variant="minimal" onClick={() => setTab("Permissions Info")}>
                        <span className="text-xs font-semibold">Previous</span>
                    </Button>
                </div>
                <div>
                    <Button onClick={() => form.handleSubmit()}>
                        {modal === "editRole" ? <span className="text-xs font-semibold">{loading ? <ButtonLoader title="Updating ..." /> : "Update"}</span> : <span className="text-xs font-semibold">{loading ? <ButtonLoader title="Saving ..." /> : "Save"}</span>}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Preview;

import { useAppNavigate, useCurrentUserPermissions } from "@/apollo/cache/auth";
import { Button, Show } from "@/components";
import RichEditor from "@/components/core/rich-text-editor";
import { useAppSelector } from "@/redux/hook";
import { selectCreateJob } from "@/redux/job/createJobSlice";
import { useUrlState } from "@/utils";
import { CpuChipIcon } from "@heroicons/react/24/outline";
import { FormikProps } from "formik";
import _ from "lodash";
import { useEffect } from "react";
import AiModal from "../components/AiModal";
import ProgressBar from "../components/ProgressBar";
import { hasPermission, PERMISSIONS } from "@/permissions";

type Props = { form: FormikProps<any>; setActive: () => void };

const DescriptionForm = ({ form, setActive }: Props) => {
    const currentUserPermissions = useCurrentUserPermissions();
    const jobPost = useAppSelector(selectCreateJob);
    const [modal, setModal] = useUrlState("modal");

    useEffect(() => {
        form.setValues({ ...jobPost });
    }, []);

    return (
        <>
            <div className="w-full px-2">
                <div className="w-full md:w-5/6 mb-10 flex justify-between items-center">
                    <div>
                        <h1 className="font-bold text-xl">Job Description</h1>
                        <p className="text-sm text-gray-500 mt-1">Fill in the fields with the necessary information.</p>
                    </div>
                    <div>
                        <ProgressBar />
                    </div>
                </div>

                <div className="w-full md:w-5/6 grid grid-cols-1 gap-5">
                    <Show if={hasPermission(currentUserPermissions, PERMISSIONS.JOB_GENERATION)}>
                        <div className="w-full border border-green-500/45  p-6 rounded-md bg-green-50/30">
                            <div className="flex w-full justify-between items-center ">
                                <div className="max-w-80">
                                    <h1 className="text-sm font-semibold">Generate job description with AI</h1>
                                    <p className="text-xs text-gray-500 mt-1">Enter key information, AI generate text into the textboxes below</p>
                                </div>
                                <button onClick={() => setModal("useAI")} className="flex items-center justify-center space-x-2 px-5 border border-green-500/45 rounded h-8 bg-white hover:bg-green-200/5">
                                    <CpuChipIcon className="h-5 w-5 text-primary-500" />
                                    <span className="text-[11px] font-semibold text-primary-500 whitespace-nowrap">Use AI</span>
                                </button>
                            </div>
                        </div>
                    </Show>
                    <div className="w-full lg:w-[unset] border-b py-2 ">
                        <RichEditor id={"about"} label="About Job" {...form} />
                    </div>
                    <div className="w-full lg:w-[unset] border-b py-2 ">
                        <RichEditor id={"responsibilities"} label="Responsibilities" {...form} />
                    </div>
                    <div className="w-full lg:w-[unset] border-b py-2 ">
                        <RichEditor id={"requirements"} label="Requirements/Qualifications" {...form} />
                    </div>
                    <div className="w-full lg:w-[unset] border-b py-2 ">
                        <RichEditor id={"benefits"} label="Benefits" {...form} />
                    </div>
                    <div className="col-span-full flex items-center justify-between space-x-5">
                        <div>
                            <Button variant="minimal" size="md" onClick={() => setActive()}>
                                Previous
                            </Button>
                        </div>
                        <div>
                            <Button variant="primary" size="md" onClick={() => form.handleSubmit()}>
                                <span className="flex justify-between items-center space-x-2">
                                    <span>Next</span>
                                </span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {modal === "useAI" && hasPermission(currentUserPermissions, PERMISSIONS.JOB_GENERATION) && <AiModal open={modal === "useAI"} setOpen={() => setModal(undefined)} form={form} />}
        </>
    );
};

export default DescriptionForm;

import { classNames } from "@/utils";
import { FC } from "react";

type Props = { title: string; active: string; setActive: (value: string) => void };

const RepostTabButton: FC<Props> = ({ title, active, setActive }) => {
    return (
        <button onClick={() => {}} className={classNames(active === title ? "border-b-2 border-primary-500 text-primary-500" : " ", "h-full flex items-end justify-start w-44 pl-2 font-bold flex-shrink-0 pb-2")}>
            {title}
        </button>
    );
};

export default RepostTabButton;

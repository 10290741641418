import { Button, TextInput } from "@/components";
import { FormikProps } from "formik";

type Props = { form: FormikProps<any> };

const RepostJobDetail = ({ form }: Props) => {
    return (
        <div className="w-full ">
            <div className="w-full mb-10">
                <h1 className="font-bold text-xl">Job Details</h1>
                <p className="text-sm text-gray-500 mt-1">Provide opening and closing dates</p>
            </div>
            <div className="w-full grid md:grid-cols-2 grid-cols-1 gap-5 md:gap-7 lg:gap:10">
                <div>
                    <TextInput
                        id={"openingDate"}
                        label={
                            <span>
                                Job <span className="text-primary-500">Opening</span> Date
                            </span>
                        }
                        type="date"
                        {...form}
                        required
                    />
                </div>
                <div>
                    <TextInput
                        id={"closingDate"}
                        label={
                            <span>
                                Job <span className="text-red-500">Closing</span> Date
                            </span>
                        }
                        type="date"
                        {...form}
                        required
                    />
                </div>
                <div className="col-span-full flex items-center justify-end space-x-5 mt-5">
                    <div>
                        <Button variant="primary" size="md" onClick={() => form.handleSubmit()} width="auto">
                            <span className="flex justify-between items-center space-x-2">
                                <span className="px-5">Next</span>
                            </span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RepostJobDetail;
